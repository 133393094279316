import { useCallback, useMemo } from 'react';
import { useSigninCheck } from 'reactfire';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isAfter, isBefore } from 'date-fns';
import { SignalIcon } from '@heroicons/react/24/outline';

import { ButtonWithIcon } from '../button-with-icon';
import { Event, EventTypeEnum } from '../../interfaces';
import { formatDateToLocale } from '../../utils';
import { getCurrentLanguage } from '../../i18n';

type Props = {
  event: Event;
};

export const BroadcastLiveAlert = ({ event }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LANDING_PAGE.BROADCAST_ALERT.LIVE' });
  const navigate = useNavigate();
  const location = useLocation();
  const lang = getCurrentLanguage();
  const { data: signInCheckResult } = useSigninCheck();

  const isEventTime = useMemo(() => {
    const startDate = event.date.start.toDate();
    // Setando a hora pra zero, então assim ele vai considerar o dia todo
    startDate.setHours(0, 0, 0, 0);

    const endDate = event.date.end.toDate();
    const today = new Date();

    return event.type !== EventTypeEnum.PRESENCIAL && isBefore(startDate, today) && isAfter(endDate, today);
  }, [event]);

  const navigateBroadcast = useCallback(() => {
    if (signInCheckResult.signedIn) {
      return navigate(`broadcast`);
    }
    return navigate(`modal/broadcast/join`, {
      state: { background: location, eventId: event.eventId }
    });
  }, [event.eventId, location, navigate, signInCheckResult.signedIn]);

  const alertDescription = useMemo(
    () => (signInCheckResult.signedIn ? t('DESCRIPTION.SIGNED_IN') : t('DESCRIPTION.SIGNED_OUT')),
    [signInCheckResult.signedIn, t]
  );

  const originalStartDate = useMemo(() => event.date.start.toDate(), [event.date.start]);

  if (!isEventTime) {
    return <></>;
  }

  return (
    <div className="mb-4 flex w-full flex-col items-center gap-4 p-4 xs:flex-row sm:px-0">
      <div className="w-full space-y-1 text-center xs:text-left">
        <h1 className="font-bold text-black">
          {isAfter(originalStartDate, new Date())
            ? `${t('TITLE.BEFORE_START', {
                time: formatDateToLocale({ date: event.date.start, lang, style: 'p' })
              })}`
            : `${t('TITLE.ON_GOING')}`}
        </h1>
        <p className="text-sm text-black">{alertDescription}</p>
      </div>

      <ButtonWithIcon
        icon={SignalIcon}
        changePositionIconLeft
        customStylesButton="bg-[#D11919] xs:max-w-[222px] w-full text-white text-sm hover:opacity-80"
        customStylesIcon="w-5 h-5 mr-2"
        onClick={navigateBroadcast}
      >
        {t('BUTTON')}
      </ButtonWithIcon>
    </div>
  );
};
