/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { sumBy } from 'lodash';

const isntTableColumn = ['lineId'];

type TableColumnPrimitiveValueTypes = string | number | JSX.Element;
type TableColumnObjectValueType = { value: TableColumnPrimitiveValueTypes; format?: (value: any) => string };
type TableColumnNames = { [column: string]: string };
type TableHeader = { title: string; details?: string };
type TableData = {
  lineId: string;
  [key: string]: TableColumnPrimitiveValueTypes | TableColumnObjectValueType;
};

export const Table = ({
  tableHeader,
  tableData,
  columnNames,
  showFooter = false
}: {
  columnNames: TableColumnNames;
  tableHeader?: TableHeader;
  tableData: Array<TableData>;
  showFooter?: boolean;
}) => {
  const columnIds = useMemo(() => Object.keys(tableData[0]).filter(f => !isntTableColumn.includes(f)), [tableData]);

  const footerColumns = useMemo(() => {
    const [first] = tableData;
    return columnIds
      .map(column => {
        const columnValue = first[column];
        const isColumnNumber =
          typeof columnValue === 'number' ||
          (typeof columnValue === 'object' && typeof (first[column] as TableColumnObjectValueType).value === 'number');
        return isColumnNumber ? column : '';
      })
      .map(column => {
        if (column.length <= 0) {
          return column;
        }
        const isObjectColumnValue =
          typeof first[column] === 'object' && (first[column] as TableColumnObjectValueType).value !== undefined;
        const iteratee = isObjectColumnValue ? `${column}.value` : column;
        const formatFn = (first[column] as TableColumnObjectValueType).format ?? undefined;
        const sumValue = sumBy(tableData, iteratee);
        return formatFn ? formatFn(sumValue) : sumValue;
      });
  }, [columnIds, tableData]);

  const renderColumnValue = (data: TableData, column: string) => {
    const isObjectColumnValue =
      typeof data[column] === 'object' && (data[column] as TableColumnObjectValueType).value !== undefined;
    if (isObjectColumnValue) {
      const { value, format = undefined } = data[column] as TableColumnObjectValueType;
      return format ? format(value) : value;
    }
    return data[column] as TableColumnPrimitiveValueTypes;
  };

  return (
    <>
      {tableHeader && (
        <div className="mb-4 border-b sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{tableHeader.title}</h1>
            <p className="mt-2 pb-2 text-gray-700">{tableHeader.details}</p>
          </div>
        </div>
      )}
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            {columnIds.map(key => (
              <th
                key={`thead-tr-th-${key}`}
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {columnNames[key]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {tableData.map((data, idx) => (
            <tr key={`tbody-tr-${data.lineId}`} className={idx % 2 === 0 ? undefined : 'bg-gray-50'}>
              {columnIds.map(column => (
                <td
                  key={`tbody-tr-td-${data.lineId}-${column}`}
                  className="whitespace-nowrap p-2 text-sm text-gray-900"
                >
                  {renderColumnValue(data, column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {showFooter && (
          <tfoot>
            <tr>
              {footerColumns.map((value, index) => (
                <th
                  key={`tfoot-tr-th-${value}-${index}`}
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {value}
                </th>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </>
  );
};
