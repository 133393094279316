import { Trans, useTranslation } from 'react-i18next';

import { useCheckoutPage, usePaymentMethods } from '../../../../../../hooks';
import { PecegePayCompleteOrderBoletoResponseType } from '../../../../../../interfaces';
import { LandingPageCard, SpinnerLoader } from '../../../../../../components';
import { BoletoFailed } from './boleto-failed';
import { BoletoSuccess } from './boleto-success';
import { SecurityInfo } from './security-info';

type Props = {
  pecegePay: PecegePayCompleteOrderBoletoResponseType;
  logErrorCount: number;
};

export const BoletoComplete: React.FC<Props> = ({ pecegePay, logErrorCount }: Props) => {
  const { t } = useTranslation();
  const { securityInfo } = usePaymentMethods();
  const { stagingPurchase } = useCheckoutPage();

  if (!pecegePay && logErrorCount === 0)
    return (
      <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.TITLE')}>
        <SpinnerLoader />
      </LandingPageCard>
    );

  if (!pecegePay && logErrorCount < 5) {
    return (
      <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.TITLE')}>
        <SpinnerLoader />
        <h1 className="pt-4 text-center text-lg font-medium">
          <Trans
            i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.VALIDATING_BOLETO"
            values={{
              logErrorCount
            }}
          />
        </h1>
      </LandingPageCard>
    );
  }

  if (!pecegePay && logErrorCount === 5) {
    return <BoletoFailed />;
  }

  return (
    <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.TITLE')}>
      <BoletoSuccess sendPurchaseInfoTo={stagingPurchase.sendPurchaseInfoTo} />
      <SecurityInfo securityInfo={securityInfo} />
    </LandingPageCard>
  );
};
