/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useMemo } from 'react';
import { useSigninCheck } from 'reactfire';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isAfter, isBefore } from 'date-fns';

import { ButtonWithIcon } from '../button-with-icon';
import { Event, EventTypeEnum } from '../../interfaces';
import { formatDateToLocale } from '../../utils';
import { getCurrentLanguage } from '../../i18n';

type Props = {
  event: Event;
};

export const BroadcastRecordAlert = ({ event }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LANDING_PAGE.BROADCAST_ALERT.RECORD' });
  const navigate = useNavigate();
  const location = useLocation();
  const lang = getCurrentLanguage();
  const { data: signInCheckResult } = useSigninCheck();

  const eventHasRecord = useMemo(() => !!event.broadcastRecordDate, [event]);

  const isRecordAvailable = useMemo(() => {
    const startDate = event.broadcastRecordDate?.start.toDate();

    if (!startDate) {
      return false;
    }

    return event.type !== EventTypeEnum.PRESENCIAL && isBefore(startDate, new Date());
  }, [event]);

  const navigateBroadcast = useCallback(() => {
    if (signInCheckResult.signedIn) {
      return navigate(`broadcast`);
    }
    return navigate(`modal/broadcast/join`, {
      state: { background: location, eventId: event.eventId }
    });
  }, [event.eventId, location, navigate, signInCheckResult.signedIn]);

  const alertDescription = useMemo(
    () =>
      signInCheckResult.signedIn
        ? t('DESCRIPTION.ON_GOING.SIGNED_IN', {
            startDate: formatDateToLocale({
              date: event.broadcastRecordDate?.start.toDate(),
              lang,
              style: 'Pp'
            }),
            endDate: formatDateToLocale({
              date: event.broadcastRecordDate?.end.toDate(),
              lang,
              style: 'Pp'
            })
          })
        : t('DESCRIPTION.ON_GOING.SIGNED_OUT', {
            startDate: formatDateToLocale({
              date: event.broadcastRecordDate?.start.toDate(),
              lang,
              style: 'Pp'
            }),
            endDate: formatDateToLocale({
              date: event.broadcastRecordDate?.end.toDate(),
              lang,
              style: 'Pp'
            })
          }),
    [event.broadcastRecordDate, lang, signInCheckResult.signedIn, t]
  );

  const originalStartDate = useMemo(
    () => event.broadcastRecordDate?.start.toDate(),
    [event.broadcastRecordDate?.start]
  );

  if (!eventHasRecord) {
    return <></>;
  }

  return (
    <div className="mb-4 flex w-full flex-col items-center gap-4 p-4 xs:flex-row sm:px-0">
      <div className="w-full space-y-1 text-center xs:text-left">
        <h1 className="font-bold text-black">
          {isAfter(originalStartDate!, new Date())
            ? `${t('TITLE.BEFORE_START', {
                time: formatDateToLocale({ date: event.date.start, lang, style: 'p' })
              })}`
            : `${t('TITLE.ON_GOING')}`}
        </h1>
        <p className="text-sm text-black">
          {isRecordAvailable
            ? alertDescription
            : t('DESCRIPTION.BEFORE_START', {
                startDate: formatDateToLocale({
                  date: event.broadcastRecordDate?.start.toDate(),
                  lang,
                  style: 'Pp'
                }),
                endDate: formatDateToLocale({
                  date: event.broadcastRecordDate?.end.toDate(),
                  lang,
                  style: 'Pp'
                })
              })}
        </p>
      </div>

      <ButtonWithIcon
        customStylesButton={`bg-[#3662E3] xs:max-w-[450px] ${
          isRecordAvailable ? 'w-[200px]' : 'w-full'
        } text-white text-sm hover:opacity-80`}
        onClick={navigateBroadcast}
        disabled={!isRecordAvailable}
      >
        {isRecordAvailable
          ? t('BUTTON.ON_GOING')
          : t('BUTTON.BEFORE_START', {
              startDate: formatDateToLocale({
                date: event.broadcastRecordDate?.start.toDate(),
                lang,
                style: 'PPp'
              })
            })}
      </ButtonWithIcon>
    </div>
  );
};
