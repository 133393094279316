import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { StorageProvider, useInitStorage } from 'reactfire';

type ReactStorageProviderProps = {
  children: React.ReactNode;
};

export const ReactStorageProvider = ({ children }: ReactStorageProviderProps) => {
  const useEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

  const { data: storageInstance } = useInitStorage(async firebaseApp => {
    const storage = getStorage(firebaseApp);
    if (useEmulator) {
      connectStorageEmulator(storage, 'localhost', 9199);
    }
    return storage;
  });
  return <StorageProvider sdk={storageInstance}>{children}</StorageProvider>;
};
