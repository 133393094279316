import { useMemo } from 'react';
import { useUser } from 'reactfire';
import { useTranslation } from 'react-i18next';
import { ChevronUpDownIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import { useAuthentication } from '../hooks';
import { nameInitials } from '../utils';
import { ComponentTypeEnum, SuspenseMenu } from '.';

type ProposType = { isMobile?: boolean } & React.HTMLAttributes<HTMLElement>;

export const UserDropdown: React.FC<ProposType> = ({ className, isMobile }: ProposType) => {
  const { t } = useTranslation();
  const { signOutFirebase } = useAuthentication();
  const { data: user } = useUser();

  const displayName = useMemo(() => {
    if (!user || !user.displayName) return '';
    return isMobile ? nameInitials(user.displayName) : user.displayName.toUpperCase();
  }, [isMobile, user]);

  // não ha obrigação de ficar aqui, o imporatante é redirecionar à pagina inicial do evento que a pessoa estiver
  const handleSignOut = async () => {
    await signOutFirebase(true);
  };

  const menuItems = {
    className:
      'absolute inset-x-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
    items: [
      {
        key: 'item-key-logout',
        componentType: ComponentTypeEnum.BUTTON,
        component: <>{t('DASHBOARD_PAGE.USER_DROPDOWN.LOGOUT')}</>,
        onClick: () => handleSignOut(),
        class: {
          base: 'block px-4 py-2 text-sm w-full text-left',
          active: 'bg-gray-100 text-gray-900',
          inactive: 'text-gray-700'
        }
      }
    ]
  };

  const menuButton = {
    className:
      'group w-full rounded-md bg-gray-100 px-3.5 py-1 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    component: (
      <span className="flex w-full items-center justify-between">
        <span className="flex min-w-0 items-center justify-between space-x-3">
          {user?.photoURL ? (
            <img className="h-10 w-10 shrink-0 rounded-full bg-gray-300" src={user?.photoURL} alt="foto do usuário" />
          ) : (
            <UserCircleIcon className="h-12 w-12 shrink-0 rounded-full text-gray-300" />
          )}
          <span className="flex min-w-0 flex-1 flex-col">
            <span className="truncate text-sm font-medium text-gray-900">{displayName}</span>
            {!isMobile ? <span className="truncate text-sm text-gray-500">{user?.email}</span> : null}
          </span>
        </span>
        <ChevronUpDownIcon className="h-5 w-5 shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
      </span>
    )
  };

  return <SuspenseMenu menuButton={menuButton} menuItems={menuItems} className={className} />;
};

UserDropdown.defaultProps = {
  isMobile: false
};
