import { Suspense, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { LayoutProvider, BroadcastProvider } from '../contexts';
import { PublicHeader, EnvBanner, Card, SpinnerLoader } from '../components';
import { useBroadcast } from '../hooks';
import { BroadcastSidebar } from '../pages/broadcast/components';

const LayoutContent = () => {
  const { event, isDescriptionOpen } = useBroadcast();

  useEffect(() => {
    document.body.classList.add('bg-slate-100');
    document.body.classList.remove('h-full');
    return () => {
      document.body.classList.remove('bg-slate-100');
      document.body.classList.add('h-full');
    };
  }, []);

  return (
    <>
      <div className="mb-6 bg-white">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <PublicHeader title={event?.name} />
        </div>
      </div>
      <div className="mx-auto max-w-7xl pb-8 sm:px-6 lg:px-8">
        <div className="flex min-h-full flex-col">
          <div className="mx-auto w-full grow lg:flex">
            <div className="flex-1 xl:flex">
              <Suspense fallback={<SpinnerLoader />}>
                <Outlet />
              </Suspense>
            </div>
            {!isDescriptionOpen && (
              <div className="shrink-0 pt-4 lg:w-96 lg:pt-0 lg:pl-6">
                <Card noShadow bodyPadding="2">
                  <BroadcastSidebar />
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const BroadcastLayout: React.FC = () => {
  const { eventId = '' } = useParams();

  return (
    <>
      <EnvBanner />
      <LayoutProvider>
        <BroadcastProvider eventId={eventId}>
          <LayoutContent />
        </BroadcastProvider>
      </LayoutProvider>
    </>
  );
};
