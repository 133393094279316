import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n';
import { ReactFireFirebaseProvider } from './services/reactfire';
import { NotificationProvider } from './contexts';

import 'react-day-picker/dist/style.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReactFireFirebaseProvider>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </ReactFireFirebaseProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
