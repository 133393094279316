import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ReceiptPercentIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonWithIcon, InputLabel, SpinnerLoader } from '../../../../../components';
import { useCheckoutPage } from '../../../../../hooks';
import { DiscountCouponValues, DiscountCouponFormSchema } from './form-validation';
import { VerifyCouponActionEnum } from '../../../../../interfaces';
import { RemoveCoupon } from './remove-coupon';

export const DiscountCoupon = () => {
  const { t } = useTranslation();
  const { handleCouponRequest, stagingPurchase } = useCheckoutPage();
  const methods = useForm<DiscountCouponValues>({
    resolver: yupResolver(DiscountCouponFormSchema)
  });
  const {
    handleSubmit,
    watch,
    setError,
    resetField,
    formState: { isSubmitting }
  } = methods;

  const appliedCouponId = useMemo(() => {
    const [ticketCoupon] = stagingPurchase.tickets.filter(ticket => ticket.coupon?.id);
    return ticketCoupon?.coupon?.id;
  }, [stagingPurchase.tickets]);

  const handleRequesteCoupon = async (action: VerifyCouponActionEnum, couponId: string) => {
    const requestVerify = {
      eventId: stagingPurchase.eventId,
      couponId,
      action,
      purchaseId: stagingPurchase.purchaseId
    };
    const response = await handleCouponRequest(requestVerify);
    return response;
  };

  const handleDiscountCoupon: SubmitHandler<DiscountCouponValues> = async ({ coupon }: DiscountCouponValues) => {
    const responseVerify = await handleRequesteCoupon(VerifyCouponActionEnum.VERIFY, coupon);
    if (!responseVerify) {
      setError('coupon', {
        type: 'focus',
        message: t('CHECKOUT.STEPS.ORDER_SUMMARY.DISCOUNT_COUPON.MESSAGE.INVALID').toString()
      });
      return;
    }
    const responseApply = await handleRequesteCoupon(VerifyCouponActionEnum.APPLY, coupon);
    if (!responseApply) {
      setError('coupon', {
        type: 'focus',
        message: t('CHECKOUT.STEPS.ORDER_SUMMARY.DISCOUNT_COUPON.MESSAGE.INVALID').toString()
      });
      return;
    }
    resetField('coupon');
  };

  if (appliedCouponId) {
    return <RemoveCoupon appliedCouponId={appliedCouponId} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleDiscountCoupon)} className="float-right flex h-[2.5rem] w-full flex-row">
        <InputLabel
          name="coupon"
          placeholder={t('CHECKOUT.STEPS.ORDER_SUMMARY.DISCOUNT_COUPON.PLACEHOLDER').toString()}
          type="text"
          maxLength={50}
          autoComplete="none"
          customStyle="flex-1 w-full rounded-l-md border-gray-300 shadow-sm text-sm h-full sm:text-base"
          disabled={isSubmitting}
        />
        <ButtonWithIcon
          type="submit"
          icon={ReceiptPercentIcon}
          customStylesButton="bg-primary text-white rounded-l-none shadow-sm text-sm w-[9.4rem] sm:text-base sm:w-[14rem]"
          disabled={isSubmitting || !watch('coupon')}
        >
          {isSubmitting ? <SpinnerLoader size="sm" /> : t('CHECKOUT.STEPS.ORDER_SUMMARY.DISCOUNT_COUPON.BUTTON.APPLY')}
        </ButtonWithIcon>
      </form>
    </FormProvider>
  );
};
