import { useContext } from 'react';
import { TicketsContext } from '../contexts';

export const useTickets = () => {
  const context = useContext(TicketsContext);
  if (!context) {
    throw new Error('useTickets deve ser usado dentro do TicketsProvider.');
  }
  return context;
};
