import { useMemo } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

type Props = {
  claims?: string[];
};

const PrivateRoutes = ({ claims }: Props) => {
  const location = useLocation();
  const params = useParams();
  const { eventId = '' } = params;

  const { data: signInCheckResult } = useSigninCheck({
    validateCustomClaims: userClaims => {
      const hasRequiredClaims = claims ? claims.some(c => userClaims[c] === true) : true;
      return { hasRequiredClaims, errors: {} };
    }
  });

  const to = useMemo(() => {
    const isBroadcast = location.pathname.includes('broadcast');
    const returnPath = isBroadcast ? `/${eventId}` : `/login`;
    if (!signInCheckResult.signedIn) {
      return returnPath;
    }
    return `/${eventId}/access-denied`;
  }, [eventId, signInCheckResult.signedIn, location.pathname]);

  return signInCheckResult.signedIn && signInCheckResult.hasRequiredClaims ? (
    <Outlet />
  ) : (
    <Navigate to={to} replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
