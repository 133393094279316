import classNames from 'classnames';
import { useMemo } from 'react';

import { SidebarNavigationItem } from '../../interfaces';

type Props = { isActive: boolean; item: SidebarNavigationItem };

export const NavigationItem = ({ isActive, item }: Props) =>
  useMemo(
    () => (
      <>
        <item.icon
          className={classNames(
            isActive ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 flex-shrink-0 h-6 w-6'
          )}
          aria-hidden="true"
        />
        {item.name}
      </>
    ),
    [isActive, item]
  );
