import { Timestamp } from 'firebase/firestore';
import { PecegePayDocumentType } from './pecege-pay';
import { PurchaseCustomerBillingAddressType, PhoneNumberType } from './purchase';

export enum FixedActivitiesEnum {
  CHECK_IN = 'check-in',
  LIVE = 'live'
}

export enum ParticipantLogActivityStatusEnum {
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  ALREADY_IN = 'already-in'
}

export type ParticipantActivity = {
  eventId: string;
  participantId: string;
  participantName: string;
  participantEmail: string;
  activityId: FixedActivitiesEnum | string;
  activityTitle: string;
  createdAt: Date;
  status: ParticipantLogActivityStatusEnum;
};

export type Participant = {
  firstName: string;
  lastName: string;
  email: string;
  cellphone: PhoneNumberType;
  tagName: string;
  tagNumber: number;
  fullName: string;
  nacionality: string;
  document: string;
  documentType: PecegePayDocumentType;
  isPeopleSpecialNeeds: boolean;
  peopleSpecialNeedsType: string;
  ticketsId: string;
  ticketsTitle: string;
  ticketsTags: string[] | null;
  course: string;
  eventId: string;
  orderId: string;
  paymentMethod: string;
  purchaseId: string;
  didActivities: Array<FixedActivitiesEnum | string>;
  createdAt: Timestamp;
  address: PurchaseCustomerBillingAddressType | null;
  company: string | null;
};

export enum PaymentMethodEnum {
  CREDIT_CARD = 'CreditCardLimit',
  BOLETO = 'Boleto',
  PIX = 'Pix'
}
