import { TranslateType } from '../interfaces';

export const getTranslateText = (translateObj?: TranslateType | string, lang = 'pt') => {
  try {
    if (!translateObj) return 'sem objeto de tradução';
    if (typeof translateObj === 'string') {
      return translateObj as string;
    }
    return translateObj[lang] ?? translateObj.pt;
  } catch {
    return 'erro ao traduzir';
  }
};
