import { Suspense, useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrinterIcon, UserCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { ErrorBoundary } from 'react-error-boundary';

import { ParticipantsContext } from '../../../participants-context';
import { Participant, PaymentMethodEnum } from '../../../../../interfaces';
import {
  Alerts,
  Card,
  ComponentTypeEnum,
  Pill,
  SpinnerLoader,
  SuspenseMenu,
  VerticalDefinitionList
} from '../../../../../components';
import { formatDateToLocale, formatPhone } from '../../../../../utils';
import { ActivitiesTimeline, PurchaseActionLog } from '.';

//TODO: Add translations
const definitionListPropNames = {
  createdAt: 'Criado em',
  fullName: 'Nome',
  cellphone: 'Celular',
  course: 'Curso',
  document: 'Documento',
  documentType: 'Tipo de documento',
  email: 'E-mail',
  peopleSpecialNeedsType: 'Necessidade especial',
  purchaseId: 'Id da compra Linka',
  tagName: 'Nome do crachá',
  ticketsId: 'Id do ingresso',
  ticketsTitle: 'Nome do ingresso',
  nacionality: 'País',
  orderId: 'Id compra Pecege Pay',
  paymentMethod: 'Meio de pagamento',
  city: 'Cidade',
  complement: 'Complemento',
  neighborhood: 'Bairro',
  placeNumber: 'Número',
  state: 'Estado',
  zipCode: 'CEP',
  street: 'Rua'
};

const FallbackComponent = () => (
  <div>
    <Alerts
      type="error"
      message="Ops, um erro inesperado aconteceu no carregamento da lista de registros de ações e atividades. Entre em contato através do e-mail linka@pecege.com para nos informar o que aconteceu."
    />
  </div>
);

const menuItemClasses = {
  base: 'block px-4 py-2 text-sm',
  active: 'bg-gray-100 text-gray-900',
  inactive: 'text-gray-700',
  disabled: 'text-gray-300 pointer-events-none'
};

export const ParticipantDetail = () => {
  const location = useLocation();
  const { t } = useTranslation('translation', { keyPrefix: 'DASHBOARD_PAGE.PARTICIPANTS_PAGE.GRID.DETAILS' });
  const { disabledRefund, transferIn, transferOut, disabledCheckIn } = useContext(ParticipantsContext);

  const participant = location.state.person as Participant & { token: string };

  const personalData = {
    fullName: participant.fullName,
    cellphone: formatPhone({ phone: participant.cellphone }),
    email: participant.email,
    document: participant.document,
    documentType: participant.documentType.toUpperCase(),
    ticketsId: participant.ticketsId,
    ticketsTitle: participant.ticketsTitle,
    paymentMethod: participant.paymentMethod === PaymentMethodEnum.CREDIT_CARD ? 'Cartão de crédito' : 'Boleto',
    nacionality: participant.nacionality,
    peopleSpecialNeedsType: participant.peopleSpecialNeedsType || '-',
    purchaseId: participant.purchaseId,
    tagName: participant.tagName,
    orderId: participant.orderId,
    createdAt: formatDateToLocale({
      date: participant.createdAt,
      lang: 'pt',
      style: 'Ppp'
    })
  };

  const menuButton = {
    className:
      'inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    component: (
      <>
        <span className="sr-only">Abrir menu</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </>
    )
  };

  const menuItems = useMemo(
    () => ({
      className:
        'absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
      items: [
        {
          key: 'item-key-checkin',
          componentType: ComponentTypeEnum.LINK,
          component: <>Efetuar check-in</>,
          onClick: `/${participant.eventId}/modal/check-in`,
          onClickState: { background: location },
          disabled: disabledCheckIn || disabledRefund || transferOut,
          class: menuItemClasses
        },
        {
          key: 'item-key-refund',
          componentType: ComponentTypeEnum.LINK,
          component: <>Estornar ingresso</>,
          onClick: `/${participant.eventId}/modal/refund`,
          onClickState: { background: location },
          disabled: disabledRefund || disabledCheckIn || transferOut || transferIn,
          class: menuItemClasses
        },
        {
          key: 'item-key-transfer',
          componentType: ComponentTypeEnum.LINK,
          component: <>Transferir ingresso</>,
          onClick: `/${participant.eventId}/modal/transfer`,
          onClickState: { background: location },
          disabled: transferOut || disabledCheckIn || disabledRefund,
          class: menuItemClasses
        },
        {
          key: 'item-key-upgrade',
          componentType: ComponentTypeEnum.LINK,
          component: <>Upgrade de ingresso</>,
          onClick: `/${participant.eventId}/modal/ticket-upgrade`,
          onClickState: { background: location },
          disabled: transferOut || disabledRefund,
          class: menuItemClasses
        }
      ]
    }),
    [disabledCheckIn, disabledRefund, location, participant.eventId, transferIn, transferOut]
  );

  return (
    <main className="h-[calc(100vh_-_71px)] w-full overflow-y-auto">
      <div className="h-20 w-full bg-blue-100" />
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <div className="h-24 w-24 rounded-full bg-blue-100 ring-4 ring-blue-100 sm:h-32 sm:w-32">
              <UserCircleIcon className="text-white" />
            </div>
          </div>
          <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6">
            <div className="min-w-0 flex-1 sm:hidden 2xl:block">
              <h1 className="truncate text-2xl font-bold text-gray-900">{participant.fullName}</h1>
              {participant.ticketsTags
                ? participant.ticketsTags.map(tag => <Pill key={tag} text={tag} color="blue" />)
                : null}
            </div>
            <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              {/* so pode imprimir se nao for ingresso transferido para outro participante ou estorno */}
              {!transferOut && !disabledRefund && (
                <Link
                  to={`/print-tag?token=${participant.token}`}
                  target="_blank"
                  className={`inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                >
                  <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3 truncate">{t('PRINT_TAG')}</span>
                </Link>
              )}

              <div className="ml-3 inline-flex sm:ml-0">
                <SuspenseMenu
                  menuButton={menuButton}
                  menuItems={menuItems}
                  className="relative inline-block text-left"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900">{participant.fullName}</h1>
          {participant.ticketsTags
            ? participant.ticketsTags.map(tag => <Pill key={tag} text={tag} color="blue" />)
            : null}
        </div>
      </div>

      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-t border-gray-200 bg-gray-50">
          <div className="mx-auto max-w-3xl px-4 sm:p-6 lg:max-w-7xl lg:p-6">
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3">
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                <Card title="Dados pessoais">
                  <VerticalDefinitionList data={personalData} propNames={definitionListPropNames} />
                </Card>
                {participant.address ? (
                  <Card title="Dados de endereço">
                    <VerticalDefinitionList data={participant.address} propNames={definitionListPropNames} />
                  </Card>
                ) : null}
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Suspense fallback={<SpinnerLoader size="md" />}>
                  <ErrorBoundary fallback={<FallbackComponent />}>
                    <PurchaseActionLog participant={participant} />
                    <ActivitiesTimeline email={participant.email} />
                  </ErrorBoundary>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
