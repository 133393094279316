import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { FormSearch, CountParticipants, List } from '.';
import { Alerts, SpinnerLoader } from '../../../../../components';

const FallbackComponent = () => (
  <div className="mx-6">
    <Alerts
      type="error"
      message="Ops, um erro inesperado aconteceu no carregamento dos participantes. Entre em contato através do e-mail linka@pecege.com para nos informar o que aconteceu."
    />
  </div>
);

export const ParticipantsNavigation = () => (
  <>
    <div className="px-6 pt-6 pb-4">
      <h2 className="text-lg font-medium text-gray-900">Participantes</h2>
      <CountParticipants />
      <FormSearch />
    </div>
    <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Participantes">
      <Suspense fallback={<SpinnerLoader size="md" position="topCenter" />}>
        <ErrorBoundary fallback={<FallbackComponent />}>
          <List />
        </ErrorBoundary>
      </Suspense>
    </nav>
  </>
);
