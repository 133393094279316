import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/20/solid';

import { ConstraintsListType, useGetCollectionData, useLayout } from '../../../../hooks';
import { PurchaseType, PurchaseStatusEnum, PurchaseTicketsType } from '../../../../interfaces';
import { formatDateToLocale, formatMonetaryToLocale } from '../../../../utils';
import { Alerts, Pill, Table } from '../../../../components';
import { PurchaseDetail } from './details';
import { differenceInMinutes } from 'date-fns';

export const PurchasesList = () => {
  const { eventId = '', ticketId = '' } = useParams();
  const { openSlideOver, setSlideOverProps } = useLayout();
  const eventCollectionName = 'event';
  const purchasesCollectionName = 'purchases';

  const constraints: ConstraintsListType = [{ fieldPath: 'status', opStr: '==', value: PurchaseStatusEnum.PAID }];
  const ignore = ['all', 'refunds'];
  if (ticketId === 'refunds') {
    constraints.shift();
    constraints.push({
      fieldPath: 'status',
      opStr: 'in',
      value: [PurchaseStatusEnum.PARTIALLY_REFUNDED, PurchaseStatusEnum.FULLY_REFUNDED]
    });
  }
  if (!ignore.includes(ticketId)) {
    constraints.push({ fieldPath: 'ticketIds', opStr: 'array-contains', value: ticketId });
  }

  const { data } = useGetCollectionData<PurchaseType>({
    collectionName: eventCollectionName,
    path: [eventId, purchasesCollectionName],
    constraints,
    orderBy: {
      direction: 'desc',
      field: 'createdAt'
    }
  });

  const amountPaid = useCallback(
    (tickets: Array<PurchaseTicketsType>) =>
      tickets.reduce((acc, ticket) => {
        if (ticket.coupon) {
          const discount = (ticket.value * ticket.quantity * ticket.coupon.discount) / 100;
          return acc + ticket.value * ticket.quantity - discount;
        }
        return acc + ticket.value * ticket.quantity;
      }, 0),
    []
  );

  const formatPaymentMethod = (paymentMethod: string) => {
    const toText: { [key: string]: string } = {
      Boleto: 'Boleto',
      CreditCardLimit: 'Cartão de crédito',
      Pix: 'Pix'
    };
    return toText[paymentMethod];
  };

  const formatStatus = (status: PurchaseStatusEnum) => {
    const statusObject: { color: string; text: string } = { color: '', text: '' };
    switch (status) {
      case PurchaseStatusEnum.CANCELLED:
        statusObject.text = 'Cancelado';
        statusObject.color = 'gray';
        break;
      case PurchaseStatusEnum.FAILED:
        statusObject.text = 'Falhou';
        statusObject.color = 'red';
        break;
      case PurchaseStatusEnum.PARTIALLY_REFUNDED:
        statusObject.text = 'Estornado parc.';
        statusObject.color = 'red';
        break;
      case PurchaseStatusEnum.FULLY_REFUNDED:
        statusObject.text = 'Estornado';
        statusObject.color = 'red';
        break;
      case PurchaseStatusEnum.IN_CANCELATION:
        statusObject.text = 'Cancelando';
        statusObject.color = 'gray';
        break;
      case PurchaseStatusEnum.OPEN:
        statusObject.text = 'Criada';
        statusObject.color = 'blue';
        break;
      case PurchaseStatusEnum.PAID:
        statusObject.text = 'Pago';
        statusObject.color = 'green';
        break;
      case PurchaseStatusEnum.VOID:
        statusObject.text = 'Inválida';
        statusObject.color = 'gray';
        break;
      default:
        break;
    }
    return statusObject;
  };

  const openSlideOverPanel = useCallback(
    (purchase: PurchaseType) => {
      setSlideOverProps({
        component: <PurchaseDetail purchase={purchase} />,
        details: `Visualize detalhes da compra como, dados do comprador, participantes, ingressos, valores e descontos.`,
        title: `Ver compra ${purchase.purchaseId}`,
        widePanel: true
      });
      openSlideOver();
    },
    [openSlideOver, setSlideOverProps]
  );

  const list = useMemo(
    () =>
      data.map(purchase => ({
        purchaseId: (
          <div className="flex">
            <button
              type="button"
              onClick={() => openSlideOverPanel(purchase)}
              className="group inline-flex space-x-2 truncate text-sm"
            >
              <EyeIcon className="h-5 w-5 shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              <p className="truncate text-gray-500 group-hover:text-gray-900">{purchase.purchaseId}</p>
            </button>
          </div>
        ),
        createdAt: formatDateToLocale({ date: purchase.createdAt.toDate(), style: 'Ppp', lang: 'pt' }),
        completedAt: purchase.completedAt
          ? formatDateToLocale({ date: purchase.completedAt.toDate(), style: 'Ppp', lang: 'pt' })
          : '-',
        purchaseTime: purchase.completedAt
          ? `${differenceInMinutes(purchase.completedAt.toDate(), purchase.createdAt.toDate())} min`
          : '-',
        paymentMethod: formatPaymentMethod(purchase.paymentMethod),
        status: <Pill text={formatStatus(purchase.status).text} color={formatStatus(purchase.status).color} />,
        ticketCount: purchase.tickets.map(t => t.quantity).reduce((prevVal, currVal) => prevVal + currVal, 0),
        amountPaid: {
          value: amountPaid(purchase.tickets),
          format: (value: number) =>
            formatMonetaryToLocale({
              value,
              lang: 'pt-BR',
              style: 'currency',
              currency: 'BRL'
            })
        },
        lineId: purchase.purchaseId
      })),
    [amountPaid, data, openSlideOverPanel]
  );

  const columnNames = {
    purchaseId: 'Id da compra',
    ticketCount: 'Qtd. ingressos',
    createdAt: 'Criado em',
    completedAt: 'Finalizado em',
    purchaseTime: 'Tempo da compra',
    paymentMethod: 'Meio de pagamento',
    status: 'Status',
    amountPaid: 'Valor total'
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden border p-4 md:rounded-lg">
            {list.length > 0 ? (
              <Table
                tableData={list}
                columnNames={columnNames}
                tableHeader={{
                  title: 'Lista de compras',
                  details:
                    'Veja as compras relacionadas ao ingresso selecionado. Pode ser que você veja uma quantidade diferente de itens na lista pois, uma compra pode ter mais de um ingresso vinculado.'
                }}
                showFooter
              />
            ) : (
              <Alerts message="Nenhum registro de compra encontrado" type="none" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
