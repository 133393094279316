import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alerts, SpinnerLoader } from '../../../../components';
import { useGetDocument } from '../../../../hooks';
import { AdditionalInfoBroadcastChat } from '../../../../interfaces';
import { ArenaChat } from './arena';

const BroadcastChatContent = () => {
  const { eventId = '' } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'BROADCAST_PAGE' });

  const { data } = useGetDocument<AdditionalInfoBroadcastChat | undefined>({
    collectionName: 'event',
    path: [eventId, 'additional-info', 'chat']
  });

  if (data && data.arena) {
    return <ArenaChat config={data.arena} />;
  }
  if (data && data.move) {
    return <>move chat</>;
  }
  return (
    <div className="py-4">
      <Alerts message={t('COMPONENTS.CHAT.NO_CONFIG')} type="none" />
    </div>
  );
};

export const BroadcastChat = () => (
  <Suspense
    fallback={
      <div className="mt-4">
        <SpinnerLoader />
      </div>
    }
  >
    <BroadcastChatContent />
  </Suspense>
);
