import { useContext } from 'react';
import { CheckoutPageContext } from '../contexts';

export const useCheckoutPage = () => {
  const context = useContext(CheckoutPageContext);
  if (!context) {
    throw new Error('useCheckoutPage deve ser usado dentro do CheckoutPageProvider.');
  }
  return context;
};
