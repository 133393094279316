import { useMemo } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import { PurchaseType, StagingPurchaseType } from '../../../../interfaces';
import { ComponentTypeEnum, SuspenseMenu, Table } from '../../../../components';
import { formatMonetaryToLocale, formatPhone } from '../../../../utils';

export const PurchaseDetail = ({ purchase }: { purchase: PurchaseType | StagingPurchaseType }) => {
  const propNames: { [key: string]: string } = {
    document: 'Documento',
    name: 'Nome',
    email: 'E-mail',
    documentType: 'Tipo do documento',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    state: 'Estado',
    street: 'Rua',
    zipCode: 'CEP',
    city: 'Cidade',
    placeNumber: 'Número',
    nacionality: 'Nacionalidade',
    title: 'Nome',
    quantity: 'Qtd.',
    value: 'Valor',
    valueDiscount: 'Com desconto',
    discount: 'Desc.',
    cellphone: 'Celular',
    ticketTitle: 'Ingresso'
  };

  const listTickets = useMemo(
    () =>
      purchase.tickets.map(({ title, quantity, value, id, coupon }) => {
        const discountPercent = coupon ? coupon.discount / 100 : 0;
        // prettier-ignore
        const discountValue: number = ((value * quantity) * discountPercent);
        return {
          title: <span className="truncate">{title}</span>,
          quantity,
          value: formatMonetaryToLocale({
            value,
            lang: 'pt-BR',
            style: 'currency',
            currency: 'BRL'
          }),
          discount: formatMonetaryToLocale({
            value: discountValue,
            lang: 'pt-BR',
            style: 'currency',
            currency: 'BRL'
          }),
          valueDiscount: {
            // prettier-ignore
            value: ((value * quantity) - discountValue),
            format: (formatValue: number) =>
              formatMonetaryToLocale({
                // prettier-ignore
                value: formatValue,
                lang: 'pt-BR',
                style: 'currency',
                currency: 'BRL'
              })
          },
          lineId: id
        };
      }),
    [purchase.tickets]
  );

  const listParticipants = useMemo(
    () =>
      purchase.participants?.map(({ cellphone, email, ticketTitle, firstName, lastName }) => ({
        name: `${firstName} ${lastName}`,
        cellphone: formatPhone({ phone: cellphone }),
        ticketTitle,
        lineId: email
      })),
    [purchase.participants]
  );

  const menuItems = {
    className:
      'absolute z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
    items: [
      {
        key: 'item-key-refund',
        componentType: ComponentTypeEnum.LINK,
        component: <>Estornar compra</>,
        onClick: '#',
        class: {
          base: 'block px-4 py-2 text-sm',
          active: 'bg-gray-100 text-gray-900',
          inactive: 'text-gray-700'
        }
      }
    ]
  };

  const menuButton = {
    className:
      'inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    component: (
      <>
        <span className="sr-only">Open options menu</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </>
    )
  };

  return (
    <div>
      <div className="pb-1">
        <div>
          <div className="sm:flex sm:items-end">
            <div className="sm:flex-1">
              <div className="flex items-center">
                <div>
                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                    {purchase.customer?.name ?? 'Comprador ainda não definido'}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {purchase.customer?.email ?? 'e-mail do comprador ainda não definido'}
                  </p>
                </div>
                <SuspenseMenu menuButton={menuButton} menuItems={menuItems} className="ml-4 inline-block text-left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 sm:pt-0">
        <h3 className="mt-4 font-medium text-gray-900">Ingressos</h3>
        <Table tableData={listTickets} columnNames={propNames} showFooter />
        {listParticipants && listParticipants.length > 0 ? (
          <>
            <h3 className="mt-8 font-medium text-gray-900">Participantes</h3>
            <Table tableData={listParticipants} columnNames={propNames} />
          </>
        ) : null}
      </div>
    </div>
  );
};
