import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { ChangeEventHandler, InputHTMLAttributes, ReactNode, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  labelChildren?: ReactNode;
  customStyle?: string;
  onChangeValue?: ChangeEventHandler<HTMLInputElement>;
  trailingIcon?: React.ReactNode;
};

export const InputLabel = ({
  name,
  label,
  type,
  onChangeValue,
  customStyle,
  labelChildren,
  trailingIcon,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const error = errors[name];
  const [array, index, prop] = name.split('.');
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const errorsArrayField = (errors as any)?.[array]?.[index]?.[prop];

  const inputError = useMemo(() => {
    if (error) return error;

    return errorsArrayField;
  }, [errorsArrayField, error]);

  return (
    <div className="w-full">
      {(label || labelChildren) && (
        <label htmlFor={name} className="flex w-full justify-between pb-1 text-sm font-medium text-gray-700">
          {label} {labelChildren}
        </label>
      )}
      <div className="relative">
        <input
          id={name}
          type={type}
          className={classNames(
            customStyle ?? 'w-full rounded-md border-gray-300 shadow-sm sm:text-sm',
            'focus:border-primary focus:ring-primary',
            {
              'border-red-400 focus:border-red-400 focus:ring-red-400': inputError
            }
          )}
          {...rest}
          {...register(name, { onChange: onChangeValue })}
        />
        {trailingIcon && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">{trailingIcon}</div>
        )}
      </div>
      {inputError && (
        <div className="flex items-center text-red-500">
          <div>
            <ExclamationCircleIcon className="mr-[3px] w-4" />
          </div>
          <h1>{t(String(inputError.message))}</h1>
        </div>
      )}
    </div>
  );
};
