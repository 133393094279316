import { createContext, ReactNode, useCallback, useMemo } from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

interface NotificationContextProps {
  successToast: (text: string, options?: ToastOptions) => void;
  errorToast: (text: string, options?: ToastOptions) => void;
  alertToast: (text: string, options?: ToastOptions) => void;
  infoToast: (text: string, options?: ToastOptions) => void;
}

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContext = createContext({} as NotificationContextProps);

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const successToast = useCallback((text: string, options?: ToastOptions) => {
    toast.success(text, options);
  }, []);
  const errorToast = useCallback((text: string, options?: ToastOptions) => {
    toast.error(text, options);
  }, []);
  const alertToast = useCallback((text: string, options?: ToastOptions) => {
    toast.warn(text, options);
  }, []);
  const infoToast = useCallback((text: string, options?: ToastOptions) => {
    toast.info(text, options);
  }, []);

  const memoizedValue = useMemo(
    () => ({ successToast, errorToast, alertToast, infoToast }),
    [successToast, errorToast, alertToast, infoToast]
  );

  return (
    <NotificationContext.Provider value={memoizedValue}>
      {children}
      <ToastContainer autoClose={5000} />
    </NotificationContext.Provider>
  );
};
