import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { SelectHTMLAttributes, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Option = {
  name: string | number;
  value: string | number;
};

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  defaultValue: string | number;
  options: Option[];
};

export const SelectLabel = ({ name, label, defaultValue, options, ...rest }: Props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const error = errors[name];
  const [array, index, prop] = name.split('.');

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const errorsArrayField = (errors as any)?.[array]?.[index]?.[prop];

  const inputError = useMemo(() => {
    if (error) return error;

    return errorsArrayField;
  }, [errorsArrayField, error]);

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id={name}
        className={`mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm  ${
          inputError
            ? 'border-red-400 focus:border-red-400 focus:ring-red-400'
            : 'focus:border-primary focus:ring-primary'
        }`}
        defaultValue={defaultValue}
        {...rest}
        {...register(name)}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {inputError && (
        <div className="flex items-center text-red-500">
          <div>
            <ExclamationCircleIcon className="mr-[3px] w-4" />
          </div>
          <h1>{t(String(inputError.message))}</h1>
        </div>
      )}
    </div>
  );
};
