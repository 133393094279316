export const defaultCountryValue = 'BR';

export const countries = [
  {
    name: 'Afeganistão',
    value: 'AF'
  },
  {
    name: 'África do Sul',
    value: 'ZA'
  },
  {
    name: 'Albânia',
    value: 'AL'
  },
  {
    name: 'Alemanha',
    value: 'DE'
  },
  {
    name: 'Andorra',
    value: 'AD'
  },
  {
    name: 'Angola',
    value: 'AO'
  },
  {
    name: 'Anguilla',
    value: 'AI'
  },
  {
    name: 'Antártida',
    value: 'AQ'
  },
  {
    name: 'Antígua e Barbuda',
    value: 'AG'
  },
  {
    name: 'Antilhas Holandesas',
    value: 'AN'
  },
  {
    name: 'Arábia Saudita',
    value: 'SA'
  },
  {
    name: 'Argélia',
    value: 'DZ'
  },
  {
    name: 'Argentina',
    value: 'AR'
  },
  {
    name: 'Armênia',
    value: 'AM'
  },
  {
    name: 'Aruba',
    value: 'AW'
  },
  {
    name: 'Austrália',
    value: 'AU'
  },
  {
    name: 'Áustria',
    value: 'AT'
  },
  {
    name: 'Azerbaijão',
    value: 'AZ  '
  },
  {
    name: 'Bahamas',
    value: 'BS'
  },
  {
    name: 'Bahrein',
    value: 'BH'
  },
  {
    name: 'Bangladesh',
    value: 'BD'
  },
  {
    name: 'Barbados',
    value: 'BB'
  },
  {
    name: 'Belarus',
    value: 'BY'
  },
  {
    name: 'Bélgica',
    value: 'BE'
  },
  {
    name: 'Belize',
    value: 'BZ'
  },
  {
    name: 'Benin',
    value: 'BJ'
  },
  {
    name: 'Bermudas',
    value: 'BM'
  },
  {
    name: 'Bolívia',
    value: 'BO'
  },
  {
    name: 'Bósnia-Herzegóvina',
    value: 'BA'
  },
  {
    name: 'Botsuana',
    value: 'BW'
  },
  {
    name: 'Brasil',
    value: 'BR'
  },
  {
    name: 'Brunei',
    value: 'BN'
  },
  {
    name: 'Bulgária',
    value: 'BG'
  },
  {
    name: 'Burkina Fasso',
    value: 'BF'
  },
  {
    name: 'Burundi',
    value: 'BI'
  },
  {
    name: 'Butão',
    value: 'BT'
  },
  {
    name: 'Cabo Verde',
    value: 'CV'
  },
  {
    name: 'Camarões',
    value: 'CM'
  },
  {
    name: 'Camboja',
    value: 'KH'
  },
  {
    name: 'Canadá',
    value: 'CA'
  },
  {
    name: 'Cazaquistão',
    value: 'KZ'
  },
  {
    name: 'Chade',
    value: 'TD'
  },
  {
    name: 'Chile',
    value: 'CL'
  },
  {
    name: 'China',
    value: 'CN'
  },
  {
    name: 'Chipre',
    value: 'CY'
  },
  {
    name: 'Cingapura',
    value: 'SG'
  },
  {
    name: 'Colômbia',
    value: 'CO'
  },
  {
    name: 'Congo',
    value: 'CG'
  },
  {
    name: 'Coréia do Norte',
    value: 'KP'
  },
  {
    name: 'Coréia do Sul',
    value: 'KR'
  },
  {
    name: 'Costa do Marfim',
    value: 'CI'
  },
  {
    name: 'Costa Rica',
    value: 'CR'
  },
  {
    name: 'Croácia (Hrvatska)',
    value: 'HR'
  },
  {
    name: 'Cuba',
    value: 'CU'
  },
  {
    name: 'Dinamarca',
    value: 'DK'
  },
  {
    name: 'Djibuti',
    value: 'DJ'
  },
  {
    name: 'Dominica',
    value: 'DM'
  },
  {
    name: 'Egito',
    value: 'EG'
  },
  {
    name: 'El Salvador',
    value: 'SV'
  },
  {
    name: 'Emirados Árabes Unidos',
    value: 'AE'
  },
  {
    name: 'Equador',
    value: 'EC'
  },
  {
    name: 'Eritréia',
    value: 'ER'
  },
  {
    name: 'Eslováquia',
    value: 'SK'
  },
  {
    name: 'Eslovênia',
    value: 'SI'
  },
  {
    name: 'Espanha',
    value: 'ES'
  },
  {
    name: 'Estados Unidos',
    value: 'US'
  },
  {
    name: 'Estônia',
    value: 'EE'
  },
  {
    name: 'Etiópia',
    value: 'ET'
  },
  {
    name: 'Fiji',
    value: 'FJ'
  },
  {
    name: 'Filipinas',
    value: 'PH'
  },
  {
    name: 'Finlândia',
    value: 'FI'
  },
  {
    name: 'França',
    value: 'FR'
  },
  {
    name: 'Gabão',
    value: 'GA'
  },
  {
    name: 'Gâmbia',
    value: 'GM'
  },
  {
    name: 'Gana',
    value: 'GH'
  },
  {
    name: 'Geórgia',
    value: 'GE'
  },
  {
    name: 'Gibraltar',
    value: 'GI'
  },
  {
    name: 'Grã-Bretanha (Reino Unido, UK)',
    value: 'GB'
  },
  {
    name: 'Granada',
    value: 'GD'
  },
  {
    name: 'Grécia',
    value: 'GR'
  },
  {
    name: 'Groelândia',
    value: 'GL'
  },
  {
    name: 'Guadalupe',
    value: 'GP'
  },
  {
    name: 'Guam (Território dos Estados Unidos)',
    value: 'GU'
  },
  {
    name: 'Guatemala',
    value: 'GT'
  },
  {
    name: 'Guernsey',
    value: 'G'
  },
  {
    name: 'Guiana',
    value: 'GY'
  },
  {
    name: 'Guiana Francesa',
    value: 'GF'
  },
  {
    name: 'Guiné',
    value: 'GN'
  },
  {
    name: 'Guiné Equatorial',
    value: 'GQ'
  },
  {
    name: 'Guiné-Bissau',
    value: 'GW'
  },
  {
    name: 'Haiti',
    value: 'HT'
  },
  {
    name: 'Holanda',
    value: 'NL'
  },
  {
    name: 'Honduras',
    value: 'HN'
  },
  {
    name: 'Hong Kong',
    value: 'HK'
  },
  {
    name: 'Hungria',
    value: 'HU'
  },
  {
    name: 'Iêmen',
    value: 'YE'
  },
  {
    name: 'Ilha Bouvet (Território da Noruega)',
    value: 'BV'
  },
  {
    name: 'Ilha do Homem',
    value: 'IM'
  },
  {
    name: 'Ilha Natal',
    value: 'CX'
  },
  {
    name: 'Ilha Pitcairn',
    value: 'PN'
  },
  {
    name: 'Ilha Reunião',
    value: 'RE'
  },
  {
    name: 'Ilhas Aland',
    value: 'AX'
  },
  {
    name: 'Ilhas Cayman',
    value: 'KY'
  },
  {
    name: 'Ilhas Cocos',
    value: 'CC'
  },
  {
    name: 'Ilhas Comores',
    value: 'KM'
  },
  {
    name: 'Ilhas Cook',
    value: 'CK'
  },
  {
    name: 'Ilhas Faroes',
    value: 'FO'
  },
  {
    name: 'Ilhas Falkland (Malvinas)',
    value: 'FK'
  },
  {
    name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    value: 'GS'
  },
  {
    name: 'Ilhas Heard e McDonald (Território da Austrália)',
    value: 'HM'
  },
  {
    name: 'Ilhas Marianas do Norte',
    value: 'MP'
  },
  {
    name: 'Ilhas Marshall',
    value: 'MH'
  },
  {
    name: 'Ilhas Menores dos Estados Unidos',
    value: 'UM'
  },
  {
    name: 'Ilhas Norfolk',
    value: 'NF'
  },
  {
    name: 'Ilhas Seychelles',
    value: 'SC'
  },
  {
    name: 'Ilhas Solomão',
    value: 'SB'
  },
  {
    name: 'Ilhas Svalbard e Jan Mayen',
    value: 'SJ'
  },
  {
    name: 'Ilhas Tokelau',
    value: 'TK'
  },
  {
    name: 'Ilhas Turks e Caicos',
    value: 'TC'
  },
  {
    name: 'Ilhas Virgens (Estados Unidos)',
    value: 'VI'
  },
  {
    name: 'Ilhas Virgens (Inglaterra)',
    value: 'VG'
  },
  {
    name: 'Ilhas Wallis e Futuna',
    value: 'WF'
  },
  {
    name: 'índia',
    value: 'IN'
  },
  {
    name: 'Indonésia',
    value: 'ID'
  },
  {
    name: 'Irã',
    value: 'IR'
  },
  {
    name: 'Iraque',
    value: 'IQ'
  },
  {
    name: 'Irlanda',
    value: 'IE'
  },
  {
    name: 'Islândia',
    value: 'IS'
  },
  {
    name: 'Israel',
    value: 'IL'
  },
  {
    name: 'Itália',
    value: 'IT'
  },
  {
    name: 'Jamaica',
    value: 'JM'
  },
  {
    name: 'Japão',
    value: 'JP'
  },
  {
    name: 'Jersey',
    value: 'JE'
  },
  {
    name: 'Jordânia',
    value: 'JO'
  },
  {
    name: 'Kênia',
    value: 'KE'
  },
  {
    name: 'Kiribati',
    value: 'KI'
  },
  {
    name: 'Kuait',
    value: 'KW'
  },
  {
    name: 'Laos',
    value: 'LA'
  },
  {
    name: 'Látvia',
    value: 'LV'
  },
  {
    name: 'Lesoto',
    value: 'LS'
  },
  {
    name: 'Líbano',
    value: 'LB'
  },
  {
    name: 'Libéria',
    value: 'LR'
  },
  {
    name: 'Líbia',
    value: 'LY'
  },
  {
    name: 'Liechtenstein',
    value: 'LI'
  },
  {
    name: 'Lituânia',
    value: 'LT'
  },
  {
    name: 'Luxemburgo',
    value: 'LU'
  },
  {
    name: 'Macau',
    value: 'MO'
  },
  {
    name: 'Macedônia (República Yugoslava)',
    value: 'MK'
  },
  {
    name: 'Madagascar',
    value: 'MG'
  },
  {
    name: 'Malásia',
    value: 'MY'
  },
  {
    name: 'Malaui',
    value: 'MW'
  },
  {
    name: 'Maldivas',
    value: 'MV'
  },
  {
    name: 'Mali',
    value: 'ML'
  },
  {
    name: 'Malta',
    value: 'MT'
  },
  {
    name: 'Marrocos',
    value: 'MA'
  },
  {
    name: 'Martinica',
    value: 'MQ'
  },
  {
    name: 'Maurício',
    value: 'MU'
  },
  {
    name: 'Mauritânia',
    value: 'MR'
  },
  {
    name: 'Mayotte',
    value: 'YT'
  },
  {
    name: 'México',
    value: 'MX'
  },
  {
    name: 'Micronésia',
    value: 'FM'
  },
  {
    name: 'Moçambique',
    value: 'MZ'
  },
  {
    name: 'Moldova',
    value: 'MD'
  },
  {
    name: 'Mônaco',
    value: 'MC'
  },
  {
    name: 'Mongólia',
    value: 'MN'
  },
  {
    name: 'Montenegro',
    value: 'ME'
  },
  {
    name: 'Montserrat',
    value: 'MS'
  },
  {
    name: 'Myanma',
    value: 'MM'
  },
  {
    name: 'Namíbia',
    value: 'NA'
  },
  {
    name: 'Nauru',
    value: 'NR'
  },
  {
    name: 'Nepal',
    value: 'NP'
  },
  {
    name: 'Nicarágua',
    value: 'NI'
  },
  {
    name: 'Níger',
    value: 'NE'
  },
  {
    name: 'Nigéria',
    value: 'NG'
  },
  {
    name: 'Niue',
    value: 'NU'
  },
  {
    name: 'Noruega',
    value: 'NO'
  },
  {
    name: 'Nova Caledônia',
    value: 'NC'
  },
  {
    name: 'Nova Zelândia',
    value: 'NZ'
  },
  {
    name: 'Omã',
    value: 'OM'
  },
  {
    name: 'Palau',
    value: 'PW'
  },
  {
    name: 'Panamá',
    value: 'PA'
  },
  {
    name: 'Papua-Nova Guiné',
    value: 'PG'
  },
  {
    name: 'Paquistão',
    value: 'PK'
  },
  {
    name: 'Paraguai',
    value: 'PY'
  },
  {
    name: 'Peru',
    value: 'PE'
  },
  {
    name: 'Polinésia Francesa',
    value: 'PF'
  },
  {
    name: 'Polônia',
    value: 'PL'
  },
  {
    name: 'Porto Rico',
    value: 'PR'
  },
  {
    name: 'Portugal',
    value: 'PT'
  },
  {
    name: 'Qatar',
    value: 'QA'
  },
  {
    name: 'Quirguistão',
    value: 'KG'
  },
  {
    name: 'República Centro-Africana',
    value: 'CF'
  },
  {
    name: 'República Democrática do Congo',
    value: 'CD'
  },
  {
    name: 'República Dominicana',
    value: 'DO'
  },
  {
    name: 'República Tcheca',
    value: 'CZ'
  },
  {
    name: 'Romênia',
    value: 'RO'
  },
  {
    name: 'Ruanda',
    value: 'RW'
  },
  {
    name: 'Rússia (antiga URSS) - Federação Russa',
    value: 'RU'
  },
  {
    name: 'Saara Ocidental',
    value: 'EH'
  },
  {
    name: 'Saint Vincente e Granadinas',
    value: 'VC'
  },
  {
    name: 'Samoa Americana',
    value: 'AS'
  },
  {
    name: 'Samoa Ocidental',
    value: 'WS'
  },
  {
    name: 'San Marino',
    value: 'SM'
  },
  {
    name: 'Santa Helena',
    value: 'SH'
  },
  {
    name: 'Santa Lúcia',
    value: 'LC'
  },
  {
    name: 'São Bartolomeu',
    value: 'BL'
  },
  {
    name: 'São Cristóvão e Névis',
    value: 'KN'
  },
  {
    name: 'São Martim',
    value: 'MF'
  },
  {
    name: 'São Tomé e Príncipe',
    value: 'ST'
  },
  {
    name: 'Senegal',
    value: 'SN'
  },
  {
    name: 'Serra Leoa',
    value: 'SL'
  },
  {
    name: 'Sérvia',
    value: 'RS'
  },
  {
    name: 'Síria',
    value: 'SY'
  },
  {
    name: 'Somália',
    value: 'SO'
  },
  {
    name: 'Sri Lanka',
    value: 'LK'
  },
  {
    name: 'St. Pierre and Miquelon',
    value: 'PM'
  },
  {
    name: 'Suazilândia',
    value: 'SZ'
  },
  {
    name: 'Sudão',
    value: 'SD'
  },
  {
    name: 'Suécia',
    value: 'SE'
  },
  {
    name: 'Suíça',
    value: 'CH'
  },
  {
    name: 'Suriname',
    value: 'SR'
  },
  {
    name: 'Tadjiquistão',
    value: 'TJ'
  },
  {
    name: 'Tailândia',
    value: 'TH'
  },
  {
    name: 'Taiwan',
    value: 'TW'
  },
  {
    name: 'Tanzânia',
    value: 'TZ'
  },
  {
    name: 'Território Britânico do Oceano índico',
    value: 'IO'
  },
  {
    name: 'Territórios do Sul da França',
    value: 'TF'
  },
  {
    name: 'Territórios Palestinos Ocupados',
    value: 'PS'
  },
  {
    name: 'Timor Leste',
    value: 'TP'
  },
  {
    name: 'Togo',
    value: 'TG'
  },
  {
    name: 'Tonga',
    value: 'TO'
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TT'
  },
  {
    name: 'Tunísia',
    value: 'TN'
  },
  {
    name: 'Turcomenistão',
    value: 'TM'
  },
  {
    name: 'Turquia',
    value: 'TR'
  },
  {
    name: 'Tuvalu',
    value: 'TV'
  },
  {
    name: 'Ucrânia',
    value: 'UA'
  },
  {
    name: 'Uganda',
    value: 'UG'
  },
  {
    name: 'Uruguai',
    value: 'UY'
  },
  {
    name: 'Uzbequistão',
    value: 'UZ'
  },
  {
    name: 'Vanuatu',
    value: 'VU'
  },
  {
    name: 'Vaticano',
    value: 'VA'
  },
  {
    name: 'Venezuela',
    value: 'VE'
  },
  {
    name: 'Vietnã',
    value: 'VN'
  },
  {
    name: 'Zâmbia',
    value: 'ZM'
  },
  {
    name: 'Zimbábue',
    value: 'ZW'
  }
];
