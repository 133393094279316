import * as yup from 'yup';
import { validateCreditCard } from '../../../../../utils';
import { ReceiveReceiptsFormSchema } from '../../receive-receipts/form-validation';

const ignoreCreditCardValidation = process.env.REACT_APP_IGNORE_CREDIT_CARD_VALIDATION === 'true';

export const CreditCardAndReceiptsFormSchema = ReceiveReceiptsFormSchema.shape({
  number: yup.string().when('$isCreditCardFormDisabled', (isCreditCardFormDisabled, schema) => {
    if (!isCreditCardFormDisabled) {
      return schema
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
        .min(16, 'CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.WARNINGS.CARD_NUMBER_INVALID')
        .max(19)
        .test(
          'isValid',
          'CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.WARNINGS.CARD_NUMBER_INVALID',
          (value: string | undefined) => {
            if (ignoreCreditCardValidation) return true; // em dev não podemos travar o uso dos cartões de teste
            if (!value) return false;
            return validateCreditCard(value);
          }
        );
    }
    return schema;
  }),
  holderName: yup
    .string()
    .max(50)
    .when('$isCreditCardFormDisabled', (isCreditCardFormDisabled, schema) => {
      if (!isCreditCardFormDisabled) {
        return schema.required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD');
      }
      return schema;
    }),
  expMonth: yup.number(),
  expYear: yup.number(),
  cvv: yup.string().when('$isCreditCardFormDisabled', (isCreditCardFormDisabled, schema) => {
    if (!isCreditCardFormDisabled) {
      return schema
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
        .matches(/^\d+$/, 'CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.WARNINGS.CVV_ONLY_NUMBERS')
        .min(3, 'CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.WARNINGS.MIN_MAX_NUMBERS')
        .max(4, 'CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.WARNINGS.MIN_MAX_NUMBERS');
    }
    return schema;
  }),
  installments: yup.number()
});
