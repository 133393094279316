import { createContext, Suspense, useMemo } from 'react';
import { Dictionary } from 'lodash';

import { useGetCollectionData } from '../hooks';
import { groupBy, formatDateToLocale } from '../utils';
import { Activity, ActivityDisplayOnEnum, GroupedActivities } from '../interfaces';

interface ContextProps {
  groupedActivities: Dictionary<Array<GroupedActivities>>;
}

interface ActivitiesProviderProps {
  children: React.ReactNode;
  eventId: string;
}

export const ActivitiesContext = createContext({} as ContextProps);

export const ActivitiesProvider: React.FC<ActivitiesProviderProps> = ({
  children,
  eventId
}: ActivitiesProviderProps) => {
  const { data } = useGetCollectionData<Activity>({
    collectionName: 'event',
    path: [eventId, 'activities'],
    constraints: [
      { fieldPath: 'displayOn', opStr: 'in', value: [ActivityDisplayOnEnum.BOTH, ActivityDisplayOnEnum.WEB] }
    ],
    orderBy: {
      direction: 'asc',
      field: 'date'
    }
  });

  const activities = data.map(item => ({
    ...item,
    date: formatDateToLocale({ date: item.date, style: 'Pp', lang: 'pt' })
  }));

  const groupedActivities = groupBy(activities, 'tabGroupName');

  const value = useMemo(() => ({ groupedActivities }), [groupedActivities]);

  return (
    <ActivitiesContext.Provider value={value}>
      <Suspense>{children}</Suspense>
    </ActivitiesContext.Provider>
  );
};
