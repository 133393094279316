import { getCurrentLanguage } from '../../i18n';
import { TicketWithTier } from '../../interfaces/ticket';

type PricingTiersProps = Pick<TicketWithTier, 'tiers'> & { formatCurrency: (monetaryValue: number) => string };

export const PricingTiers: React.FC<PricingTiersProps> = ({ tiers, formatCurrency }: PricingTiersProps) => {
  const lang = getCurrentLanguage();

  const formattedTiers = tiers.map(tier => ({
    ...tier,
    value: formatCurrency(tier.value)
  }));

  return (
    <div className="mt-8">
      <ul className="flex flex-col divide-y divide-gray-200 sm:flex-row sm:divide-x sm:divide-y-0">
        {formattedTiers &&
          formattedTiers.map(tier => (
            <li key={tier.title.pt} className={`${tier.current ? 'text-primary' : 'text-gray-400'} py-4 sm:px-4`}>
              <span className="text-sm font-medium uppercase">{tier.title[lang] ?? tier.title.pt}</span>
              <h3
                className={`text-2xl font-bold
                ${tier.isOlderThanCurrent && 'line-through'}
            `}
              >
                {tier.value}
              </h3>
              <p
                className={`mt-2 text-sm
                ${tier.current ? 'text-gray-900' : 'text-gray-400'}
                ${tier.isOlderThanCurrent && 'line-through'}
            `}
              >
                {tier.deadline}
              </p>
            </li>
          ))}
      </ul>
    </div>
  );
};
