import { useTranslation } from 'react-i18next';
import { Activity } from '../../../../interfaces';
import { SpeakerCard } from '../../../modal/activity/speaker-card';

type SpeakersProps = {
  speakers: Activity['speakers'];
};

export const Speakers = ({ speakers }: SpeakersProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'BROADCAST_PAGE' });

  return (
    <div>
      <p className="mb-[18px] text-sm font-medium sm:text-lg">{t('COMPONENTS.DESCRIPTION.SPEAKERS')}</p>
      {!!speakers &&
        speakers?.map(speaker => (
          <div key={speaker.name} className="space-y-4">
            <SpeakerCard speaker={speaker} />
          </div>
        ))}
    </div>
  );
};
