import { connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore';
import { FirestoreProvider, useInitFirestore } from 'reactfire';

export const ReactFireFirestoreProvider: React.FC<React.PropsWithChildren> = ({
  children
}: React.PropsWithChildren) => {
  const useEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

  const { data: firestoreInstance } = useInitFirestore(async firebaseApp => {
    const firestore = initializeFirestore(firebaseApp, {});
    // TODO: não funciona com reactfire
    // em desenvolvimento local não precisamos habilitar o modo de persistencia
    // await enableIndexedDbPersistence(firestore);
    if (useEmulator) {
      connectFirestoreEmulator(firestore, 'localhost', 8080);
    }
    return firestore;
  });
  return <FirestoreProvider sdk={firestoreInstance}>{children}</FirestoreProvider>;
};
