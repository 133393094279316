import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonWithIcon } from '../../../../../../components';

type PaymetFailed = {
  customerName: string | undefined;
};

export const PaymentFailed: React.FC<PaymetFailed> = ({ customerName }: PaymetFailed) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleReturnToHome = () => navigate('/');
  return (
    <>
      <div className="ml-6 flex flex-col items-center gap-6">
        <ExclamationCircleIcon className="w-20 text-red-500" />
        <div className="text-center text-lg font-medium text-red-500">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.TITLE')}
        </div>
        <div className="text-center">
          <Trans
            i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.DESCRIPTION"
            values={{
              customerName
            }}
          />
        </div>
      </div>
      <div>
        <span className="text-start">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.POSSIBLE_ERRORS.TITLE')}
        </span>
        <ul className="ml-6 mt-2 list-disc">
          <li>{t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.POSSIBLE_ERRORS.ITEM_1')}</li>
          <li>{t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.POSSIBLE_ERRORS.ITEM_2')}</li>
          <li>{t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.POSSIBLE_ERRORS.ITEM_3')}</li>
        </ul>
      </div>
      <div className="flex justify-center">
        <ButtonWithIcon
          onClick={handleReturnToHome}
          type="button"
          customStylesButton="flex w-1/2 text-white bg-green-500 hover:bg-green-600 "
        >
          {t('CHECKOUT.BUTTONS.TRY_AGAIN')}
        </ButtonWithIcon>
      </div>
    </>
  );
};
