import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

type Option = {
  name: string | number;
  value: string | number;
};

type Props = {
  name: string;
  label: string;
  options: Option[];
  disabled?: boolean;
};

export const InputRadioGroup = ({ name, label, options, disabled }: Props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const error = errors[name];
  const [array, index, prop] = name.split('.');
  const errorsArrayField = (errors as never)?.[array]?.[index]?.[prop];

  const inputError = useMemo(() => {
    if (error) return error;

    return errorsArrayField;
  }, [errorsArrayField, error]);

  return (
    <div>
      <label htmlFor={name} className="block pb-1 text-sm font-medium text-gray-700">
        {label}
      </label>
      <fieldset className="mt-2">
        <legend className="sr-only">Opções {label}</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
          {options.map(option => (
            <div key={option.value} className="flex items-center">
              <input
                id={`${name}_${option.value}`}
                value={option.value}
                type="radio"
                className="h-4 w-4 cursor-pointer border-gray-600 text-primary focus:ring-primary"
                disabled={disabled}
                {...register(name)}
              />
              <label htmlFor={`${name}_${option.value}`} className="ml-2 block text-sm font-medium text-gray-700">
                {t(`${option.name}`)}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {inputError && (
        <div className="flex items-center text-red-500">
          <div>
            <ExclamationCircleIcon className="mr-[3px] w-4" />
          </div>
          <h1>{t(String(inputError.message))}</h1>
        </div>
      )}
    </div>
  );
};
