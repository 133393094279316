import { Timestamp } from 'firebase/firestore';
import { PurchaseParticipantType } from './purchase';

export enum ActionLogTypeEnum {
  TRANSFER = 'transfer',
  REFUND = 'refund'
}

export type PurchaseTransferActionLog = {
  purchaseId: string;
  from?: {
    name: string;
    email: string;
  };
  to?: {
    name: string;
    email: string;
  };
};

export type PurchaseRefundActionLog = {
  purchaseId: string;
} & PurchaseParticipantType;

export type ActionLogType<T> = {
  collection: string;
  eventId: string;
  actionType: ActionLogTypeEnum;
  user: string;
  createdAt: Timestamp;
  data: T;
};
