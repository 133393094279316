import { signInWithEmailAndPassword, signInWithCustomToken, signOut, UserCredential } from 'firebase/auth';
import React, { createContext, Suspense, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { useFirebaseFunctions } from '../hooks';
import { md5HashAsciiStringLMS } from '../utils';

export type AuthLoginRequestType = {
  user: string;
  password: string;
  recaptchaToken: string | undefined;
};
type AuthLoginResponseType = {
  [key: string]: string | boolean;
};

interface ContextProps {
  handleAuthLoginRequest: (callableParams: AuthLoginRequestType) => Promise<AuthLoginResponseType | undefined>;
  signInFirebase: (params: Omit<AuthLoginRequestType, 'recaptchaToken'>) => Promise<UserCredential>;
  signInCustomToken: (token: string) => Promise<UserCredential>;
  signOutFirebase: (redirect: boolean) => Promise<void>;
}

export const AuthenticationContext = createContext({} as ContextProps);

export const AuthenticationProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const { onCall } = useFirebaseFunctions();
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { eventId = '' } = params;

  const handleAuthLoginRequest = useCallback(
    (callableParams: AuthLoginRequestType) =>
      onCall<AuthLoginRequestType, AuthLoginResponseType>({
        functionName: 'http-cal-auth-login',
        callableParams: { ...callableParams, password: md5HashAsciiStringLMS(callableParams.password) }
      }),
    [onCall]
  );

  const signInFirebase = useCallback(
    ({ user, password }: Omit<AuthLoginRequestType, 'recaptchaToken'>) =>
      signInWithEmailAndPassword(auth, user, md5HashAsciiStringLMS(password)),
    [auth]
  );

  const signInCustomToken = useCallback((token: string) => signInWithCustomToken(auth, token), [auth]);

  const signOutFirebase = useCallback(
    async (redirect: boolean) => {
      await signOut(auth);
      if (redirect) navigate(`/${eventId}`);
    },
    [auth, eventId, navigate]
  );

  const value = useMemo(
    () => ({
      handleAuthLoginRequest,
      signInFirebase,
      signInCustomToken,
      signOutFirebase
    }),
    [handleAuthLoginRequest, signInCustomToken, signInFirebase, signOutFirebase]
  );
  return (
    <AuthenticationContext.Provider value={value}>
      <Suspense>{children}</Suspense>
    </AuthenticationContext.Provider>
  );
};
