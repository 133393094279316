import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputNumberPhone from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  customStyle?: string;
  country: string;
  defaultCountry: string;
  disabled?: boolean;
};

export const InputPhone = ({ name, label, customStyle, country, defaultCountry, disabled }: Props) => {
  const { t } = useTranslation();
  const [newCountryValue, setNewCountryValue] = useState<string>(defaultCountry);

  const {
    formState: { errors },
    control,
    setValue
  } = useFormContext();

  const error = errors[name];
  const [array, index, prop] = name.split('.');
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const errorsArrayField = (errors as any)?.[array]?.[index]?.[prop];

  const inputError = useMemo(() => {
    if (error) return error;

    return errorsArrayField;
  }, [errorsArrayField, error]);

  useEffect(() => {
    if (country && country !== newCountryValue) {
      setValue(name, '');
      setNewCountryValue(country);
    }
  }, [country, name, setValue, newCountryValue]);

  return (
    <>
      <label htmlFor={name} className="block pb-1 text-sm font-medium text-gray-700">
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref, ...field } }) => (
          <InputNumberPhone
            {...field}
            country={newCountryValue}
            value={value}
            onChange={onChange}
            disabled={disabled}
            specialLabel={undefined}
            countryCodeEditable={false}
            disableDropdown
            inputProps={{
              ref,
              required: true
            }}
            buttonStyle={{
              borderColor: inputError ? '#ef4444' : '#d1d5db'
            }}
            inputStyle={{
              width: '100%',
              borderColor: inputError ? '#ef4444' : '#d1d5db'
            }}
            inputClass={`${customStyle ?? 'shadow-sm w-full sm:text-sm rounded-md'} 
              ${inputError ? 'focus:border-red-400 focus:ring-red-400' : 'focus:ring-primary focus:border-primary'}
            `}
          />
        )}
      />
      {inputError && (
        <div className="flex items-center text-red-500">
          <div>
            <ExclamationCircleIcon className="mr-[3px] w-4" />
          </div>
          <h1>{t(String(inputError.message))}</h1>
        </div>
      )}
    </>
  );
};
