import { useLandingPage } from '../../hooks';
import { BroadcastLiveAlert } from './live';
import { BroadcastRecordAlert } from './record';

export const BroadcastAlert = () => {
  const { event } = useLandingPage();
  return (
    <>
      <BroadcastRecordAlert event={event} />
      <BroadcastLiveAlert event={event} />
    </>
  );
};
