import { CalendarIcon, ComputerDesktopIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { useCheckoutPage } from '../../../../hooks';
import { EventTypeEnum } from '../../../../interfaces';

export const EventDetails: React.FC = () => {
  const { event } = useCheckoutPage();

  return (
    <div className="flex flex-col gap-2">
      <h2 className="pb-1 text-3xl font-extrabold text-neutral-900 line-clamp-2 sm:text-3xl sm:tracking-tight lg:text-4xl">
        {event.name}
      </h2>
      <p className="my-4 text-lg text-neutral-700 line-clamp-1">{event.shortDescription}</p>
      <p className="flex items-center capitalize text-neutral-700 sm:text-sm">
        <CalendarIcon className="mr-2 hidden sm:block sm:h-6  sm:w-6" />
        {event.duration}
      </p>
      <p className="flex items-center text-neutral-700 sm:text-sm">
        {event.type === EventTypeEnum.ONLINE ? (
          <ComputerDesktopIcon className="mr-2 hidden sm:block sm:h-6 sm:w-6" />
        ) : (
          <MapPinIcon className="mr-2 hidden sm:block sm:h-6 sm:w-6" />
        )}
        <span>{event.type === EventTypeEnum.ONLINE ? event.eventType : event.location ?? 'Local a definir'}</span>
      </p>
    </div>
  );
};
