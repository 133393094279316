import { createContext, Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useGetDocument } from '../hooks';
import {
  AdditionalInfoSettings,
  Event,
  EventModulesEnum,
  EventTypeEnum,
  LandingPageNavigationType,
  NavigationType
} from '../interfaces';

interface ContextProps {
  eventId: string;
  event: Event;
  eventLocation: {
    fullAddress: string;
  };
  navigation: LandingPageNavigationType;
  handleCheckoutPage: (purchaseId: string) => void;
  subscriptionModule: EventModulesEnum | undefined;
}

const LoadingFallback = () => (
  <>
    <div className="relative animate-pulse border bg-slate-200 sm:overflow-hidden sm:rounded-2xl">
      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <div className="h-12 rounded bg-slate-300"></div>

        <div className="mt-6 px-12">
          <div className="h-4 rounded bg-slate-300"></div>
        </div>

        <div className="mt-12 grid grid-flow-row auto-rows-max justify-center">
          <div className="h-4 w-48 rounded bg-slate-300"></div>
          <div className="mt-2 h-4 w-48 rounded bg-slate-300"></div>
        </div>
        <div className="mt-12 flex justify-center">
          <div className="h-10 w-32 rounded bg-slate-300"></div>
        </div>
      </div>
    </div>
    <div className="my-8 animate-pulse rounded-2xl border bg-slate-200 p-8">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <div className="h-12 rounded bg-slate-300"></div>
        </div>
        <div className="mx-auto mt-6">
          <div className="h-6 w-40 rounded bg-slate-300 md:w-64"></div>
          <div className="mt-4 h-16 rounded bg-slate-300"></div>
          <div className="mt-4 h-28 rounded bg-slate-300"></div>
          <div className="mt-4 h-36 rounded bg-slate-300"></div>
        </div>
      </div>
    </div>
  </>
);

export const LandingPageContext = createContext({} as ContextProps);

export const LandingPageProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const { eventId = '' } = params;
  const { data: event } = useGetDocument<Event>({ collectionName: 'event', path: [eventId] });

  const handleCheckoutPage = useCallback((purchaseId: string) => navigate(`/checkout/${purchaseId}`), [navigate]);

  const { data: eventLocation } = useGetDocument<{ fullAddress: string }>({
    collectionName: 'event',
    path: [eventId, 'additional-info', 'location']
  });

  const { data: additionalInfoSettings } = useGetDocument<AdditionalInfoSettings>({
    collectionName: 'event',
    path: [eventId, 'additional-info', 'settings']
  });

  // verifica se o modulo de inscrição está configurado para o evento
  const subscriptionModule = useMemo(
    () => additionalInfoSettings.modules.find(f => f === EventModulesEnum.INSCRICAO),
    [additionalInfoSettings.modules]
  );

  const navigation = useMemo(() => {
    const defaultNavigation: Array<NavigationType> = [
      { name: t('LANDING_PAGE.NAVIGATION_BAR.ABOUT'), id: 'about', linkTo: '' },
      { name: t('LANDING_PAGE.NAVIGATION_BAR.SIGN_UP'), id: 'subscription', linkTo: '' },
      { name: t('LANDING_PAGE.NAVIGATION_BAR.ACTIVITIES'), id: 'activity', linkTo: '' },
      { name: t('LANDING_PAGE.NAVIGATION_BAR.EVENTS_PLACE'), id: 'eventAddress', linkTo: '' }
    ];

    if (event && event.type === EventTypeEnum.ONLINE) {
      const index = defaultNavigation.findIndex(n => n.id === 'eventAddress');
      defaultNavigation.splice(index, 1);
    }

    if (!subscriptionModule) {
      const index = defaultNavigation.findIndex(n => n.id === 'subscription');
      defaultNavigation.splice(index, 1);
    }

    const footerNavigation: Array<NavigationType> = [
      ...defaultNavigation,
      { name: t('LANDING_PAGE.NAVIGATION_BAR.TERMS'), id: 'terms', linkTo: 'terms-of-use' },
      { name: t('LANDING_PAGE.NAVIGATION_BAR.PRIVACY'), id: 'privacy', linkTo: 'privacy-policy' }
    ];

    return { header: defaultNavigation, footer: footerNavigation };
  }, [event, subscriptionModule, t]);

  if (!event) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <LandingPageContext.Provider
      value={{ event, eventLocation, eventId, navigation, handleCheckoutPage, subscriptionModule }}
    >
      <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
    </LandingPageContext.Provider>
  );
};
