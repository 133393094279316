import { PurchaseTicketsType } from '../interfaces';

type FormatMonetaryToLocaleType = {
  currency: string;
  style: string;
  lang: string;
  value: number;
};

export const formatMonetaryToLocale = ({ value, currency, style, lang }: FormatMonetaryToLocaleType) =>
  new Intl.NumberFormat(lang, {
    style,
    currency
  }).format(value);

/**
 * Esse algoritmo foi encontrado em um artiggo sobre
 * validação de cartões de crédito usando o algoritmo de Luhn.
 * https://cleilsontechinfo.netlify.app/jekyll/update/2019/12/08/um-guia-completo-para-validar-e-formatar-cartoes-de-credito.html#o-algoritmo-de-luhn
 */

export const validateCreditCard = (creditCardNumber: string) => {
  // remove all non digit characters
  const creditCardNumberWithoutSpaces = creditCardNumber.replace(/\D/g, '');
  let shouldDouble = false;

  const sum = creditCardNumberWithoutSpaces.split('').reduceRight((acc, digit) => {
    let creditCardDigit = Number(digit);

    if (shouldDouble) {
      const test = (creditCardDigit *= 2) > 9;

      if (test) {
        creditCardDigit -= 9;
      }
    }

    // eslint-disable-next-line no-param-reassign
    acc += creditCardDigit;
    shouldDouble = !shouldDouble;

    return acc;
  }, 0);

  return sum % 10 === 0;
};

export const brazilCurrencyFormater = (number: number) =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);

export const roundNumber = (number: number) => Math.round((number + Number.EPSILON) * 100) / 100;

export const getTicketValue = (ticket: PurchaseTicketsType) => {
  if (ticket.coupon) {
    return roundNumber(ticket.value - ticket.value * (ticket.coupon.discount / 100));
  }
  return ticket.value;
};
