import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputGroup, InputLabel, InputPhone, InputRadioGroup, SelectLabel } from '../..';
import { PecegePayDocumentType } from '../../../interfaces';
import {
  brazilianDocumentTypes,
  countries,
  courses,
  defaultCountryValue,
  defaultCourseValue,
  defaultDocumentTypeValue,
  foreignDocumentTypes,
  inputDocumentMask
} from '../../../utils';

type ParticipantFieldsProps = {
  index: number;
  showMbaCoursesField?: boolean;
  loading?: boolean;
};

const optionsPeopleSpecialNeeds = [
  { name: 'CHECKOUT.FORM.PCD_OPTIONS.YES', value: 'true' },
  { name: 'CHECKOUT.FORM.PCD_OPTIONS.NO', value: 'false' }
];

const languagesContact = [
  { name: 'Português', value: 'pt-br' },
  { name: 'English', value: 'en' },
  { name: 'Español', value: 'es' }
];

const languageContactDefaultValue = 'pt-br';

export const ParticipantFields = ({ index, showMbaCoursesField, loading }: ParticipantFieldsProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'CHECKOUT' });
  const methods = useFormContext();
  const { setValue } = methods;

  const selectedNationality = useWatch({
    name: `participants.${index}.nacionality`
  });

  const isPeopleSpecialNeeds = useWatch({
    name: `participants.${index}.isPeopleSpecialNeeds`
  });

  useEffect(() => {
    if (selectedNationality && selectedNationality !== defaultCountryValue) {
      return setValue(`participants.${index}.documentType`, PecegePayDocumentType.PASSPORT);
    }
    return setValue(`participants.${index}.documentType`, PecegePayDocumentType.CPF || PecegePayDocumentType.CNPJ);
  }, [index, setValue, selectedNationality]);

  // Garantir que será passado uma string vazia no campo peopleSpecialNeedsType
  // caso o valor do campo isPeopleSpecialNeeds seja igual a false
  useEffect(() => {
    if (!isPeopleSpecialNeeds || isPeopleSpecialNeeds === 'false') {
      setValue(`participants.${index}.peopleSpecialNeedsType`, '');
    }
  }, [index, isPeopleSpecialNeeds, setValue]);

  return (
    <div className="w-full gap-3 pb-4 sm:flex sm:flex-col">
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 w-full sm:mt-0">
          <InputLabel
            name={`participants.${index}.firstName`}
            label={t('FORM.NAME').toString()}
            placeholder={t('FORM.NAME').toString()}
            type="text"
            autoComplete="none"
            maxLength={50}
            disabled={loading}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0">
          <InputLabel
            name={`participants.${index}.lastName` as const}
            label={t('FORM.SURNAME').toString()}
            placeholder={t('FORM.SURNAME').toString()}
            type="text"
            autoComplete="none"
            maxLength={50}
            disabled={loading}
          />
        </div>
      </div>
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 w-full sm:mt-0">
          <InputLabel
            name={`participants.${index}.email` as const}
            label={t('FORM.EMAIL').toString()}
            placeholder={t('FORM.EMAIL').toString()}
            type="email"
            autoComplete="none"
            maxLength={50}
            disabled={loading}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0">
          <InputLabel
            name={`participants.${index}.confirmEmail` as const}
            label={t('FORM.CONFIRM_EMAIL').toString()}
            placeholder={t('FORM.CONFIRM_EMAIL').toString()}
            type="email"
            onPaste={e => e.preventDefault()}
            autoComplete="none"
            maxLength={50}
            disabled={loading}
          />
        </div>
      </div>
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 w-full sm:mt-0">
          <SelectLabel
            name={`participants.${index}.nacionality` as const}
            label={t('FORM.NATIONALITY')}
            defaultValue={defaultCountryValue}
            options={countries}
            autoComplete="none"
            disabled={loading}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0">
          <InputGroup
            inputType="text"
            label={t('FORM.DOCUMENT')}
            placeholder={t('FORM.TYPE_HERE').toString()}
            inputId={`participants.${index}.document` as const}
            selectId={`participants.${index}.documentType` as const}
            selectOptions={selectedNationality !== defaultCountryValue ? foreignDocumentTypes : brazilianDocumentTypes}
            selectDefaultValue={defaultDocumentTypeValue}
            onChangeValue={value => inputDocumentMask(value, methods.watch(`participants.${index}.documentType`))}
            inputAutoComplete="none"
            disabled={loading}
          />
        </div>
      </div>
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 w-full sm:mt-0">
          <InputLabel
            name={`participants.${index}.tagName` as const}
            label={t('FORM.TAG').toString()}
            placeholder={t('FORM.TAG').toString()}
            type="text"
            autoComplete="none"
            disabled={loading}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0">
          <InputPhone
            name={`participants.${index}.cellphone` as const}
            label={t('FORM.PHONE').toString()}
            disabled={loading}
            country={methods.watch(`participants.${index}.nacionality`)?.toLowerCase()}
            defaultCountry={defaultCountryValue.toLowerCase()}
            minLength={10}
            maxLength={20}
          />
        </div>
      </div>
      {showMbaCoursesField && (
        <>
          <div className="mt-4 w-full sm:mt-0">
            <SelectLabel
              name={`participants.${index}.course` as const}
              label={t('FORM.MBA_COURSE')}
              defaultValue={defaultCourseValue}
              options={courses}
              disabled={loading}
            />
          </div>
          <small className="font-medium text-red-500">{t('FORM.INFORMATION_MBA_COURSE')}</small>
        </>
      )}
      <div className="mt-3 w-full items-center justify-between gap-14 sm:flex">
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <InputRadioGroup
            label={t('FORM.PCD')}
            name={`participants.${index}.isPeopleSpecialNeeds` as const}
            options={optionsPeopleSpecialNeeds}
            disabled={loading}
          />
        </div>
        {isPeopleSpecialNeeds === 'true' && (
          <div className="mt-4 sm:mt-0 sm:grow">
            <InputLabel
              name={`participants.${index}.peopleSpecialNeedsType` as const}
              label={t('FORM.PDC_DESCRIPTION').toString()}
              placeholder={t('FORM.PCD_TYPE_HERE').toString()}
              type="text"
              autoComplete="none"
              maxLength={50}
              disabled={loading}
            />
          </div>
        )}
      </div>
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 w-full sm:mt-2">
          <SelectLabel
            name={`participants.${index}.languageContact` as const}
            label={t('FORM.LANGUAGE_CONTACT')}
            defaultValue={languageContactDefaultValue}
            options={languagesContact}
            autoComplete="none"
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};
