import { Timestamp } from 'firebase/firestore';

import { TranslateType } from './translate';

export type Activity = {
  eventId: string;
  uid: string;
  tabGroupName: string;
  title: TranslateType;
  speakers?: Array<{
    name: string;
    curriculum: TranslateType;
    photoUrl: string;
  }>;
  description: TranslateType;
  date: Date | Timestamp;
  broadcast?: {
    broadcastType: BroadcastTypeEnum;
    url: string;
    thumbnail?: string;
  };
};

enum BroadcastTypeEnum {
  LIVE = 'live',
  RECORDED = 'recorded',
  WEB_CONF = 'web-conf'
}

export enum ActivityDisplayOnEnum {
  WEB = 'web',
  APP = 'app',
  BOTH = 'both'
}

export type GroupedActivities = Omit<Activity, 'date'> & { date: string };
