import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCheckoutPage, useGetDocument, useLayout } from '../../../../hooks';
import {
  PaymentMethodTypeEnum,
  PecegePayCompleteOrderBoletoResponseType,
  PecegePayCompleteOrderCreditCardResponseType
} from '../../../../interfaces';
import { BoletoComplete, CreditCardComplete, FreeComplete } from './components';

export const OrderComplete: React.FC = () => {
  const navigate = useNavigate();

  const { event, stagingPurchase } = useCheckoutPage();
  const { data: pecegePay } = useGetDocument<
    PecegePayCompleteOrderCreditCardResponseType | PecegePayCompleteOrderBoletoResponseType
  >({
    collectionName: 'event',
    path: [event.eventId, 'pecege-pay', stagingPurchase.purchaseId]
  });
  const { setHeaderProps } = useLayout();

  const { logErrorCount = 0 } = stagingPurchase;

  useEffect(() => {
    setHeaderProps({
      title: 'Inscrição realizada com sucesso!'
    });
  }, [setHeaderProps]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate('/');
    };

    history.pushState({}, '');

    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  if (stagingPurchase.paymentMethod === PaymentMethodTypeEnum.BOLETO) {
    return (
      <BoletoComplete pecegePay={pecegePay as PecegePayCompleteOrderBoletoResponseType} logErrorCount={logErrorCount} />
    );
  } else if (stagingPurchase.paymentMethod === PaymentMethodTypeEnum.CREDIT_CARD) {
    return (
      <CreditCardComplete
        pecegePay={pecegePay as PecegePayCompleteOrderCreditCardResponseType}
        logErrorCount={logErrorCount}
      />
    );
  } else {
    return <FreeComplete logErrorCount={logErrorCount} />;
  }
};
