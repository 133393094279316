import { useContext } from 'react';
import { LayoutContext } from '../contexts';

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout deve ser usado dentro do LayoutProvider.');
  }
  return context;
};
