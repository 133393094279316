import { useFormContext } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Options = {
  name: string;
  value: string;
};

type Props = {
  label: string;
  inputId: string;
  selectId: string;
  selectOptions: Options[];
  selectDefaultValue: string;
  placeholder?: string;
  disabled?: boolean;
  inputType: string;
  inputAutoComplete?: 'off' | 'none';
  onChangeValue?: (value: React.FormEvent<HTMLInputElement>) => void;
};

export const InputGroup = ({
  label,
  inputId,
  selectId,
  selectOptions,
  selectDefaultValue,
  disabled,
  placeholder,
  inputType,
  onChangeValue,
  inputAutoComplete
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const error = errors[inputId];
  const [array, index, prop] = inputId.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorsArrayField = (errors as any)?.[array]?.[index]?.[prop];

  const inputError = useMemo(() => {
    if (error) return error;

    return errorsArrayField;
  }, [error, errorsArrayField]);

  return (
    <div>
      <label htmlFor={inputId} className="block pb-1 text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor={selectId} className="sr-only">
            {`opções ${label}`}
          </label>
          <select
            id={selectId}
            className="h-full w-28 rounded-md border-transparent bg-transparent py-0 pl-1 text-gray-500 focus:border-primary focus:ring-primary sm:text-sm"
            disabled={disabled}
            defaultValue={selectDefaultValue}
            {...register(selectId)}
          >
            {selectOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <input
          id={inputId}
          type={inputType}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={inputAutoComplete}
          className={`block h-full w-full rounded-md border-gray-300 sm:text-sm ${
            inputError
              ? 'border-red-400 focus:border-red-400 focus:ring-red-400'
              : 'focus:border-primary focus:ring-primary'
          }`}
          style={{ paddingLeft: '7rem' }}
          {...register(inputId, { onChange: onChangeValue })}
        />
      </div>
      {inputError && (
        <div className="flex items-center text-red-500">
          <div>
            <ExclamationCircleIcon className="mr-[3px] w-4" />
          </div>
          <h1>{t(String(inputError.message))}</h1>
        </div>
      )}
    </div>
  );
};
