import { Timestamp } from 'firebase/firestore';

export enum CouponStatus {
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired'
}

export type Coupon = {
  discount: number;
  name: string;
  status: Lowercase<keyof typeof CouponStatus>;
  quantity: number;
  targetTickets: string[];
  validity: {
    from: Date | Timestamp;
    to: Date | Timestamp;
  };
  needMoreThanOneTicketInCart: boolean;
};

export enum VerifyCouponActionEnum {
  VERIFY = 'verify',
  APPLY = 'apply',
  REMOVE = 'remove'
}
