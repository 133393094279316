import { createContext, Suspense, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { convertToDate, formatDateToLocale, formatMonetaryToLocale } from '../utils';
import { useFirebaseFunctions, useGetCollectionData } from '../hooks';
import { Ticket, TicketValidity, TicketWithTier } from '../interfaces/ticket';

type SelectedTickets = {
  ticketId: string;
  increment: boolean;
};

type TicketsPurchaseCreate = {
  ticketId: string;
  amount: number;
};

type PurchaseCreateRequestType = {
  eventId: string;
  tickets: TicketsPurchaseCreate[];
  inPersonPurchase?: boolean;
};

type PurchaseCreateResponseType = string;

interface ContextProps {
  tickets: TicketWithTier[];
  sumSelectedTicketsValues: number;
  ticketsCount: number;
  purchaseCreateLoading: boolean;
  setPurchaseCreateLoading: (loading: boolean) => void;
  getUpdateTicketAmount: ({ ticketId, increment }: SelectedTickets) => void;
  handleCreatePurchaseRequest: (
    callableParams: PurchaseCreateRequestType
  ) => Promise<PurchaseCreateResponseType | undefined>;
  formatCurrency: (monetaryValue: number) => string;
}

export const TicketsContext = createContext({} as ContextProps);

export const TicketsProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const { onCall } = useFirebaseFunctions();
  const [sumSelectedTicketsValues, setSumSelectedTicketsValues] = useState(0);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [purchaseCreateLoading, setPurchaseCreateLoading] = useState(false);

  const params = useParams();
  const { eventId = '' } = params;
  const { data: tickets } = useGetCollectionData<Ticket>({
    collectionName: 'event',
    path: [eventId, 'tickets'],
    idField: 'ticketId'
  });

  const getDeadline = ({ from, to }: TicketValidity) => `
    de ${formatDateToLocale({ date: from, style: 'dd/MM/yyyy', lang: 'pt' })}
    até ${formatDateToLocale({ date: to, style: 'dd/MM/yyyy', lang: 'pt' })}
  `;

  const formatCurrency = useCallback(
    (monetaryValue: number) =>
      formatMonetaryToLocale({ value: monetaryValue ?? 0, lang: 'pt-BR', style: 'currency', currency: 'BRL' }),
    []
  );

  const getCurrent = ({ from, to }: TicketValidity) => {
    const currentDate = new Date();
    return convertToDate(from) <= currentDate && convertToDate(to) >= currentDate;
  };

  const roundValue = (value: number) => Math.round(value * 100) / 100;

  const getUpdateTicketAmount = useCallback(
    async ({ ticketId, increment }: SelectedTickets) => {
      setTicketsCount(set => (increment ? set + 1 : set - 1));

      const ticketExists = tickets.find(ticket => ticket.ticketId === ticketId);
      const ticketAllotmentCurrent = ticketExists?.allotment?.find(allotment => getCurrent(allotment.validity));
      if (ticketAllotmentCurrent)
        setSumSelectedTicketsValues(sumTotal =>
          increment ? roundValue(sumTotal + ticketAllotmentCurrent.value) : sumTotal - ticketAllotmentCurrent.value
        );
    },
    [tickets]
  );

  const handleCreatePurchaseRequest = useCallback(
    (callableParams: PurchaseCreateRequestType) =>
      onCall<PurchaseCreateRequestType, PurchaseCreateResponseType>({
        functionName: 'http-cal-stagingPurchase-create',
        callableParams
      }),
    [onCall]
  );

  const memoValue = useMemo(
    () => ({
      tickets: tickets.map((ticket: Ticket) => {
        if (!ticket.allotment) {
          return { ...ticket, tiers: [] };
        }

        const formattedAllotment = ticket.allotment.map(allotmentItem => ({
          ...allotmentItem,
          deadline: getDeadline(allotmentItem.validity),
          current: getCurrent(allotmentItem.validity),
          value: allotmentItem.value,
          isOlderThanCurrent: convertToDate(allotmentItem.validity.to) < new Date()
        }));
        return { ...ticket, tiers: formattedAllotment };
      }),
      getUpdateTicketAmount,
      sumSelectedTicketsValues,
      formatCurrency,
      ticketsCount,
      handleCreatePurchaseRequest,
      purchaseCreateLoading,
      setPurchaseCreateLoading
    }),
    [
      tickets,
      getUpdateTicketAmount,
      sumSelectedTicketsValues,
      formatCurrency,
      ticketsCount,
      handleCreatePurchaseRequest,
      purchaseCreateLoading,
      setPurchaseCreateLoading
    ]
  );

  return (
    <TicketsContext.Provider value={memoValue}>
      <Suspense>{children}</Suspense>
    </TicketsContext.Provider>
  );
};
