import classNames from 'classnames';

export const Pill = ({ color, text }: { color?: string; text: string }) => {
  const statusColor: { [key: string]: string } = {
    green: 'bg-green-100 text-green-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    gray: 'bg-gray-100 text-gray-800',
    red: 'bg-red-100 text-red-800',
    blue: 'bg-blue-100 text-blue-800'
  };
  return (
    <span
      className={classNames(
        statusColor[color ?? 'gray'],
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
      )}
    >
      {text}
    </span>
  );
};
