/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props<T> {
  data: T | T[];
  propNames: { [key: string]: string };
  title?: string;
  skipProps?: string[];
}

const HorizontalDl = <T extends object>({ data, skipProps, propNames }: Props<T>) => (
  <dl className="mt-2 divide-y divide-gray-200 border-y border-gray-200">
    {Object.keys(data)
      .filter(f => (skipProps ? !skipProps.includes(f) : true))
      .map(key => (
        <div key={key} className="flex justify-between py-3 text-sm font-medium">
          <dt className="text-gray-500">{propNames[key]}</dt>
          <dd className="text-gray-900">{(data as any)[key]}</dd>
        </div>
      ))}
  </dl>
);

const VerticalDl = <T extends object>({ data, skipProps, propNames }: Props<T>) => (
  <>
    {Object.keys(data)
      .filter(f => (skipProps ? !skipProps.includes(f) : true))
      .map(key => (
        <div key={key} className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">{propNames[key]}</dt>
          <dd className="mt-1 text-sm text-gray-900">{(data as any)[key]}</dd>
        </div>
      ))}
  </>
);

export const HorizontalDefinitionList = <T extends object>({ data, propNames, title, skipProps }: Props<T>) => (
  <>
    {title ? <h3 className="mt-4 font-medium text-gray-900">{title}</h3> : null}
    {Array.isArray(data) ? (
      data.map((item, idx) => (
        <HorizontalDl key={`dl-${idx}`} data={item} skipProps={skipProps} propNames={propNames} />
      ))
    ) : (
      <HorizontalDl data={data} skipProps={skipProps} propNames={propNames} />
    )}
  </>
);

export const VerticalDefinitionList = <T extends object>({ data, propNames, skipProps }: Props<T>) => (
  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
    {Array.isArray(data) ? (
      data.map((item, idx) => <VerticalDl key={`dl-${idx}`} data={item} skipProps={skipProps} propNames={propNames} />)
    ) : (
      <VerticalDl data={data} skipProps={skipProps} propNames={propNames} />
    )}
  </dl>
);
