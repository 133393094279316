/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans } from 'react-i18next';

export const GoogleRecaptchaTerms = () => (
  <Trans
    i18nKey="APP.RECAPTCHA.MESSAGE"
    components={{
      privacy: (
        <a
          className="underline hover:brightness-50"
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        />
      ),
      terms: (
        <a
          className="underline hover:brightness-50"
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        />
      )
    }}
  />
);
