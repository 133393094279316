/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans } from 'react-i18next';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Banner } from './banner';

export const EnvBanner = () => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  const isDevEnv = projectId?.includes('-dev');
  const hostUrl = process.env.REACT_APP_HOST_URL ?? '';

  return (
    <>
      {isDevEnv && (
        <Banner>
          <ExclamationTriangleIcon className="hidden w-6 text-gray-700 md:block" />
          <p className="ml-3 text-center font-medium text-gray-700">
            <Trans
              i18nKey="APP.BANNER.ALERT_TEXT"
              components={{
                a: <a className="underline hover:brightness-150" href={hostUrl.replace('dev.', '')} />
              }}
            />
          </p>
        </Banner>
      )}
    </>
  );
};
