import { Dispatch, SetStateAction } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type ToggleProps = {
  setEnabled: Dispatch<SetStateAction<boolean>>;
  enabled: boolean;
  label?: string;
};

export const Toggle = ({ setEnabled, enabled, label }: ToggleProps) => (
  <Switch.Group as="div" className="flex items-center">
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? 'bg-blue-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
    <Switch.Label as="span" className="ml-3">
      {label ? <span className="text-sm text-gray-900">{label}</span> : null}
    </Switch.Label>
  </Switch.Group>
);
