import { ref, getDownloadURL, getStorage } from 'firebase/storage';
import { useEffect, useMemo, useRef, useState } from 'react';

export const useStorageUrl = (filesPaths: (string | undefined)[]) => {
  const [filesUrls, setFilesUrls] = useState<string[]>([]);
  const { current: files } = useRef(filesPaths);

  const pathReferences = useMemo(() => files.map(path => ref(getStorage(), path)), [files]);

  useEffect(() => {
    if (files.some(item => item === undefined)) {
      return;
    }

    const promises: Promise<string>[] = [];

    pathReferences.forEach(path => {
      promises.push(getDownloadURL(path));
    });

    Promise.all(promises).then(data => {
      setFilesUrls(data);
    });
  }, [files, pathReferences]);

  return filesUrls;
};
