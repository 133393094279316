import { useContext } from 'react';

import { AuthenticationContext } from '../contexts';

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthentication deve ser usado dentro do AuthenticationProvider.');
  }
  return context;
};
