/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import {
  ButtonWithIcon,
  SpinnerLoader,
  PurchaseParticipantValues,
  ParticipantsInfoFormSchema,
  ParticipantInfo,
  TermsAndConditions
} from '../../../../components';
import { useCheckoutPage, useLayout } from '../../../../hooks';
import { handleRemoveMaskDocument, toPurchasePhoneNumber } from '../../../../utils';

export const Participants: React.FC = () => {
  const {
    stagingPurchase,
    handleContinueToCustomer,
    handleContinueToPaymentMethods,
    handlePurchaseParticipantsRequest,
    participantTicketList,
    purchaseTotalValue,
    companyInviteField
  } = useCheckoutPage();
  const { t } = useTranslation('translation', { keyPrefix: 'CHECKOUT' });
  const { setHeaderProps } = useLayout();

  useEffect(() => {
    setHeaderProps({
      title: 'Checkout - Participantes'
    });
  }, [setHeaderProps]);

  const participantDefaultInfo = participantTicketList.map(({ ticketId, ticketTitle, ticketTags }) => ({
    ticketId,
    ticketTitle,
    ticketTags
  }));

  const methods = useForm<PurchaseParticipantValues>({
    resolver: yupResolver(ParticipantsInfoFormSchema),
    defaultValues: {
      participants: participantDefaultInfo
    }
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = methods;

  const { fields } = useFieldArray({
    name: 'participants',
    control
  });

  const handleParticipants: SubmitHandler<PurchaseParticipantValues> = async ({
    participants,
    availableForMailing
  }: PurchaseParticipantValues) => {
    const request = {
      eventId: stagingPurchase.eventId,
      purchaseId: stagingPurchase.purchaseId,
      participants: participants.map(participant => ({
        ...participant,
        // para quando o form não tenha curso, forçar a passagem ao backend como string ao inves de undefined
        // como essa prop é paliativa, logo removeremos ela daqui
        course: participant.course ?? '',
        document: handleRemoveMaskDocument(participant.documentType, participant.document),
        cellphone: toPurchasePhoneNumber(participant.cellphone as string),
        availableForMailing,
        company: participant.company ?? ''
      }))
    };
    const response = await handlePurchaseParticipantsRequest(request);
    if (response) purchaseTotalValue === 0 ? handleContinueToPaymentMethods() : handleContinueToCustomer();
  };

  return (
    <div className="flex w-full flex-col gap-[2px]">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleParticipants)} autoComplete="none">
          <div className="divide-y divide-dashed divide-gray-300">
            {fields.map((field, index) => (
              <ParticipantInfo
                key={field.id}
                order={index}
                participantsRequestLoading={isSubmitting}
                participantTicketList={participantTicketList}
                stagingPurchase={stagingPurchase}
                companyInviteField={companyInviteField}
              />
            ))}
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-8">
            <div className="space-y-4 pb-8">
              <TermsAndConditions />
            </div>
            <ButtonWithIcon
              type="submit"
              className="w-full bg-primary"
              icon={isSubmitting ? SpinnerLoader : ArrowRightIcon}
              disabled={isSubmitting}
            >
              {isSubmitting ? t('BUTTONS.LOADING') : t('BUTTONS.CONTINUE')}
            </ButtonWithIcon>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
