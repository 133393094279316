import { useLocation } from 'react-router-dom';

type SearchTerms = string | (string | null)[] | null;

type QueryReturn<T> = T extends string ? string | null : T extends string[] ? (string | null)[] : never;

export const useQuery = <T = SearchTerms>(searchTerms: T): QueryReturn<T> => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  if (Array.isArray(searchTerms)) {
    return searchTerms.map(term => query.get(term)) as QueryReturn<T>;
  }

  return query.get(String(searchTerms)) as QueryReturn<T>;
};
