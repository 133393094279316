import { browserLocalPersistence, connectAuthEmulator, initializeAuth } from 'firebase/auth';
import { AuthProvider, useInitAuth } from 'reactfire';

export const ReactFireAuthenticationProvider: React.FC<React.PropsWithChildren> = ({
  children
}: React.PropsWithChildren) => {
  const useEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

  const { data: authInstance } = useInitAuth(async firebaseApp => {
    const auth = initializeAuth(firebaseApp, { persistence: browserLocalPersistence });
    if (useEmulator) {
      connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    }
    return auth;
  });
  return <AuthProvider sdk={authInstance}>{children}</AuthProvider>;
};
