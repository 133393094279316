import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

import { LandingPageCard } from '../../../../components';
import { useCheckoutPage } from '../../../../hooks';

export const ParticipantSummary: React.FC = () => {
  const { t } = useTranslation();
  const { stagingPurchase, handleGoBackToParticipants } = useCheckoutPage();

  return (
    <LandingPageCard title={t('CHECKOUT.STEPS.PARTICIPANTS_SUMMARY.TITLE')}>
      <div className="divide-y divide-dashed divide-gray-300">
        {stagingPurchase &&
          stagingPurchase.participants?.map((participant, index) => (
            <div key={participant.document} className="flex justify-between py-3">
              <div>
                <h1 className="text-xl">{`${participant.firstName} ${participant.lastName}`}</h1>
                <p className="text-gray-500">{participant.email}</p>
                <p className="text-gray-500">{`nº ${index + 1} - ${participant.ticketTitle}`}</p>
              </div>

              <button
                onClick={handleGoBackToParticipants}
                type="button"
                className="flex h-8 items-center justify-center rounded-lg px-2 text-primary transition duration-500 hover:bg-blue-50"
              >
                <span className="mr-2">{t('CHECKOUT.BUTTONS.EDIT')}</span>
                <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          ))}
      </div>
    </LandingPageCard>
  );
};
