import React, { createContext, useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useFirebaseFunctions, useNotifications } from '../hooks';
import { PhoneNumberType } from '../interfaces';

type Address = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  erro?: string;
};

type CustomerRequestType = {
  eventId: string;
  purchaseId: string;
  name: string;
  email: string;
  document: string;
  documentType: string;
  billingAddress: {
    nacionality: string;
    zipCode: string;
    placeNumber: string;
    street: string;
    neighborhood: string;
    complement: string;
    city: string;
    state: string;
  };
  phoneNumber: PhoneNumberType;
};

type CustomerResponseType = boolean;

interface ContextProps {
  addressViaCep: Address | undefined;
  getConsultViaCep: (cep: string) => void;
  handleCustomerRequest: (callableParams: CustomerRequestType) => Promise<CustomerResponseType | undefined>;
}

export const CustomerContext = createContext({} as ContextProps);

export const CustomerProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const { onCall } = useFirebaseFunctions();
  const { alertToast, errorToast } = useNotifications();

  const [addressViaCep, setAddressViaCep] = useState<Address>();

  const getAxiosUrlViaCep = async (cep: string) => {
    const viaCepData = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return viaCepData.data as Address;
  };

  const getConsultViaCep = useCallback(
    async (cep: string) => {
      const cepFormatted = cep.replace(/[^0-9]/g, '');
      if ((!cepFormatted && cepFormatted.length === 0) || cepFormatted.length !== 8) {
        errorToast(t('CHECKOUT.STEPS.BUYER_DATA.MESSAGES.INVALID_ZIP_CODE'));
        return setAddressViaCep(undefined);
      }
      const dataViaCep = await getAxiosUrlViaCep(cepFormatted);
      if (dataViaCep.erro === 'true') {
        alertToast(t('CHECKOUT.STEPS.BUYER_DATA.MESSAGES.ZIP_CODE_NOT_FOUND'));
        return setAddressViaCep(dataViaCep);
      }
      return setAddressViaCep(dataViaCep);
    },
    [alertToast, errorToast, t]
  );

  const handleCustomerRequest = useCallback(
    (callableParams: CustomerRequestType) =>
      onCall<CustomerRequestType, CustomerResponseType>({
        functionName: 'http-cal-stagingPurchase-customer',
        callableParams,
        successMessage: 'CHECKOUT.STEPS.BUYER_DATA.MESSAGES.REGISTERED_SUCCESSFULY'
      }),
    [onCall]
  );

  const memoValue = useMemo(
    () => ({
      getConsultViaCep,
      addressViaCep,
      handleCustomerRequest
    }),
    [getConsultViaCep, addressViaCep, handleCustomerRequest]
  );

  return <CustomerContext.Provider value={memoValue}>{children}</CustomerContext.Provider>;
};
