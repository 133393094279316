import { isEmpty } from '.';

/* eslint-disable @typescript-eslint/no-explicit-any */
const getInstance = () => (window as any).Tawk_API || {};

export const tawktoMaximize = (): boolean => {
  const Tawk = getInstance();
  if (isEmpty(Tawk)) {
    return false;
  }

  try {
    Tawk.maximize();
  } catch {
    // devido aos efeitos de render nem sempre temos o tawk instanciado no navegador
    // por isso, mantemos esse excessão silenciosa, pra evitar erro de undefined
  }

  return true;
};
