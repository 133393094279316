import { CalendarIcon, ClockIcon, UserIcon } from '@heroicons/react/24/outline';

import { Activity } from '../../../../interfaces';
import { formatDateToLocale } from '../../../../utils';
import { getCurrentLanguage } from '../../../../i18n';
import { useStorageUrl } from '../../../../hooks';
import broadcastDefaultImage from '../../../../assets/images/broadcast-default.jpg';

export const BroadcastScheduleItem = (activity: Activity) => {
  const speakers = activity.speakers?.map(speaker => speaker.name).join(',');
  const language = getCurrentLanguage();
  const [thumbnail] = useStorageUrl([activity.broadcast?.thumbnail]);

  return (
    <>
      <img
        className="h-[94px] w-full max-w-[128px] rounded-sm object-cover"
        src={thumbnail ?? broadcastDefaultImage}
        alt=""
      />
      <section className="ml-2 space-y-1 self-start overflow-hidden">
        <p className="max-w-xs font-semibold line-clamp-2">{activity.title[language] ?? activity.title.pt}</p>

        {!!activity.speakers && (
          <div className="flex items-center text-gray-500">
            <UserIcon className="mr-1 h-5 w-5" />
            <p className="w-full truncate text-sm">{speakers}</p>
          </div>
        )}

        <footer className="flex space-x-2 text-sm text-gray-500">
          <div className="flex items-center">
            <CalendarIcon className="mr-1 h-4 w-4" />
            <p>
              {formatDateToLocale({
                date: activity.date,
                lang: 'pt',
                style: 'dd/MM'
              })}
            </p>
          </div>
          <div className="flex items-center">
            <ClockIcon className="mr-1 h-4 w-4" />
            <p>
              {formatDateToLocale({
                date: activity.date,
                lang: 'pt',
                style: 'HH:mm'
              })}
            </p>
          </div>
        </footer>
      </section>
    </>
  );
};
