import { ButtonHTMLAttributes, ComponentProps, ComponentType } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: ComponentType<ComponentProps<'svg'>>;
  customStylesButton?: string;
  customStylesIcon?: string;
  changePositionIconLeft?: boolean;
  changePositionIconTop?: boolean;
  changePositionIconBottom?: boolean;
};

export const ButtonWithIcon = ({
  icon: Icon,
  type,
  children,
  customStylesButton,
  customStylesIcon,
  changePositionIconLeft,
  changePositionIconTop,
  changePositionIconBottom,
  ...rest
}: Props) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    {...rest}
    className={`
      inline-flex w-full items-center justify-center rounded-md px-4 py-2
      text-base font-medium focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2
      ${customStylesButton ?? 'bg-primary text-white shadow-sm hover:bg-primary'}
      ${changePositionIconLeft && 'flex-row-reverse'}
      ${changePositionIconTop && 'flex-col-reverse'}
      ${changePositionIconBottom && 'flex-col'}
      ${rest.className}
      `}
  >
    {children}
    {Icon && (
      <Icon className={customStylesIcon ?? `h-4 w-4 ${changePositionIconLeft ? 'mr-2' : 'ml-2'}`} aria-hidden="true" />
    )}
  </button>
);
