import { FirebaseAppProvider } from 'reactfire';

import config from './config';
import {
  ReactFireAnalyticsProvider,
  ReactFireAppCheckProvider,
  ReactFireAuthenticationProvider,
  ReactFireFirestoreProvider,
  ReactFireFunctionsProvider,
  ReactStorageProvider
} from '.';

const suspense = process.env.REACT_APP_USE_CONCURRENT_MODE === 'true';

export const ReactFireFirebaseProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => (
  <FirebaseAppProvider firebaseConfig={config} suspense={suspense}>
    <ReactFireAppCheckProvider>
      <ReactFireAuthenticationProvider>
        <ReactFireFunctionsProvider>
          <ReactStorageProvider>
            <ReactFireFirestoreProvider>
              <ReactFireAnalyticsProvider>{children}</ReactFireAnalyticsProvider>
            </ReactFireFirestoreProvider>
          </ReactStorageProvider>
        </ReactFireFunctionsProvider>
      </ReactFireAuthenticationProvider>
    </ReactFireAppCheckProvider>
  </FirebaseAppProvider>
);
