import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { FunctionsProvider, useInitFunctions } from 'reactfire';

export const ReactFireFunctionsProvider: React.FC<React.PropsWithChildren> = ({
  children
}: React.PropsWithChildren) => {
  const useEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';
  const { data: functionsInstance } = useInitFunctions(async firebaseApp => {
    const functions = getFunctions(firebaseApp);
    if (useEmulator) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }
    return functions;
  });
  return <FunctionsProvider sdk={functionsInstance}>{children}</FunctionsProvider>;
};
