import { Fragment, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link as ScrollLink } from 'react-scroll';

import { LanguageSelector } from '../../components';
import LogoLinka from '../../assets/svg/logo-linka.svg';
import { useLayout } from '../../hooks';
import { useSigninCheck } from 'reactfire';
import { ClaimsEnum } from '../../utils';

export const PublicHeader = ({ title }: { title?: string }) => {
  const { eventId = '' } = useParams();
  const { data: signInCheckResult } = useSigninCheck({
    validateCustomClaims: userClaims => {
      const hasRequiredClaims = [ClaimsEnum.ADMIN, ClaimsEnum.COLLAB].some(c => userClaims[c] === true);
      return { hasRequiredClaims, errors: {} };
    }
  });
  const { headerProps } = useLayout();

  const showAuthenticationInfo = useMemo(() => {
    if (!headerProps.showAuthentication) {
      return null;
    }
    return signInCheckResult.signedIn && signInCheckResult.hasRequiredClaims ? (
      <Link
        to={`/${eventId}/dashboard`}
        className="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
      >
        Dashboard
      </Link>
    ) : null;
  }, [eventId, headerProps.showAuthentication, signInCheckResult]);

  return (
    <header>
      <Popover className="relative">
        <div className="flex items-center justify-between py-6 px-4 md:justify-start md:space-x-10 md:px-0">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <img className="mx-2 h-8 w-auto sm:mx-0 sm:h-10" src={LogoLinka} alt="Linka" />
            </Link>
          </div>
          {title && (
            <div className="hidden justify-center font-semibold text-gray-700 antialiased lg:block">{title}</div>
          )}
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          {headerProps.navigation?.header ? (
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              {headerProps.navigation.header.map(item => (
                <ScrollLink
                  key={item.name}
                  to={item.id}
                  smooth
                  className="cursor-pointer text-base font-medium text-gray-500 line-clamp-1 hover:text-gray-900"
                >
                  {item.name}
                </ScrollLink>
              ))}
            </Popover.Group>
          ) : null}

          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <LanguageSelector />
            {showAuthenticationInfo}
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 z-30 origin-top-right p-2 transition md:hidden">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black/5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={LogoLinka} alt="Linka" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  {headerProps.navigation?.header
                    ? headerProps.navigation.header.map(item => (
                        <ScrollLink
                          key={item.name}
                          to={item.id}
                          smooth
                          className="cursor-pointer text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          <Popover.Button>{item.name}</Popover.Button>
                        </ScrollLink>
                      ))
                    : null}
                </div>

                <div className="mt-6">
                  <div className="mb-4">
                    <LanguageSelector />
                  </div>
                  {/* {showAuthenticationInfoMobile} */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};
