import { ChevronRightIcon, XCircleIcon, TicketIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LandingPageCard } from '../../../../components';
import { tawktoMaximize } from '../../../../utils';

const reasons = [
  {
    title: 'CHECKOUT.ERROR_BOUNDARY.REASONS.ITEM_1.TITLE',
    description: 'CHECKOUT.ERROR_BOUNDARY.REASONS.ITEM_1.DESCRIPTION',
    icon: TicketIcon,
    clicable: false
  },
  {
    title: 'CHECKOUT.ERROR_BOUNDARY.REASONS.ITEM_2.TITLE',
    description: 'CHECKOUT.ERROR_BOUNDARY.REASONS.ITEM_2.DESCRIPTION',
    icon: XCircleIcon,
    clicable: true
  }
];

export const ErrorFallbackCheckout: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openTawkto = () => tawktoMaximize();
  const returnToHome = () => navigate('/');

  return (
    <LandingPageCard title="">
      <main className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-xl">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {t('CHECKOUT.ERROR_BOUNDARY.TITLE')}
            </h1>
            <p className="mt-2 text-lg text-gray-500">{t('CHECKOUT.ERROR_BOUNDARY.SUB_TITLE')}</p>
          </div>
          <div className="mt-12">
            <h2 className="text-base font-semibold text-gray-500">{t('CHECKOUT.ERROR_BOUNDARY.REASONS.TITLE')}</h2>
            <ul className="mt-4 divide-y divide-gray-200 border-y border-gray-200">
              {reasons.map(reason => (
                <li key={reason.title} className="relative flex items-start space-x-4 py-6">
                  <div className="shrink-0">
                    <span className="flex h-12 w-12 items-center justify-center rounded-lg bg-blue-50">
                      <reason.icon className="h-6 w-6 text-primary" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2">
                        {reason.clicable ? (
                          <button type="button" onClick={openTawkto} className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            {t(reason.title)}
                          </button>
                        ) : (
                          t(reason.title)
                        )}
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">{t(reason.description)}</p>
                  </div>
                  {reason.clicable && (
                    <div className="shrink-0 self-center">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <button
                type="button"
                onClick={returnToHome}
                className="text-base font-medium text-primary hover:brightness-75"
              >
                {t('CHECKOUT.ERROR_BOUNDARY.BUTTON')}
              </button>
            </div>
          </div>
        </div>
      </main>
    </LandingPageCard>
  );
};
