import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { ptBR, enUS, es } from 'date-fns/locale';

type FormatDateToLocaleType = {
  date?: Date | Timestamp;
  style: string;
  lang: string;
};

export const convertToDate = (date: Date | Timestamp): Date => {
  if (!(date instanceof Date)) {
    try {
      return date.toDate();
    } catch {
      return new Date(date.seconds * 1000);
    }
  }
  return date;
};

const locales: { [key: string]: Locale } = { pt: ptBR, en: enUS, es: es };

export const formatDateToLocale = ({ date, lang, style }: FormatDateToLocaleType): string => {
  const locale = locales[lang];
  if (!date) return '';
  return format(convertToDate(date), style, { locale });
};

export const formatDateToLocaleTime = ({ date, lang }: FormatDateToLocaleType): string => {
  const locale = locales[lang];
  if (!date) return '';
  return format(convertToDate(date), 'HH:mm', { locale });
};
