import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { CheckIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

import {
  HorizontalTabWithUnderline,
  ButtonWithIcon,
  SpinnerLoader,
  LandingPageCard,
  Alerts
} from '../../../../components';
import { ParticipantSummary, ReceiveReceipts, PurchaseSummary } from '../';
import BoletoInfo from './boleto-info';
import CreditCardInfo from './credit-card';
import { useCheckoutPage, useLayout, usePaymentMethods, useRecaptcha } from '../../../../hooks';
import { CreditCardAndReceiptsFormSchema } from './credit-card/form-validation';
import {
  PaymentMethodTypeEnum,
  PecegePayOrderCreditCardRequestType,
  PecegePayDocumentType
} from '../../../../interfaces';
import { PurchaseCompleteRequestType } from '../../../../contexts';
import { getTranslateText, keepOnlyNumbers } from '../../../../utils';
import { getCurrentLanguage } from '../../../../i18n';

// TODO: criar uma estrutura que faça isso dinamicamente para quando precisarmos desabilitar uma forma de pagamento em produção
const tabGroup = [
  {
    key: PaymentMethodTypeEnum.BOLETO,
    tabName: 'CHECKOUT.STEPS.PAYMENT_METHOD.BOLETO.TITLE',
    component: <BoletoInfo />,
    disabled: false
  },
  {
    key: PaymentMethodTypeEnum.CREDIT_CARD,
    tabName: 'CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.TITLE',
    component: <CreditCardInfo />,
    disabled: false
  }
];

type FormType = PecegePayOrderCreditCardRequestType & {
  installments: number | null;
  sendPurchaseInfoTo: string | null;
  confirmSendPurchaseInfoTo: string | null;
};

export const PaymentMethods: React.FC = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const navigate = useNavigate();
  const { handlePurchaseCompleteRequest, setSelectedTabIndex, selectedTabIndex, paymentWarnings } = usePaymentMethods();
  const { stagingPurchase, handleCompletePayment, purchaseTotalValue } = useCheckoutPage();
  const { reCaptchaVerify } = useRecaptcha();
  const { setHeaderProps } = useLayout();

  const [purchaseLoading, setPurchaseLoading] = useState(false);

  useEffect(() => {
    setHeaderProps({
      title: 'Checkout - Forma de pagamento'
    });
  }, [setHeaderProps]);

  const filteredPaymentMethods = useMemo(
    () =>
      tabGroup.map(tab => {
        if (
          stagingPurchase.customer?.documentType === PecegePayDocumentType.PASSPORT &&
          tab.key === PaymentMethodTypeEnum.BOLETO
        ) {
          return { ...tab, disabled: true };
        }
        return tab;
      }),

    [stagingPurchase.customer]
  );

  const tabList = filteredPaymentMethods.map(({ tabName, disabled }) => ({ name: t(tabName), disabled }));
  const tabPanels = filteredPaymentMethods.map(({ component, key }) => ({
    index: `tab_${key}`,
    panel: component
  }));

  const paymentMethod = useMemo(() => {
    if (purchaseTotalValue === 0) {
      return PaymentMethodTypeEnum.FREE;
    }
    return filteredPaymentMethods[selectedTabIndex].key;
  }, [filteredPaymentMethods, purchaseTotalValue, selectedTabIndex]);

  const isCreditCardFormDisabled = useMemo(
    () => paymentMethod !== PaymentMethodTypeEnum.CREDIT_CARD || purchaseTotalValue === 0,
    [paymentMethod, purchaseTotalValue]
  );
  const defaultSendPurchaseInfoTo = useMemo(() => {
    if (stagingPurchase.customer && stagingPurchase.customer.email) {
      return stagingPurchase.customer.email;
    }
    const firstParticipantEmail = stagingPurchase.participantEmails ? stagingPurchase.participantEmails[0] : '';
    return firstParticipantEmail;
  }, [stagingPurchase.customer, stagingPurchase.participantEmails]);

  const methods = useForm<FormType>({
    resolver: yupResolver(CreditCardAndReceiptsFormSchema),
    context: {
      isCreditCardFormDisabled
    },
    defaultValues: {
      sendPurchaseInfoTo: defaultSendPurchaseInfoTo
    }
  });

  const { handleSubmit } = methods;

  const handleCompletePurchase: SubmitHandler<FormType> = async values => {
    setPurchaseLoading(true);

    const token = await reCaptchaVerify('stagingPurchaseComplete');

    const { installments, number, cvv, expMonth, expYear, holderName, sendPurchaseInfoTo } = values;
    const paymentMethodRequest: PurchaseCompleteRequestType = {
      eventId: stagingPurchase.eventId,
      purchaseId: stagingPurchase.purchaseId,
      paymentMethod,
      sendPurchaseInfoTo,
      creditCard: null,
      recaptchaToken: token
    };

    if (paymentMethod === PaymentMethodTypeEnum.CREDIT_CARD) {
      paymentMethodRequest.creditCard = {
        number: keepOnlyNumbers(number),
        cvv,
        expMonth,
        expYear,
        holderName,
        installments
      };
    }
    const response = await handlePurchaseCompleteRequest(paymentMethodRequest);

    if (response === true) handleCompletePayment();

    setPurchaseLoading(false);
  };

  return (
    <>
      <PurchaseSummary />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleCompletePurchase)} className="w-full gap-3 pb-4 sm:flex sm:flex-col">
          {purchaseTotalValue > 0 && (
            <>
              <LandingPageCard title={t('CHECKOUT.STEPS.PAYMENT_METHOD.SELECT_PAYMENT_METHOD')}>
                {paymentWarnings && <Alerts type="error" message={getTranslateText(paymentWarnings, lang)} outline />}

                <HorizontalTabWithUnderline
                  tabList={tabList}
                  tabPanels={tabPanels}
                  setSelectedTabIndex={setSelectedTabIndex}
                  selectedIndex={selectedTabIndex}
                />
                <div className="mt-8 flex items-center justify-center  gap-1 text-center text-green-600">
                  <LockClosedIcon className="w-4" />
                  <span>{t('CHECKOUT.STEPS.PAYMENT_METHOD.SAFE_INFO')}</span>
                </div>
              </LandingPageCard>
              <ReceiveReceipts />
            </>
          )}
          <ParticipantSummary />

          <div className="mt-4 flex w-full rounded-md bg-white py-8 sm:gap-8 sm:px-8">
            <div>
              <ButtonWithIcon
                disabled={purchaseLoading}
                type="button"
                customStylesButton="text-primary hover:text-primary mt-[-4px]"
                icon={ArrowLeftIcon}
                changePositionIconLeft
                onClick={() => navigate(-1)}
              >
                {t('CHECKOUT.BUTTONS.BACK')}
              </ButtonWithIcon>
            </div>

            <div className="mr-6 grow sm:mr-0">
              <ButtonWithIcon
                disabled={purchaseLoading}
                type="submit"
                customStylesButton={purchaseLoading ? 'bg-green-500 text-white' : 'bg-green-600 text-white'}
                icon={purchaseLoading ? SpinnerLoader : CheckIcon}
                customStylesIcon="mr-2 h-6 w-6"
                changePositionIconLeft
              >
                {purchaseLoading ? t('CHECKOUT.BUTTONS.LOADING') : t('CHECKOUT.BUTTONS.COMPLETE_REGISTRATION')}
              </ButtonWithIcon>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
