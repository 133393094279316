import { Timestamp } from 'firebase/firestore';
import { useContext, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { Alerts, Card } from '../../../../../components';
import { useGetCollectionGroupData } from '../../../../../hooks';
import {
  ActionLogType,
  ActionLogTypeEnum,
  Participant,
  PurchaseRefundActionLog,
  PurchaseTransferActionLog
} from '../../../../../interfaces';
import { formatDateToLocale, formatMonetaryToLocale } from '../../../../../utils';
import { ParticipantsContext } from '../../../participants-context';

const TranfersActionLog = ({
  transfer,
  transferDate,
  participantEmail
}: {
  participantEmail: string;
  transfer: PurchaseTransferActionLog;
  transferDate: Timestamp;
}) => {
  const participantReceivedTransfer = transfer.to && transfer.to.email === participantEmail;
  const i18nKey = participantReceivedTransfer
    ? 'DASHBOARD_PAGE.PARTICIPANTS_PAGE.GRID.DETAILS.ACTION_LOG.TRANSFER_FROM'
    : 'DASHBOARD_PAGE.PARTICIPANTS_PAGE.GRID.DETAILS.ACTION_LOG.TRANSFER_TO';
  const participantName = participantReceivedTransfer ? transfer.to?.name : transfer.from?.name;
  const transferParticipantName = participantReceivedTransfer ? transfer.from?.name : transfer.to?.name;
  const transferParticipantEmail = participantReceivedTransfer ? transfer.from?.email : transfer.to?.email;
  return (
    <>
      <Trans
        i18nKey={i18nKey}
        components={{ strong: <strong />, p: <p /> }}
        values={{
          participantName,
          transferParticipantName,
          transferParticipantEmail,
          transferDate: formatDateToLocale({
            date: transferDate,
            lang: 'pt',
            style: "dd/MM 'às' HH:mm"
          })
        }}
      />
    </>
  );
};

const RefundActionLog = ({
  purchaseRefund,
  refundDate
}: {
  purchaseRefund: PurchaseRefundActionLog;
  refundDate: Timestamp;
}) => {
  const i18nKey = 'DASHBOARD_PAGE.PARTICIPANTS_PAGE.GRID.DETAILS.ACTION_LOG.REFUND';
  return (
    <>
      <Trans
        i18nKey={i18nKey}
        components={{ strong: <strong />, p: <p /> }}
        values={{
          purchaseId: purchaseRefund.purchaseId,
          ticketName: purchaseRefund.ticketTitle,
          participantName: purchaseRefund.firstName,
          participantEmail: purchaseRefund.email,
          refundReason: purchaseRefund.refund?.refundReason,
          refundAmount: formatMonetaryToLocale({
            value: purchaseRefund.refund?.refundAmount ?? 0,
            lang: 'pt-BR',
            style: 'currency',
            currency: 'BRL'
          }),
          refundDate: formatDateToLocale({
            date: refundDate,
            lang: 'pt',
            style: "dd/MM 'às' HH:mm"
          })
        }}
      />
    </>
  );
};

export const PurchaseActionLog = ({ participant }: { participant: Participant }) => {
  const { setDisabledRefund, setTransferIn, setTransferOut } = useContext(ParticipantsContext);
  const { data } = useGetCollectionGroupData<ActionLogType<PurchaseTransferActionLog | PurchaseRefundActionLog>>({
    collectionName: 'action-log',
    constraints: [
      { fieldPath: 'eventId', opStr: '==', value: participant.eventId },
      { fieldPath: 'collection', opStr: '==', value: 'purchases' },
      { fieldPath: 'data.purchaseId', opStr: '==', value: participant.purchaseId }
    ],
    orderBy: { field: 'createdAt', direction: 'desc' }
  });

  const transferOut = useMemo(() => {
    const transfers = data
      .filter(m => m.actionType === ActionLogTypeEnum.TRANSFER)
      .map(m => m.data as PurchaseTransferActionLog);

    return transfers.some(s => s.from && s.from.email.toLowerCase() === participant.email.toLowerCase() && s.to);
  }, [data, participant.email]);

  const transferIn = useMemo(() => {
    const transfers = data
      .filter(m => m.actionType === ActionLogTypeEnum.TRANSFER)
      .map(m => m.data as PurchaseTransferActionLog);

    return transfers.some(s => s.to && s.to.email.toLowerCase() === participant.email.toLowerCase() && s.from);
  }, [data, participant.email]);

  const disabledRefund = useMemo(() => {
    const refunds = data
      .filter(m => m.actionType === ActionLogTypeEnum.REFUND)
      .map(m => m.data as PurchaseRefundActionLog);

    return refunds.some(s => s.email.toLowerCase() === participant.email.toLowerCase());
  }, [data, participant.email]);

  useEffect(() => {
    setTransferOut(transferOut);
    setTransferIn(transferIn);
    setDisabledRefund(disabledRefund);
  }, [disabledRefund, setDisabledRefund, setTransferIn, setTransferOut, transferIn, transferOut]);

  //TODO: Add translations
  const actionLogs = data.map((log, index) => {
    const key = `${index}-${Date.now().toString()}`;
    return {
      key,
      title: log.actionType === ActionLogTypeEnum.REFUND ? 'Estorno de ingresso' : 'Transferência de ingresso',
      component:
        log.actionType === ActionLogTypeEnum.TRANSFER ? (
          <TranfersActionLog participantEmail={participant.email} transfer={log.data} transferDate={log.createdAt} />
        ) : (
          <RefundActionLog purchaseRefund={log.data as PurchaseRefundActionLog} refundDate={log.createdAt} />
        )
    };
  });

  const actions = (
    <ul className="-my-5 divide-y divide-gray-200">
      {actionLogs.map(log => (
        <li key={log.key} className="py-5">
          <h3 className="font-semibold text-gray-800 underline">{log.title}</h3>
          <div className="mt-1 text-sm text-gray-600">{log.component}</div>
        </li>
      ))}
    </ul>
  );

  return (
    <Card title="Registro de ações">
      {actionLogs.length === 0 ? <Alerts message="Nenhum registro de ações até o momento" type="none" /> : actions}
    </Card>
  );
};
