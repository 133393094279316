import React, { createContext, Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCheckoutPage, useGetDocument, useFirebaseFunctions } from '../hooks';
import {
  AdditionalInfoSettings,
  PaymentMethodsTabOrder,
  PecegePayOrderCreditCardRequestType,
  TranslateType
} from '../interfaces';

export type PurchaseCompleteRequestType = {
  eventId: string;
  purchaseId: string;
  paymentMethod: string;
  sendPurchaseInfoTo: string | null;
  creditCard: PecegePayOrderCreditCardRequestType | null;
  recaptchaToken?: string;
};
type PurchaseCompleteResponseType = boolean;

interface ContextProps {
  handlePurchaseCompleteRequest: (
    callableParams: PurchaseCompleteRequestType
  ) => Promise<PurchaseCompleteResponseType | undefined>;
  daysToExpireText: string;
  securityInfo: { bank: string; companyBillingDocument: string; payee: string; supportEmail: string };
  creditCardInstallments: { installments: number | null };
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  paymentWarnings?: TranslateType;
}

export const PaymentMethodsContext = createContext({} as ContextProps);

export const PaymentMethodsProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(PaymentMethodsTabOrder.CREDIT_CARD_LIMIT); // define cartão de credito primeiro

  const { onCall } = useFirebaseFunctions();
  const { event } = useCheckoutPage();
  const { data } = useGetDocument<AdditionalInfoSettings>({
    collectionName: 'event',
    path: [event.eventId, 'additional-info', 'settings']
  });

  const securityInfo = useMemo(
    () => ({
      bank: data.pecegePay.bank ?? '',
      companyBillingDocument: data.pecegePay.companyBillingDocument ?? '',
      payee: data.pecegePay.payee ?? '',
      supportEmail: data.pecegePay.supportEmail ?? ''
    }),
    [data]
  );

  const creditCardInstallments = useMemo(
    () => ({
      installments: data.pecegePay.creditCard?.installments ?? null
    }),
    [data]
  );

  const daysToExpireText = useMemo(() => {
    let daysToBoletoExpire = 1;
    if (data.pecegePay && data.pecegePay.boleto && data.pecegePay.boleto.daysToExpireAt) {
      daysToBoletoExpire = data.pecegePay.boleto.daysToExpireAt;
    }
    const expireText = t('CHECKOUT.STEPS.PAYMENT_METHOD.BOLETO.INSTRUCTIONS.ITEM_3', {
      count: daysToBoletoExpire
    });
    return expireText;
  }, [data, t]);

  const paymentWarnings = useMemo(() => data.pecegePay.paymentWarnings, [data.pecegePay.paymentWarnings]);

  const handlePurchaseCompleteRequest = useCallback(
    (callableParams: PurchaseCompleteRequestType) =>
      onCall<PurchaseCompleteRequestType, PurchaseCompleteResponseType>({
        functionName: 'http-cal-stagingPurchase-complete',
        callableParams
      }),
    [onCall]
  );

  const value = useMemo(
    () => ({
      handlePurchaseCompleteRequest,
      daysToExpireText,
      securityInfo,
      selectedTabIndex,
      setSelectedTabIndex,
      creditCardInstallments,
      paymentWarnings
    }),
    [
      handlePurchaseCompleteRequest,
      daysToExpireText,
      securityInfo,
      selectedTabIndex,
      creditCardInstallments,
      paymentWarnings
    ]
  );
  return (
    <PaymentMethodsContext.Provider value={value}>
      <Suspense>{children}</Suspense>
    </PaymentMethodsContext.Provider>
  );
};
