import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  SelectLabel,
  InputLabel,
  ButtonWithIcon,
  InputGroup,
  SpinnerLoader,
  InputPhone,
  LandingPageCard
} from '../../../../components';
import { useCheckoutPage, useCustomer, useLayout } from '../../../../hooks';
import { CustomerInfoFormSchema } from './form-validation';
import {
  PurchaseCustomerType,
  PurchaseCustomerBillingAddressType,
  PecegePayDocumentType,
  PhoneNumberType
} from '../../../../interfaces';
import {
  inputDocumentMask,
  countries,
  defaultCountryValue,
  defaultDocumentTypeValue,
  keepOnlyNumbers,
  foreignDocumentTypes,
  brazilianDocumentTypes,
  handleRemoveMaskDocument,
  cep,
  toPurchasePhoneNumber
} from '../../../../utils';
import { countryStateApi } from '../../../../services/country-state-api';

type PurchaseCustomerPreview = Omit<PurchaseCustomerType, 'billingAddress' | 'phoneNumber'> & {
  phoneNumber: string | PhoneNumberType;
};

type CountryState = {
  id: number;
  iso2: string;
  name: string;
};

export const CustomerInfo: React.FC = () => {
  const navigate = useNavigate();
  const { handleContinueToPaymentMethods, stagingPurchase, purchaseTotalValue } = useCheckoutPage();
  const { getConsultViaCep, addressViaCep, handleCustomerRequest } = useCustomer();
  const { t } = useTranslation();
  const { setHeaderProps } = useLayout();

  const [countryStates, setCountryStates] = useState<CountryState[]>([]);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [firstParticipant] = stagingPurchase.participants ?? [undefined];
  const { customer } = stagingPurchase ?? undefined;
  const skipFirstRender = useRef(true);
  const defaultCountryCellPhone = firstParticipant ? firstParticipant.nacionality : defaultCountryValue;

  // WORKAROUND: para evitar que o usuario veja essa página quando estiver fazendo uma compra gratuita
  useEffect(() => {
    if (purchaseTotalValue === 0) {
      navigate(-1);
    }
  }, [navigate, purchaseTotalValue]);

  useEffect(() => {
    setHeaderProps({
      title: 'Checkout - Dados do comprador'
    });
  }, [setHeaderProps]);

  const methods = useForm<PurchaseCustomerPreview & PurchaseCustomerBillingAddressType>({
    resolver: yupResolver(CustomerInfoFormSchema)
  });

  const {
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { isSubmitting }
  } = methods;

  const selectedDocumentType = watch('documentType');
  const selectedNationality = watch('nacionality');

  const handleCountryStates = useCallback(async () => {
    if (!selectedNationality) return;

    setValue('state', '');
    setIsLoadingStates(true);
    setCountryStates([]);

    const { data: states } = await countryStateApi.get<CountryState[]>(`/countries/${selectedNationality}/states`);

    if (customer && selectedNationality === customer.billingAddress.nacionality) {
      setValue('state', customer.billingAddress.state);
    }

    if (selectedNationality !== customer?.billingAddress.nacionality || !customer) {
      setValue('state', states[0]?.iso2);
    }

    setCountryStates(states);
    setIsLoadingStates(false);
  }, [selectedNationality, setValue, customer]);

  useEffect(() => {
    if (skipFirstRender.current) {
      skipFirstRender.current = false;
      return;
    }
    const currentDocumentType = customer ? customer.documentType : firstParticipant?.documentType;
    if (selectedDocumentType && selectedDocumentType !== currentDocumentType) {
      setValue('document', '');
    }
  }, [customer, firstParticipant?.documentType, selectedDocumentType, setValue]);

  useEffect(() => {
    handleCountryStates();

    if (selectedNationality && selectedNationality !== defaultCountryValue) {
      return setValue('documentType', PecegePayDocumentType.PASSPORT);
    }
    if (!customer && firstParticipant && firstParticipant.nacionality === defaultCountryValue) {
      return setValue('documentType', firstParticipant.documentType);
    }
    if (customer && customer.billingAddress.nacionality === defaultCountryValue) {
      return setValue('documentType', customer.documentType);
    }
    return setValue('documentType', PecegePayDocumentType.CPF);
  }, [customer, firstParticipant, handleCountryStates, selectedNationality, setValue]);

  useEffect(() => {
    if (firstParticipant) {
      setValue('name', customer ? customer.name : `${firstParticipant?.firstName} ${firstParticipant.lastName}`);
      setValue('email', customer ? customer.email : firstParticipant.email);
      setValue('documentType', customer ? customer.documentType : firstParticipant.documentType);
      setValue(
        'document',
        customer
          ? String(inputDocumentMask(customer.document, customer.documentType))
          : String(inputDocumentMask(firstParticipant.document, firstParticipant.documentType))
      );
      setValue('nacionality', customer ? customer.billingAddress.nacionality : firstParticipant.nacionality);
      setValue(
        'phoneNumber',
        customer?.phoneNumber
          ? `${customer.phoneNumber.countryCode}${customer.phoneNumber.areaCode}${customer.phoneNumber.number}`
          : `${firstParticipant.cellphone.countryCode}${firstParticipant.cellphone.areaCode}${firstParticipant.cellphone.number}`
      );
      clearErrors(['name', 'email', 'documentType', 'document', 'nacionality', 'zipCode']);
    }

    if (customer) {
      setValue('placeNumber', customer.billingAddress.placeNumber);
      setValue('complement', customer.billingAddress.complement);
      setValue('city', customer.billingAddress.city);
      setValue('neighborhood', customer.billingAddress.neighborhood);
      setValue('street', customer.billingAddress.street);
    }

    if (customer && customer.billingAddress.nacionality === defaultCountryValue) {
      setValue('zipCode', String(cep(customer.billingAddress.zipCode)));
      getConsultViaCep(customer.billingAddress.zipCode);
    }
  }, [clearErrors, customer, firstParticipant, getConsultViaCep, setValue]);

  const handleAddressViaCep = useMemo(() => {
    if (addressViaCep && selectedNationality === defaultCountryValue) {
      if (addressViaCep.erro) {
        setError('zipCode', { type: 'focus', message: t('CHECKOUT.FORM.WARNINGS.ZIP_C0DE_NOT_FOUND').toString() });
        return false;
      }

      setValue('city', addressViaCep.localidade);
      setValue('state', addressViaCep.uf);
      setValue('neighborhood', addressViaCep.bairro);
      setValue('street', addressViaCep.logradouro);
      clearErrors(['city', 'state', 'neighborhood', 'street', 'zipCode']);
      return true;
    }

    return false;
  }, [addressViaCep, clearErrors, selectedNationality, setError, setValue, t]);

  const showZipCodeField = useMemo(() => {
    if (selectedNationality !== defaultCountryValue) {
      setValue('zipCode', '');
      return false;
    }
    return true;
  }, [selectedNationality, setValue]);

  const handleCustomer: SubmitHandler<PurchaseCustomerPreview & PurchaseCustomerBillingAddressType> = async values => {
    const response = await handleCustomerRequest({
      eventId: stagingPurchase.eventId,
      purchaseId: stagingPurchase.purchaseId,
      name: values.name,
      email: values.email,
      documentType: values.documentType,
      document: handleRemoveMaskDocument(values.documentType, values.document),
      phoneNumber: toPurchasePhoneNumber(values.phoneNumber as string),
      billingAddress: {
        nacionality: values.nacionality,
        zipCode: keepOnlyNumbers(values.zipCode),
        state: values.state,
        city: values.city,
        neighborhood: values.neighborhood,
        street: values.street,
        placeNumber: values.placeNumber,
        complement: values.complement.trim()
      }
    });
    if (response) {
      handleContinueToPaymentMethods();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCustomer)}>
        <LandingPageCard title={t('CHECKOUT.STEPS.BUYER_DATA.TITLE')}>
          <div className="w-full gap-3 pb-4 sm:flex sm:flex-col">
            <div className="w-full justify-between gap-12 sm:flex">
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="name"
                  label={t('CHECKOUT.FORM.FULLNAME').toString()}
                  placeholder={t('CHECKOUT.FORM.FULLNAME').toString()}
                  type="text"
                  disabled={isSubmitting}
                  maxLength={100}
                  autoComplete="none"
                />
              </div>

              <div className="mt-4 w-full sm:mt-0">
                <SelectLabel
                  name="nacionality"
                  label={t('CHECKOUT.FORM.NATIONALITY')}
                  defaultValue={firstParticipant?.nacionality ?? defaultCountryValue}
                  options={countries}
                  disabled={isSubmitting}
                  autoComplete="none"
                />
              </div>
            </div>

            <div className="w-full justify-between gap-12 sm:flex">
              <div className="mt-4 w-full sm:mt-0">
                <InputGroup
                  inputType="text"
                  label={t('CHECKOUT.FORM.DOCUMENT')}
                  placeholder={t('CHECKOUT.FORM.TYPE_HERE').toString()}
                  inputId="document"
                  selectId="documentType"
                  selectOptions={
                    selectedNationality !== defaultCountryValue ? foreignDocumentTypes : brazilianDocumentTypes
                  }
                  selectDefaultValue={firstParticipant?.documentType ?? defaultDocumentTypeValue}
                  onChangeValue={value => inputDocumentMask(value, selectedDocumentType)}
                  disabled={isSubmitting}
                  inputAutoComplete="none"
                />
              </div>
              <div className="mt-4 w-full sm:mt-0">
                <InputPhone
                  name="phoneNumber"
                  label={t('CHECKOUT.FORM.PHONE').toString()}
                  disabled={isSubmitting}
                  country={selectedNationality?.toLowerCase()}
                  defaultCountry={defaultCountryCellPhone.toLowerCase()}
                  minLength={10}
                  maxLength={20}
                />
              </div>
            </div>

            <div className="w-full justify-between gap-12 sm:flex">
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="email"
                  label={t('CHECKOUT.FORM.EMAIL').toString()}
                  placeholder={t('CHECKOUT.FORM.EMAIL').toString()}
                  type="email"
                  disabled={isSubmitting}
                  maxLength={50}
                  autoComplete="none"
                />
              </div>
              {showZipCodeField && (
                <div className="mt-4 w-full sm:mt-0">
                  <InputLabel
                    name="zipCode"
                    label={t('CHECKOUT.FORM.ZIP_CODE').toString()}
                    placeholder={t('CHECKOUT.FORM.ZIP_CODE').toString()}
                    type="text"
                    onChangeValue={value => cep(value)}
                    onBlurCapture={event => {
                      getConsultViaCep(event.currentTarget.value);
                    }}
                    disabled={isSubmitting}
                    autoComplete="none"
                  />
                </div>
              )}
            </div>

            <div className="w-full justify-between gap-12 sm:flex">
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="city"
                  label={t('CHECKOUT.FORM.CITY').toString()}
                  placeholder={t('CHECKOUT.FORM.CITY').toString()}
                  type="text"
                  disabled={isSubmitting || handleAddressViaCep}
                  maxLength={70}
                  autoComplete="none"
                />
              </div>
              <div className="mt-4 w-full sm:mt-0">
                {!!countryStates.length && (
                  <SelectLabel
                    name="state"
                    label={t('CHECKOUT.FORM.STATE')}
                    defaultValue={countryStates[0].iso2}
                    options={countryStates.map(state => ({ value: state.iso2, name: state.name }))}
                    disabled={isSubmitting || isLoadingStates || handleAddressViaCep}
                    autoComplete="none"
                  />
                )}

                {!countryStates.length && (
                  <InputLabel
                    name="state"
                    label={t('CHECKOUT.FORM.STATE').toString()}
                    placeholder={t('CHECKOUT.FORM.STATE').toString()}
                    type="text"
                    disabled={isSubmitting || handleAddressViaCep || isLoadingStates}
                    maxLength={70}
                    autoComplete="none"
                  />
                )}
              </div>
            </div>

            <div className="w-full justify-between gap-12 sm:flex">
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="neighborhood"
                  label={t('CHECKOUT.FORM.DISTRICT').toString()}
                  placeholder={t('CHECKOUT.FORM.DISTRICT').toString()}
                  type="text"
                  disabled={isSubmitting}
                  maxLength={70}
                  autoComplete="none"
                />
              </div>
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="street"
                  label={t('CHECKOUT.FORM.STREET').toString()}
                  placeholder={t('CHECKOUT.FORM.STREET').toString()}
                  type="text"
                  disabled={isSubmitting}
                  maxLength={100}
                  autoComplete="none"
                />
              </div>
            </div>

            <div className="w-full justify-between gap-12 sm:flex">
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="placeNumber"
                  label={t('CHECKOUT.FORM.PLACE_NUMBER').toString()}
                  placeholder={t('CHECKOUT.FORM.PLACE_NUMBER').toString()}
                  type="text"
                  disabled={isSubmitting}
                  maxLength={11}
                  autoComplete="none"
                />
              </div>
              <div className="mt-4 w-full sm:mt-0">
                <InputLabel
                  name="complement"
                  label={t('CHECKOUT.FORM.OTHER').toString()}
                  placeholder={t('CHECKOUT.FORM.OTHER').toString()}
                  type="text"
                  disabled={isSubmitting}
                  maxLength={30}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </LandingPageCard>

        <div className="mt-4 flex w-full flex-col items-center justify-center rounded-md bg-white py-8 px-4 sm:flex-row-reverse sm:gap-8 sm:px-8">
          <div className="mx-auto mr-6 w-full grow items-center sm:mr-0 sm:w-0">
            <ButtonWithIcon
              disabled={isSubmitting || !!addressViaCep?.erro}
              type="submit"
              className="bg-primary"
              icon={isSubmitting ? SpinnerLoader : ArrowRightIcon}
            >
              {isSubmitting ? t('CHECKOUT.BUTTONS.LOADING') : t('CHECKOUT.BUTTONS.NAVIGATE_TO_PAYMENT')}
            </ButtonWithIcon>
          </div>
          <div className="mt-4 sm:mt-0">
            <ButtonWithIcon
              type="button"
              customStylesButton="text-primary hover:text-primary text-sm"
              icon={ArrowLeftIcon}
              changePositionIconLeft
              onClick={() => navigate(`/checkout/${stagingPurchase.purchaseId}`)}
              disabled={isSubmitting}
            >
              {t('CHECKOUT.BUTTONS.BACK')}
            </ButtonWithIcon>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
