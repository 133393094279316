import { TranslateType } from './translate';

export enum EventModulesEnum {
  TRANSMISSAO = 'transmissao',
  INSCRICAO = 'inscricao',
  CERTIFICADOS = 'certificados',
  PROGRAMACAO = 'programacao',
  CREDENCIAMENTO = 'credenciamento',
  SUBMISSAO = 'submissao'
}

export enum CertificateFontColorEnum {
  BLACK = 0,
  WHITE = 1
}

export enum CertificateFontTypeEnum {
  Courier = 'Courier',
  CourierBold = 'Courier-Bold',
  CourierOblique = 'Courier-Oblique',
  CourierBoldOblique = 'Courier-BoldOblique',
  Helvetica = 'Helvetica',
  HelveticaBold = 'Helvetica-Bold',
  HelveticaOblique = 'Helvetica-Oblique',
  HelveticaBoldOblique = 'Helvetica-BoldOblique',
  TimesRoman = 'Times-Roman',
  TimesRomanBold = 'Times-Bold',
  TimesRomanItalic = 'Times-Italic',
  TimesRomanBoldItalic = 'Times-BoldItalic',
  Symbol = 'Symbol',
  ZapfDingbats = 'ZapfDingbats'
}

enum BroadcastTypeEnum {
  LIVE = 'live',
  RECORDED = 'recorded',
  WEB_CONF = 'web-conf'
}

export type PecegePaySettings = {
  companyBillingDocument: string;
  bank: string;
  payee: string;
  issueInvoice: boolean;
  boleto: {
    daysToExpireAt: number;
    fine: number | null;
    dailyInterest: number | null;
    timelyPaymentDiscount: number | null;
    instructions: string;
    daysToProtest: number | null;
    daysForAutomaticWriteOff: number | null;
  };
  creditCard: {
    installments: number | null;
  };
  supportEmail: string;
  paymentWarnings?: TranslateType;
};

export type AdditionalInfoSettings = {
  modules: EventModulesEnum[];
  pecegePay: PecegePaySettings;
};

export type AdditionalInfoLocation = {
  fullAddress?: string;
};

export type AdditionalInfoDescription = {
  about?: TranslateType;
};

export type AdditionalInfoCertificate = {
  backgroundImage: File;
  signatureImage: File;
  eventId: string;
  workload: number;
  name: string;
  companyName: string;
  fontType: CertificateFontTypeEnum;
  fontColor: CertificateFontColorEnum;
  signatureName: string;
  signatureRole: string;
  issuanceCity: string;
  validTickets: Array<string>;
};

export type AdditionalInfoBroadcast = {
  type: BroadcastTypeEnum;
  url: string;
};

export type AdditionalInfoBroadcastChatArena = {
  iframeSrc: string;
};
export type AdditionalInfoBroadcastChatMove = {
  publicKey: string;
};

export type AdditionalInfoBroadcastChat = {
  arena?: AdditionalInfoBroadcastChatArena;
  move?: AdditionalInfoBroadcastChatMove;
};

export type CompanyInviteField = {
  isEnabled: boolean;
  companies: string[];
};

export type AdditionalInfoCheckoutForm = {
  companyInviteField?: CompanyInviteField;
};
