import { DataImport } from '../interfaces';
import { useGetCollectionData } from './use-react-fire';

type UseDataImportProps = {
  eventId: string;
  importType?: DataImport['type'];
  importStatus?: DataImport['status'] | DataImport['status'][];
};

type UseDataImportReturn = {
  imports: DataImport[];
  isImportsEmpty: boolean;
};

type UseDataImport = (props: UseDataImportProps) => UseDataImportReturn;

export const useDataImport: UseDataImport = ({ eventId, importType, importStatus }) => {
  const isImportStatusArray = Array.isArray(importStatus);
  const { data: rawImports } = useGetCollectionData<DataImport>({
    collectionName: 'event',
    path: [eventId, 'data-import'],
    orderBy: {
      direction: 'asc',
      field: 'createdAt'
    }
  });
  const filteredImports = rawImports
    .filter(({ status }) => {
      if (!importStatus) return status;

      if (isImportStatusArray) {
        return importStatus.includes(status);
      }

      return importStatus === status;
    })
    .filter(({ type }) => {
      if (!importType) return type;

      return importType === type;
    });

  return {
    imports: filteredImports,
    isImportsEmpty: filteredImports.length === 0
  };
};
