import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { ChartBarIcon, UsersIcon, TicketIcon, DocumentTextIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { AdditionalInfoSettings, EventModulesEnum, SidebarNavigationItem } from '../../interfaces';
import { useGetDocument } from '../../hooks';

interface ContextProps {
  navigation: Array<SidebarNavigationItem>;
  secondaryNavigation: Array<{ name: string; to: string }>;
  data: AdditionalInfoSettings;
}

export const SidebarContext = createContext({} as ContextProps);

export const SidebarProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const { eventId = '' } = useParams();
  const { t } = useTranslation();

  const { data } = useGetDocument<AdditionalInfoSettings>({
    collectionName: 'event',
    path: [eventId, 'additional-info', 'settings']
  });

  const navigation: Array<SidebarNavigationItem> = [
    { name: t('DASHBOARD_PAGE.NAVIGATION.HOME'), to: 'dashboard', icon: ChartBarIcon },
    {
      name: t('DASHBOARD_PAGE.NAVIGATION.PARTICIPANTS'),
      to: '',
      icon: UsersIcon,
      children: [
        { name: t('DASHBOARD_PAGE.NAVIGATION.NEW_PARTICIPANT'), to: 'participants/new' },
        { name: t('DASHBOARD_PAGE.NAVIGATION.MANAGE_PARTICIPANTS'), to: 'participants/manage' }
      ]
    },
    {
      name: t('DASHBOARD_PAGE.NAVIGATION.TICKETS.TITLE'),
      to: '',
      icon: TicketIcon,
      children: [{ name: t('DASHBOARD_PAGE.NAVIGATION.TICKETS.COUPONS'), to: 'coupons' }]
    },
    {
      name: t('DASHBOARD_PAGE.NAVIGATION.REPORTS.TITLE'),
      to: '',
      icon: ClipboardIcon,
      children: [
        { name: t('DASHBOARD_PAGE.NAVIGATION.REPORTS.ACTIVITIES'), to: 'reports/activities' },
        { name: t('DASHBOARD_PAGE.NAVIGATION.REPORTS.PURCHASES'), to: 'reports/purchases' },
        { name: t('DASHBOARD_PAGE.NAVIGATION.REPORTS.ACCESS'), to: 'reports/access' }
      ]
    }
  ];

  const secondaryNavigation = [
    { name: 'Landing page do evento', to: `../${eventId}` },
    { name: 'Home page', to: '..' }
  ];

  if (data && data.modules.includes(EventModulesEnum.CERTIFICADOS)) {
    navigation.push({
      name: t('DASHBOARD_PAGE.NAVIGATION.CERTIFICATES.TITLE'),
      to: '',
      icon: DocumentTextIcon,
      children: [
        { name: t('DASHBOARD_PAGE.NAVIGATION.CERTIFICATES.MODEL'), to: 'certificates' },
        { name: t('DASHBOARD_PAGE.NAVIGATION.CERTIFICATES.ISSUANCE'), to: 'certificates/issuance' }
      ]
    });
  }

  return (
    <SidebarContext.Provider
      value={{
        navigation,
        secondaryNavigation,
        data
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
