import { Outlet } from 'react-router-dom';

import { LayoutProvider } from '../contexts';
import { Footer, PublicHeader, EnvBanner } from '../components';
import { useLayout } from '../hooks';

const LayoutContent = () => {
  const { headerProps } = useLayout();

  return (
    <>
      <PublicHeader />
      <Outlet />
      {headerProps.showFooter ? <Footer /> : null}
    </>
  );
};

export const PublicLayout = () => (
  <>
    <EnvBanner />
    <div className="mx-auto max-w-7xl pb-8 sm:px-6 lg:px-8">
      <LayoutProvider>
        <LayoutContent />
      </LayoutProvider>
    </div>
  </>
);
