import { Link } from 'react-router-dom';

import { useLayout } from '../../hooks';

export const PrivateHeader = () => {
  const { headerProps } = useLayout();
  return (
    <div className="border-b border-gray-200 p-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div className="min-w-0 flex-1">
        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">{headerProps.title}</h1>
      </div>
      <div className="mt-4 flex sm:mt-0 sm:ml-4">
        {headerProps.secondaryActionButton ? (
          <Link
            to={headerProps.secondaryActionButton.to}
            state={headerProps.secondaryActionButton.state}
            className="order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-0"
          >
            {headerProps.secondaryActionButton.title}
          </Link>
        ) : null}
        {headerProps.primaryActionButton ? (
          <Link
            to={headerProps.primaryActionButton.to}
            state={headerProps.primaryActionButton.state}
            className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:order-1 sm:ml-3"
          >
            {headerProps.primaryActionButton.title}
          </Link>
        ) : null}
      </div>
    </div>
  );
};
