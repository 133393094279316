import { initializeAnalytics } from 'firebase/analytics';
import { AnalyticsProvider, useInitAnalytics } from 'reactfire';

export const ReactFireAnalyticsProvider: React.FC<React.PropsWithChildren> = ({
  children
}: React.PropsWithChildren) => {
  const { data: analyticsInstance } = useInitAnalytics(async firebaseApp => {
    const analytics = initializeAnalytics(firebaseApp);

    return analytics;
  });
  return <AnalyticsProvider sdk={analyticsInstance}>{children}</AnalyticsProvider>;
};
