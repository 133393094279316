import { useMemo, useState } from 'react';
import { InputNumberCounter } from './index';

type updateTicketAmount = {
  ticketId: string;
  increment: boolean;
};

type StatusPriceProps = {
  isValidityOutOfRange: boolean;
  ticketSoldOut: boolean;
  currentValue: number | 'Grátis';
  maximumCounterValue: number | null | undefined;
  ticketId: string;
  outOfRangeMessage: string | null;
  getUpdateTicketAmount: ({ ticketId, increment }: updateTicketAmount) => void;
  purchaseCreateLoading: boolean;
  formatCurrency: (monetaryValue: number) => string;
};

export const StatusPriceCounter: React.FC<StatusPriceProps> = ({
  isValidityOutOfRange,
  ticketSoldOut,
  currentValue,
  maximumCounterValue,
  ticketId,
  outOfRangeMessage,
  getUpdateTicketAmount,
  purchaseCreateLoading,
  formatCurrency
}: StatusPriceProps) => {
  const [inputValue, setInputValue] = useState(0);
  const ticketTotal = useMemo(() => {
    if (currentValue !== 'Grátis') {
      return formatCurrency(currentValue * inputValue);
    }

    return currentValue;
  }, [inputValue, currentValue, formatCurrency]);

  return (
    <div className="bg-gray-50 p-6 text-center sm:p-6 lg:flex lg:w-80 lg:shrink-0 lg:flex-col lg:items-center lg:justify-center">
      {isValidityOutOfRange && (
        <h1 className="flex items-center justify-center text-2xl font-extrabold text-gray-900">{outOfRangeMessage}</h1>
      )}

      {ticketSoldOut && (
        <h1 className="flex items-center justify-center text-2xl font-extrabold text-gray-900">Esgotado</h1>
      )}

      {!isValidityOutOfRange && !ticketSoldOut && (
        <>
          <div className="text-3xl font-extrabold text-gray-900">
            <span>{ticketTotal}</span>
          </div>

          <div className="mt-4 flex justify-center">
            <InputNumberCounter
              maximumCounterValue={maximumCounterValue}
              ticketId={ticketId}
              onInputChange={setInputValue}
              getUpdateTicketAmount={getUpdateTicketAmount}
              purchaseCreateLoading={purchaseCreateLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};
