import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationProvider, RecaptchaProvider } from './contexts';
import { useTawkTo } from './hooks';
import Routes from './routes';

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
const gtmId = process.env.REACT_APP_GTM_ID as string;
const gtmParams = gtmId && gtmId.length > 0 ? { id: gtmId } : null;

const AppComponents = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={recaptchaKey}
    scriptProps={{
      defer: true,
      async: true
    }}
    language="default"
  >
    <RecaptchaProvider>
      <BrowserRouter>
        <AuthenticationProvider>
          <Routes />
        </AuthenticationProvider>
      </BrowserRouter>
    </RecaptchaProvider>
  </GoogleReCaptchaProvider>
);

const App = () => {
  useTawkTo();
  if (!gtmParams) {
    return <AppComponents />;
  }
  return <GTMProvider state={gtmParams}>{<AppComponents />}</GTMProvider>;
};

export default App;
