import { useTranslation } from 'react-i18next';
import { useCheckoutPage } from '../../../../../hooks';
import { ItemsPurchaseSummary } from './items-purchase-summary';

export const PurchaseSummaryTable = () => {
  const { t } = useTranslation();
  const { stagingPurchase } = useCheckoutPage();

  return (
    <div className="w-full gap-8 pb-4 sm:flex sm:flex-col">
      <div className="-mx-4 flex flex-col sm:-mx-6 sm:px-6">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-1"
              >
                {t('CHECKOUT.STEPS.ORDER_SUMMARY.TABLE.HEADER.ITEM')}
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                {t('CHECKOUT.STEPS.ORDER_SUMMARY.TABLE.HEADER.QUANTITY')}
              </th>
              <th scope="col" className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                {t('CHECKOUT.STEPS.ORDER_SUMMARY.TABLE.HEADER.PRICE')}
              </th>
              <th scope="col" className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                {t('CHECKOUT.STEPS.ORDER_SUMMARY.TABLE.HEADER.COUPON')}
              </th>
              <th
                scope="col"
                className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-1"
              >
                {t('CHECKOUT.STEPS.ORDER_SUMMARY.TABLE.HEADER.TOTAL')}
              </th>
            </tr>
          </thead>

          <ItemsPurchaseSummary purchaseSummary={stagingPurchase.tickets} />
        </table>
      </div>
    </div>
  );
};
