import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InputGroup, SelectLabel, InputLabel, InputPhone, InputRadioGroup, LandingPageCard } from '../../components';
import {
  inputDocumentMask,
  countries,
  defaultCountryValue,
  foreignDocumentTypes,
  brazilianDocumentTypes,
  defaultDocumentTypeValue,
  courses,
  defaultCourseValue
} from '../../utils';
import { CompanyInviteField, PecegePayDocumentType } from '../../interfaces';
import { OptionalStagingPurchaseType, StagingPurchaseParticipantType } from '../../contexts';

const optionsPeopleSpecialNeeds = [
  { name: 'CHECKOUT.FORM.PCD_OPTIONS.YES', value: 'true' },
  { name: 'CHECKOUT.FORM.PCD_OPTIONS.NO', value: 'false' }
];

const languagesContact = [
  { name: 'Português', value: 'pt-br' },
  { name: 'English', value: 'en' },
  { name: 'Español', value: 'es' }
];

const languageContactDefaultValue = 'pt-br';

type Props = {
  order: number;
  participantsRequestLoading: boolean;
  stagingPurchase: OptionalStagingPurchaseType;
  participantTicketList: StagingPurchaseParticipantType;
  companyInviteField?: CompanyInviteField;
};

export const ParticipantInfo = ({
  order,
  participantsRequestLoading,
  participantTicketList,
  stagingPurchase,
  companyInviteField
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'CHECKOUT' });
  const { requireEnrollmentCheck } = participantTicketList[order];
  const methods = useFormContext();
  const { setValue } = methods;
  const skipFirstRender = useRef(true);
  const defaultCountryCellPhone = stagingPurchase.participants
    ? stagingPurchase.participants[order].nacionality
    : defaultCountryValue;

  const selectedDocumentType = useWatch({
    name: `participants.${order}.documentType`
  });

  const ticketTitle = useWatch({
    name: `participants.${order}.ticketTitle`
  });

  const selectedNationality = useWatch({
    name: `participants.${order}.nacionality`
  });

  const isPeopleSpecialNeeds = useWatch({
    name: `participants.${order}.isPeopleSpecialNeeds`
  });

  const companyList = useMemo(() => {
    if (!companyInviteField) {
      return [];
    }

    const { isEnabled, companies } = companyInviteField;

    if (!isEnabled) {
      return [];
    }

    const options = companies.map(company => ({
      name: company,
      value: company
    }));

    options.unshift({
      name: 'Não fui convidado por uma empresa ou, empresa não está na lista',
      value: '0'
    });

    return options;
  }, [companyInviteField]);

  useEffect(() => {
    if (stagingPurchase.participants) {
      setValue(`participants.${order}.firstName`, stagingPurchase.participants[order].firstName);
      setValue(`participants.${order}.lastName`, stagingPurchase.participants[order].lastName);
      setValue(`participants.${order}.email`, stagingPurchase.participants[order].email);
      setValue(`participants.${order}.documentType`, stagingPurchase.participants[order].documentType);
      setValue(
        `participants.${order}.document`,
        String(
          inputDocumentMask(
            stagingPurchase.participants[order].document,
            stagingPurchase.participants[order].documentType
          )
        )
      );
      setValue(`participants.${order}.nacionality`, stagingPurchase.participants[order].nacionality);
      setValue(`participants.${order}.tagName`, stagingPurchase.participants[order].tagName);
      setValue(
        `participants.${order}.cellphone`,
        `${stagingPurchase.participants[order].cellphone.countryCode}${stagingPurchase.participants[order].cellphone.areaCode}${stagingPurchase.participants[order].cellphone.number}`
      );
      setValue(`participants.${order}.course`, stagingPurchase.participants[order].course);
      setValue(`participants.${order}.company`, stagingPurchase.participants[order].company);
      setValue(
        `participants.${order}.isPeopleSpecialNeeds`,
        `${stagingPurchase.participants[order].isPeopleSpecialNeeds}`
      );
      setValue(
        `participants.${order}.peopleSpecialNeedsType`,
        stagingPurchase.participants[order].peopleSpecialNeedsType
      );
      setValue(`participants.${order}.languageContact`, stagingPurchase.participants[order].languageContact);
    }
  }, [order, setValue, stagingPurchase.participants]);

  useEffect(() => {
    if (skipFirstRender.current && selectedDocumentType) {
      skipFirstRender.current = false;
      return;
    }
    const currentDocumentType = stagingPurchase.participants
      ? stagingPurchase.participants[order].documentType
      : PecegePayDocumentType.CPF;
    if (selectedDocumentType && selectedDocumentType !== currentDocumentType) {
      setValue(`participants.${order}.document`, '');
    }
  }, [order, selectedDocumentType, setValue, stagingPurchase.participants]);

  useEffect(() => {
    if (selectedNationality && selectedNationality !== defaultCountryValue) {
      return setValue(`participants.${order}.documentType`, PecegePayDocumentType.PASSPORT);
    }
    if (stagingPurchase.participants && stagingPurchase.participants[order].nacionality === defaultCountryValue) {
      return setValue(`participants.${order}.documentType`, stagingPurchase.participants[order].documentType);
    }
    if (stagingPurchase.participants && stagingPurchase.participants[order].nacionality === defaultCountryValue) {
      return setValue(`participants.${order}.documentType`, stagingPurchase.participants[order].documentType);
    }
    return setValue(`participants.${order}.documentType`, PecegePayDocumentType.CPF || PecegePayDocumentType.CNPJ);
  }, [order, selectedNationality, setValue, stagingPurchase.participants]);

  const showPeopleSpecialNeedsTypeField = useMemo(() => {
    const isNotPeopleSpecialNeeds = 'false';

    if (!isPeopleSpecialNeeds || isPeopleSpecialNeeds === isNotPeopleSpecialNeeds) {
      setValue(`participants.${order}.peopleSpecialNeedsType`, '');
      return false;
    }
    return true;
  }, [isPeopleSpecialNeeds, order, setValue]);

  return (
    <LandingPageCard title={t('STEPS.PARTICIPANTS_DATA.TITLE')} subtitle={`nº ${order + 1} - ${ticketTitle}`}>
      <div className="w-full gap-3 pb-4 sm:flex sm:flex-col">
        <div className="w-full justify-between gap-12 sm:flex">
          <div className="mt-4 w-full sm:mt-0">
            <InputLabel
              name={`participants.${order}.firstName`}
              label={t('FORM.NAME').toString()}
              placeholder={t('FORM.NAME').toString()}
              type="text"
              autoComplete="none"
              maxLength={50}
              disabled={participantsRequestLoading}
            />
          </div>

          <div className="mt-4 w-full sm:mt-0">
            <InputLabel
              name={`participants.${order}.lastName` as const}
              label={t('FORM.SURNAME').toString()}
              placeholder={t('FORM.SURNAME').toString()}
              type="text"
              autoComplete="none"
              maxLength={50}
              disabled={participantsRequestLoading}
            />
          </div>
        </div>

        <div className="w-full justify-between gap-12 sm:flex">
          <div className="mt-4 w-full sm:mt-0">
            <InputLabel
              name={`participants.${order}.email` as const}
              label={t('FORM.EMAIL').toString()}
              placeholder={t('FORM.EMAIL').toString()}
              type="email"
              autoComplete="none"
              maxLength={50}
              disabled={participantsRequestLoading}
            />
          </div>
          <div className="mt-4 w-full sm:mt-0">
            <InputLabel
              name={`participants.${order}.confirmEmail` as const}
              label={t('FORM.CONFIRM_EMAIL').toString()}
              placeholder={t('FORM.CONFIRM_EMAIL').toString()}
              type="email"
              onPaste={e => e.preventDefault()}
              autoComplete="none"
              maxLength={50}
              disabled={participantsRequestLoading}
            />
          </div>
        </div>

        <div className="w-full justify-between gap-12 sm:flex">
          <div className="mt-4 w-full sm:mt-0">
            <SelectLabel
              name={`participants.${order}.nacionality` as const}
              label={t('FORM.NATIONALITY')}
              defaultValue={defaultCountryValue}
              options={countries}
              autoComplete="none"
              disabled={participantsRequestLoading}
            />
          </div>
          <div className="mt-4 w-full sm:mt-0">
            <InputGroup
              inputType="text"
              label={t('FORM.DOCUMENT')}
              placeholder={t('FORM.TYPE_HERE').toString()}
              inputId={`participants.${order}.document` as const}
              selectId={`participants.${order}.documentType` as const}
              selectOptions={
                selectedNationality !== defaultCountryValue ? foreignDocumentTypes : brazilianDocumentTypes
              }
              selectDefaultValue={defaultDocumentTypeValue}
              onChangeValue={value => inputDocumentMask(value, selectedDocumentType)}
              inputAutoComplete="none"
              disabled={participantsRequestLoading}
            />
          </div>
        </div>

        <div className="w-full justify-between gap-12 sm:flex">
          <div className="mt-4 w-full sm:mt-0">
            <InputLabel
              name={`participants.${order}.tagName` as const}
              label={t('FORM.TAG').toString()}
              placeholder={t('FORM.TAG').toString()}
              type="text"
              autoComplete="none"
              disabled={participantsRequestLoading}
            />
          </div>

          <div className="mt-4 w-full sm:mt-0">
            <InputPhone
              name={`participants.${order}.cellphone` as const}
              label={t('FORM.PHONE').toString()}
              disabled={participantsRequestLoading}
              country={selectedNationality?.toLowerCase()}
              defaultCountry={defaultCountryCellPhone.toLowerCase()}
              minLength={10}
              maxLength={20}
            />
          </div>
        </div>
        {requireEnrollmentCheck && (
          <>
            <div className="mt-4 w-full sm:mt-0">
              <SelectLabel
                name={`participants.${order}.course` as const}
                label={t('FORM.MBA_COURSE')}
                defaultValue={defaultCourseValue}
                options={courses}
                disabled={participantsRequestLoading}
              />
            </div>
            <small className="font-medium text-red-500">{t('FORM.INFORMATION_MBA_COURSE')}</small>
          </>
        )}

        {companyInviteField?.isEnabled && (
          <>
            <div className="mt-4 w-full sm:mt-0">
              <SelectLabel
                name={`participants.${order}.company` as const}
                label={t('FORM.COMPANY')}
                defaultValue={companyList[0].value}
                options={companyList}
                disabled={participantsRequestLoading}
              />
            </div>
          </>
        )}
        <div className="mt-3 w-full items-center justify-between gap-14 sm:flex">
          <div className="mt-4 sm:mt-0 sm:flex-none">
            <InputRadioGroup
              label={t('FORM.PCD')}
              name={`participants.${order}.isPeopleSpecialNeeds` as const}
              options={optionsPeopleSpecialNeeds}
              disabled={participantsRequestLoading}
            />
          </div>
          {showPeopleSpecialNeedsTypeField && (
            <div className="mt-4 sm:mt-0 sm:grow">
              <InputLabel
                name={`participants.${order}.peopleSpecialNeedsType` as const}
                label={t('FORM.PDC_DESCRIPTION').toString()}
                placeholder={t('FORM.PCD_TYPE_HERE').toString()}
                type="text"
                autoComplete="none"
                maxLength={50}
                disabled={participantsRequestLoading}
              />
            </div>
          )}
        </div>
        <div className="w-full justify-between gap-12 sm:flex">
          <div className="mt-4 w-full sm:mt-2">
            <SelectLabel
              name={`participants.${order}.languageContact` as const}
              label={t('FORM.LANGUAGE_CONTACT')}
              defaultValue={languageContactDefaultValue}
              options={languagesContact}
              autoComplete="none"
              disabled={participantsRequestLoading}
            />
          </div>
        </div>
      </div>
    </LandingPageCard>
  );
};
