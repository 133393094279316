type Props = React.PropsWithChildren & { title: string; subtitle?: string; icon?: JSX.Element };

export const LandingPageCard = ({ title, subtitle, icon, children }: Props) => (
  <section className="rounded-md bg-white p-8">
    <div className="flex h-full w-full flex-col items-start gap-12 sm:pb-0 ">
      <div className="flex flex-col gap-2">
        <h2 className="flex text-3xl font-bold text-neutral-800 sm:text-2xl sm:tracking-tight lg:text-3xl">
          {icon ? <span className="mr-2">{icon} </span> : null}
          {title}
        </h2>
        <h3 className="text-lg font-normal text-neutral-600 sm:text-base sm:tracking-tight lg:text-lg">{subtitle}</h3>
      </div>

      <div className="flex w-full flex-col gap-8 pb-4">{children}</div>
    </div>
  </section>
);
