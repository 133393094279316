import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { SpinnerLoader } from '..';

type SubscribeBarProps = {
  title: string;
  formatCurrency: (monetaryValue: number) => string;
  ticketsCount: number;
  sumSelectedTicketsValues: number;
  purchaseCreateLoading: boolean;
};

const buttonClassName =
  'cursor-pointer px-4 flex justify-center items-center bg-primary text-white md:bg-white md:text-primary rounded-md h-full w-full md:w-2/5 font-bold text-sm md:text-lg';

export const SubscribeBar = ({
  title,
  formatCurrency,
  purchaseCreateLoading,
  sumSelectedTicketsValues,
  ticketsCount
}: SubscribeBarProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="fixed bottom-14 left-[50%] z-10 flex 
    h-20 w-full translate-x-[-50%] items-center justify-center rounded-xl px-6 py-4 sm:justify-between md:max-w-screen-lg md:bg-primary"
    >
      <p className="hidden text-lg font-medium text-white md:block">{title}</p>
      {ticketsCount <= 0 && (
        <Link as="button" to="subscription" smooth className={buttonClassName}>
          {t('LANDING_PAGE.BUTTONS.SUBSCRIBE')}
        </Link>
      )}

      {ticketsCount >= 1 && (
        <button disabled={purchaseCreateLoading} type="submit" className={buttonClassName}>
          {t('LANDING_PAGE.BUTTONS.SUBSCRIBE')} - {formatCurrency(sumSelectedTicketsValues)}
          {purchaseCreateLoading && <SpinnerLoader className="ml-4" />}
        </button>
      )}
    </div>
  );
};
