import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { formatMonetaryToLocale, inputDocumentMask } from '../../../../../../utils';
import { PecegePayCompleteOrderCreditCardResponseType } from '../../../../../../interfaces';

type PaymentSuccessProps = {
  event: { name: string; type: string };
  pecegePay: PecegePayCompleteOrderCreditCardResponseType;
  lastFourDigits: string;
  cardBrand: string;
  ticketsQuantity: number;
};

export const PaymentSuccess: React.FC<PaymentSuccessProps> = ({
  event,
  pecegePay,
  lastFourDigits,
  cardBrand,
  ticketsQuantity
}: PaymentSuccessProps) => {
  const { t } = useTranslation();
  const formatMonetary = (monetaryValue: number) =>
    formatMonetaryToLocale({ value: monetaryValue, lang: 'pt-BR', style: 'currency', currency: 'BRL' });

  return (
    <>
      <div className="ml-6 flex flex-col items-center gap-6">
        <CheckCircleIcon className="w-20 text-green-500" />
        <div className="text-lg font-medium text-green-600">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.TITLE')}
        </div>
        <div className="text-center">
          <Trans
            i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.DESCRIPTION_EMAIL"
            components={{ strong: <strong /> }}
            values={{
              customerName: pecegePay.customer.name,
              eventName: event.name
            }}
          />
        </div>
        <span>{t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.DESCRIPTION_SPAM')}</span>
        <Link to="/" className="text-base text-primary hover:brightness-50">
          {t('CHECKOUT.ERROR_BOUNDARY.BUTTON')}
        </Link>
      </div>

      <hr className="mb-4" />
      <span className="flex justify-center font-medium">
        {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.PURCHASE_SUMMARY.TITLE')}
      </span>

      <div className="flex flex-col justify-between sm:flex-row">
        <div className="flex flex-col ">
          <span>
            <Trans
              i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.PURCHASE_SUMMARY.TICKET_EVENT"
              values={{
                eventName: event.name
              }}
            />
          </span>
          <span>
            {pecegePay.customer.name} - {pecegePay.customer.documentType.toUpperCase()}{' '}
            {String(inputDocumentMask(pecegePay.customer.document, pecegePay.customer.documentType.toLowerCase()))}
          </span>
          <span>
            <Trans
              i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.PURCHASE_SUMMARY.EMAIL"
              values={{
                customerEmail: pecegePay.customer.email
              }}
            />
          </span>
          <span>
            <Trans
              i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.PURCHASE_SUMMARY.CREDIT_CARD_INFO"
              values={{
                cardBrand,
                lastFourDigits
              }}
            />
          </span>
        </div>
        <div className="flex flex-col items-end">
          <span className="font-medium">
            {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.PURCHASE_SUMMARY.QUANTITY', {
              count: ticketsQuantity
            })}
          </span>
          <span className="font-medium text-green-600">
            <Trans
              i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_SUCCESS.PURCHASE_SUMMARY.AMOUNT"
              values={{
                amount: formatMonetary(pecegePay.amount)
              }}
            />
          </span>
        </div>
      </div>

      <hr />
    </>
  );
};
