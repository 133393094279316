import { useContext } from 'react';
import { PaymentMethodsContext } from '../contexts';

export const usePaymentMethods = () => {
  const context = useContext(PaymentMethodsContext);
  if (!context) {
    throw new Error('usePaymentMethods deve ser usado dentro do PaymentMethodsProvider.');
  }
  return context;
};
