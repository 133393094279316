import { Trans, useTranslation } from 'react-i18next';

import { useCheckoutPage } from '../../../../../../hooks';
import { LandingPageCard, SpinnerLoader } from '../../../../../../components';
import { FreeFailed } from './free-failed';
import { FreeSuccess } from './free-success';

type Props = {
  logErrorCount: number;
};

export const FreeComplete: React.FC<Props> = ({ logErrorCount }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'CHECKOUT.STEPS.ORDER_COMPLETE.FREE' });
  const { stagingPurchase } = useCheckoutPage();

  if (logErrorCount > 0 && logErrorCount < 5) {
    return (
      <LandingPageCard title={t('TITLE')}>
        <SpinnerLoader />
        <h1 className="pt-4 text-center text-lg font-medium">
          <Trans
            i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.FREE.VALIDATING"
            values={{
              logErrorCount
            }}
          />
        </h1>
      </LandingPageCard>
    );
  }

  if (logErrorCount === 5) {
    return <FreeFailed />;
  }

  return (
    <LandingPageCard title={t('TITLE')}>
      <FreeSuccess sendPurchaseInfoTo={stagingPurchase.sendPurchaseInfoTo} />
    </LandingPageCard>
  );
};
