import { createContext, Dispatch, SetStateAction, useState } from 'react';

import { useGetDocumentOnce } from '../hooks';
import { AdditionalInfoDescription, Event } from '../interfaces';

interface ContextProps {
  event?: Event;
  descripition?: AdditionalInfoDescription;
  isDescriptionOpen: boolean;
  setIsDescriptionOpen: Dispatch<SetStateAction<boolean>>;
}

export const BroadcastContext = createContext({} as ContextProps);

export const BroadcastProvider: React.FC<React.PropsWithChildren & { eventId: string }> = ({
  children,
  eventId
}: React.PropsWithChildren & { eventId: string }) => {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const { data: event } = useGetDocumentOnce<Event>({
    collectionName: 'event',
    path: [eventId]
  });

  const { data: descripition } = useGetDocumentOnce<AdditionalInfoDescription>({
    collectionName: 'event',
    path: [eventId, 'additional-info', 'description']
  });

  return (
    <BroadcastContext.Provider value={{ event, descripition, setIsDescriptionOpen, isDescriptionOpen }}>
      {children}
    </BroadcastContext.Provider>
  );
};
