import { useContext } from 'react';
import { CustomerContext } from '../contexts';

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomer deve ser usado dentro do CustomerProvider.');
  }
  return context;
};
