import { ComponentProps, ComponentType } from 'react';
import classNames from 'classnames';
import { CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/20/solid';

const eventTypes: { [key: string]: { icon: ComponentType<ComponentProps<'svg'>>; bgColorClass: string } } = {
  user: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  thumb: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
  check: { icon: CheckIcon, bgColorClass: 'bg-green-500' }
};

export enum TimelineTypeEnum {
  USER = 'user',
  THUMB = 'thumb',
  CHECK = 'check'
}

type TimelineProps = {
  id: string;
  type: TimelineTypeEnum;
  content: string;
  date: string;
};

export const Timeline = ({ timeline }: { timeline: Array<TimelineProps> }) => {
  const renderIcon = (type: TimelineTypeEnum) => {
    const event = eventTypes[type];
    return <event.icon className="h-5 w-5 text-white" aria-hidden="true" />;
  };

  return (
    <ul>
      {timeline.map((item, itemIdx) => (
        <li key={item.id}>
          <div className="relative pb-8">
            {itemIdx !== timeline.length - 1 ? (
              <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            ) : null}
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={classNames(
                    eventTypes[item.type].bgColorClass,
                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                  )}
                >
                  {renderIcon(item.type)}
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p className="text-sm text-gray-500 line-clamp-3">{item.content}</p>
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  <time>{item.date}</time>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
