import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';

type Props = {
  tabList: Array<{
    name: string;
    disabled: boolean;
    icon?: JSX.Element | null;
  }>;
  tabPanels: Array<{ index: string; panel: React.ReactNode }>;
  setSelectedTabIndex?: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
  selectedIndex?: number;
};
export const HorizontalTabWithUnderline: React.FC<Props> = ({
  tabList,
  tabPanels,
  setSelectedTabIndex,
  className,
  selectedIndex
}: Props) => {
  useEffect(() => {
    if (setSelectedTabIndex !== undefined) {
      setSelectedTabIndex(selectedIndex ?? 0);
    }
  }, [selectedIndex, setSelectedTabIndex]);

  const tabClasses = ({ hasIcon }: { hasIcon: boolean }) => {
    const defaultTabClass = `disabled:hidden border-b-2 font-medium text-gray-400 text-sm py-4 px-1 w-full focus:outline-none focus:ring-off`;
    return classNames(defaultTabClass, {
      'inline-flex items-center justify-center gap-x-1.5': hasIcon
    });
  };

  return (
    <Tab.Group onChange={setSelectedTabIndex} selectedIndex={selectedIndex}>
      {tabList.length > 1 && (
        <Tab.List className={`flex ${className || 'justify-evenly'}`}>
          {tabList.map(({ name, disabled, icon = null }) => (
            <Tab
              key={name}
              disabled={disabled}
              className={({ selected }) =>
                `${tabClasses({ hasIcon: !!icon })} ${
                  selected ? 'border-primary text-primary' : 'hover:border-gray-300 hover:text-gray-600'
                }`
              }
            >
              <>
                {icon}
                {name}
              </>
            </Tab>
          ))}
        </Tab.List>
      )}
      <Tab.Panels>
        {tabPanels.map(({ index, panel }) => (
          <Tab.Panel key={index}>{panel}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
