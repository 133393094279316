import { Fragment, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Alerts, SpinnerLoader } from './';
import { useLayout } from '../hooks';
import classNames from 'classnames';

export const SlideOverPanel = () => {
  const { slideOverProps, slideOverOpen, closeSlideOver } = useLayout();
  return (
    <Transition.Root show={slideOverOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeSlideOver}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={classNames(
                    'pointer-events-auto w-screen',
                    !slideOverProps.widePanel ? 'max-w-md' : 'max-w-2xl'
                  )}
                >
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-blue-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">{slideOverProps.title}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={closeSlideOver}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-blue-300">{slideOverProps.details}</p>
                      </div>
                    </div>
                    <div className="relative flex-1 py-6 px-4 sm:px-6">
                      <div className="absolute inset-0 py-6 px-4 sm:px-6">
                        <Suspense fallback={<SpinnerLoader size="sm" />}>
                          <ErrorBoundary
                            fallback={
                              <Alerts
                                type="error"
                                message="Ops, um erro inesperado aconteceu no carregamento do componente. 
                                Entre em contato através do e-mail linka@pecege.com para nos informar o que aconteceu."
                              />
                            }
                          >
                            {slideOverProps.component}
                          </ErrorBoundary>
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
