import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/20/solid';

import { useGetCollectionData, useLayout } from '../../../../hooks';
import { StagingPurchaseType, PurchaseStatusEnum, PurchaseTicketsType } from '../../../../interfaces';
import { formatDateToLocale, formatMonetaryToLocale } from '../../../../utils';
import { Alerts, Pill, Table } from '../../../../components';
import { PurchaseDetail } from './details';

export const StagingPurchasesList = () => {
  const { eventId = '' } = useParams();
  const { openSlideOver, setSlideOverProps } = useLayout();
  const eventCollectionName = 'event';
  const purchasesCollectionName = 'staging-purchases';

  const { data } = useGetCollectionData<StagingPurchaseType>({
    collectionName: eventCollectionName,
    path: [eventId, purchasesCollectionName],
    constraints: [
      {
        fieldPath: 'status',
        opStr: 'in',
        value: [PurchaseStatusEnum.VOID, PurchaseStatusEnum.OPEN, PurchaseStatusEnum.EXPIRED, PurchaseStatusEnum.FAILED]
      }
    ],
    orderBy: {
      direction: 'desc',
      field: 'createdAt'
    }
  });

  const amountPaid = useCallback(
    (tickets: Array<PurchaseTicketsType>) =>
      tickets.reduce((acc, ticket) => {
        if (ticket.coupon) {
          const discount = (ticket.value * ticket.quantity * ticket.coupon.discount) / 100;
          return acc + ticket.value * ticket.quantity - discount;
        }
        return acc + ticket.value * ticket.quantity;
      }, 0),
    []
  );

  const formatStatus = (status: PurchaseStatusEnum) => {
    const statusObject: { color: string; text: string } = { color: '', text: '' };
    switch (status) {
      case PurchaseStatusEnum.CANCELLED:
        statusObject.text = 'Cancelado';
        statusObject.color = 'gray';
        break;
      case PurchaseStatusEnum.FAILED:
        statusObject.text = 'Falhou';
        statusObject.color = 'red';
        break;
      case PurchaseStatusEnum.EXPIRED:
        statusObject.text = 'Expirada';
        statusObject.color = 'gray';
        break;
      case PurchaseStatusEnum.PARTIALLY_REFUNDED:
        statusObject.text = 'Estornado parc.';
        statusObject.color = 'red';
        break;
      case PurchaseStatusEnum.FULLY_REFUNDED:
        statusObject.text = 'Estornado';
        statusObject.color = 'red';
        break;
      case PurchaseStatusEnum.IN_CANCELATION:
        statusObject.text = 'Cancelando';
        statusObject.color = 'gray';
        break;
      case PurchaseStatusEnum.PAID:
        statusObject.text = 'Pago';
        statusObject.color = 'green';
        break;
      case PurchaseStatusEnum.VOID:
      case PurchaseStatusEnum.OPEN:
        statusObject.text = 'Em andamento';
        statusObject.color = 'blue';
        break;
      default:
        break;
    }
    return statusObject;
  };

  const openSlideOverPanel = useCallback(
    (purchase: StagingPurchaseType) => {
      setSlideOverProps({
        component: <PurchaseDetail purchase={purchase} />,
        details: `Visualize detalhes da compra como, dados do comprador, participantes, ingressos, valores e descontos.`,
        title: `Ver compra ${purchase.purchaseId}`,
        widePanel: true
      });
      openSlideOver();
    },
    [openSlideOver, setSlideOverProps]
  );

  const list = useMemo(
    () =>
      data.map(purchase => ({
        purchaseId: (
          <div className="flex">
            <button
              type="button"
              onClick={() => openSlideOverPanel(purchase)}
              className="group inline-flex space-x-2 truncate text-sm"
            >
              <EyeIcon className="h-5 w-5 shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              <p className="truncate text-gray-500 group-hover:text-gray-900">{purchase.purchaseId}</p>
            </button>
          </div>
        ),
        createdAt: formatDateToLocale({ date: purchase.createdAt.toDate(), style: 'Ppp', lang: 'pt' }),
        status: <Pill text={formatStatus(purchase.status).text} color={formatStatus(purchase.status).color} />,
        ticketCount: purchase.tickets.map(t => t.quantity).reduce((prevVal, currVal) => prevVal + currVal, 0),
        amountPaid: {
          value: amountPaid(purchase.tickets),
          format: (value: number) =>
            formatMonetaryToLocale({
              value,
              lang: 'pt-BR',
              style: 'currency',
              currency: 'BRL'
            })
        },
        lineId: purchase.purchaseId
      })),
    [amountPaid, data, openSlideOverPanel]
  );

  const columnNames = {
    purchaseId: 'Id da compra',
    ticketCount: 'Qtd. ingressos',
    createdAt: 'Criado em',
    status: 'Status',
    amountPaid: 'Valor total'
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden border p-4 md:rounded-lg">
            {list.length > 0 ? (
              <Table
                tableData={list}
                columnNames={columnNames}
                tableHeader={{
                  title: 'Lista de compras em andamento',
                  details:
                    'Veja em tempo real, compras em andamento, expiradas ou com falha (as compras em andamento vão sumir da lista quando o status estiver como pago)'
                }}
                showFooter
              />
            ) : (
              <Alerts message="Nenhum registro de compra encontrado" type="none" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
