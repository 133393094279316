import { useCallback, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Card } from '../../../../components';
import { useBroadcast } from '../../../../hooks';
import { Activity } from '../../../../interfaces';
import { About } from './about';
import { Speakers } from './speakers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type BroadcastDescriptionType = {
  title: string;
  description: string;
  speakers: Activity['speakers'];
};

const SMALL_WINDOW_WIDTH = 640;

export const BroadcastDescription = ({ title, description, speakers }: BroadcastDescriptionType) => {
  const { t } = useTranslation('translation', { keyPrefix: 'BROADCAST_PAGE' });
  const { setIsDescriptionOpen, isDescriptionOpen } = useBroadcast();

  const handleResize = useCallback(() => {
    if (window.innerWidth >= SMALL_WINDOW_WIDTH) {
      setIsDescriptionOpen(false);
    }
  }, [setIsDescriptionOpen]);

  useEffect(() => {
    if (isDescriptionOpen) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, isDescriptionOpen]);

  return (
    <>
      {!isDescriptionOpen && (
        <Card noShadow>
          <>
            <div className="relative flex w-full flex-col">
              <span className="block font-semibold text-gray-700 antialiased line-clamp-2 sm:hidden lg:line-clamp-none">
                {title}
              </span>
              <button
                className="block self-end text-primary underline underline-offset-4 active:brightness-75 sm:hidden"
                type="button"
                onClick={() => setIsDescriptionOpen(prevState => !prevState)}
              >
                {t('COMPONENTS.DESCRIPTION.SEE_MORE')}
              </button>
            </div>
            <div className="hidden sm:block">
              {!!description && (
                <div
                  className={classNames({
                    'mb-8': !!speakers
                  })}
                >
                  <About description={description} />
                </div>
              )}
              {!!speakers && <Speakers speakers={speakers} />}
            </div>
          </>
        </Card>
      )}

      {isDescriptionOpen && (
        <section className="mt-2 block w-full sm:hidden">
          <header className="flex justify-between rounded-t-lg bg-primary px-4 py-2 text-white">
            <span>Ver mais</span>
            <button onClick={() => setIsDescriptionOpen(false)} type="button">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </header>

          <section className="absolute h-1/2 overflow-auto bg-white">
            <div className="p-4 pb-8">
              <h1 className="mb-3 text-sm font-medium text-gray-600">{title}</h1>
              {!!description && (
                <div
                  className={classNames({
                    'mb-8': !!speakers
                  })}
                >
                  <About description={description} />
                </div>
              )}
              {!!speakers && <Speakers speakers={speakers} />}
            </div>
          </section>
        </section>
      )}
    </>
  );
};
