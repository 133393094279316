import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { useLayout } from '../hooks';
import { GoogleRecaptchaTerms } from './google-recaptcha-terms';

export const Footer = () => {
  const { headerProps } = useLayout();

  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer>
      <div className="flex flex-col justify-between gap-14 px-4 pt-12 pb-40 sm:flex-row  sm:px-6 lg:px-8">
        <div className="flex w-full flex-col gap-3 self-end text-center sm:text-start lg:w-auto">
          <p className="text-gray-500">Organizadores</p>
          <Link className="text-secondary underline" to="/login">
            Ir para área do organizador
          </Link>
        </div>
        <div>
          <div className="mb-4 hidden md:flex md:justify-end" />
          <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
            <div className="hidden gap-6 text-sm font-medium text-zinc-800 dark:text-zinc-200 lg:flex">
              {headerProps.navigation
                ? headerProps.navigation.footer.map(item => (
                    <div key={item.id}>
                      {item.linkTo ? (
                        <Link
                          to={item.linkTo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cursor-pointer text-base text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <ScrollLink
                          to={item.id}
                          smooth
                          className="cursor-pointer text-base text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </ScrollLink>
                      )}
                    </div>
                  ))
                : null}
            </div>
            <p className="text-sm text-zinc-400 dark:text-zinc-500">&copy; {year} Linka Eventos.</p>
          </div>
          <div className="mt-8 flex text-center md:justify-end">
            <p className="text-sm text-zinc-300">
              <GoogleRecaptchaTerms />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
