import { Fragment, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useLayout } from '../../hooks';
import { SidebarProvider } from './sidebar-context';
import { SidebarNavigation } from './navigation';
import { Alerts, SpinnerLoader, UserDropdown } from '../';
import LogoLinka from '../../assets/svg/logo-linka.svg';

export const Sidebar = () => {
  const { setSidebarOpen, sidebarOpen } = useLayout();

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600/75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex shrink-0 items-center px-4">
                  <img className="h-8 w-auto" src={LogoLinka} alt="Linka Eventos" />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="px-2">
                    <SidebarProvider>
                      <SidebarNavigation baseClass="group w-full flex items-center text-left px-2 py-2 leading-5 rounded-md" />
                    </SidebarProvider>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pt-5 lg:pb-4">
        <div className="flex shrink-0 items-center px-6">
          <img className="h-8 w-auto" src={LogoLinka} alt="Linka Eventos" />
        </div>
        <div className="mt-5 flex h-0 flex-1 flex-col overflow-y-auto pt-1">
          <UserDropdown className="relative inline-block px-3 text-left" />

          <nav className="mt-6 px-3">
            <Suspense fallback={<SpinnerLoader size="sm" />}>
              <ErrorBoundary
                fallback={
                  <Alerts
                    type="error"
                    message="Ops, um erro inesperado aconteceu no carregamento do menu de navegação. 
                    Entre em contato através do e-mail linka@pecege.com para nos informar o que aconteceu."
                  />
                }
              >
                <SidebarProvider>
                  <SidebarNavigation />
                </SidebarProvider>
              </ErrorBoundary>
            </Suspense>
          </nav>
        </div>
      </div>
    </>
  );
};
