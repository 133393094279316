import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

import { PhoneNumberType } from '../interfaces';
import { defaultCountryValue } from './countries';

interface FormatPhoneProps {
  phone: string | PhoneNumberType;
  country?: CountryCode;
}

export const formatPhone = ({ country, phone }: FormatPhoneProps) => {
  let fullNumber = '';
  try {
    if (typeof phone === 'object') {
      fullNumber = `${phone.countryCode}${phone.areaCode}${phone.number}`;
    } else {
      fullNumber = phone;
    }
    const phoneNumber = parsePhoneNumber(fullNumber, country ?? defaultCountryValue);
    if (phoneNumber.country === defaultCountryValue) {
      return phoneNumber.formatNational();
    }
    return phoneNumber.formatInternational();
  } catch (err) {
    return 'falha na formatação';
  }
};

export const toPurchasePhoneNumber = (phone: string) => {
  try {
    const phoneNumberInternational = parsePhoneNumber(`+${phone}`).formatInternational();
    const splitPhoneNumber = phoneNumberInternational.split(' ');
    const [countryCode, areaCode] = splitPhoneNumber;
    const number = phoneNumberInternational.replace(`${countryCode}`, '').replace(`${areaCode}`, '').replace(/\s/g, '');
    return {
      countryCode: countryCode.replace('+', ''),
      areaCode: areaCode,
      number: number
    };
  } catch (err) {
    console.log(err);
    return {} as PhoneNumberType;
  }
};
