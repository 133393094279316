/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox } from '../checkbox';

export const TermsAndConditions = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'CHECKOUT' });
  const hostUrl = process.env.REACT_APP_HOST_URL ?? '';

  return (
    <>
      <Checkbox
        id="termsAndConditions"
        label={
          <Trans
            i18nKey="CHECKOUT.FORM.ACCEPT_TERMS_POLICIES"
            components={{
              terms: (
                <a
                  className="underline hover:brightness-150"
                  href={`${hostUrl}/terms-of-use`}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              privacy: (
                <a
                  className="underline hover:brightness-150"
                  href={`${hostUrl}/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                />
              )
            }}
          />
        }
      />
      <Checkbox id="availableForMailing" label={`${t('FORM.AVAILABLE_FOR_MAILING')}`} />
    </>
  );
};
