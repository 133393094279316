import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

import { getCurrentLanguage, languagesFlags, changeLanguage } from '../../i18n';
import { ComponentTypeEnum, SuspenseMenu } from '../suspense-menu';

enum Languages {
  es = 'Español',
  en = 'English',
  pt = 'Português'
}

export const LanguageSelector = () => {
  const languages = languagesFlags;

  const getLanguageNameOption = (lang: keyof typeof Languages = 'pt') => Languages[lang];

  const [currentLanguageName, setCurrentLanguageName] = useState(
    getLanguageNameOption(getCurrentLanguage() as keyof typeof Languages)
  );

  const setNewLanguage = async (name: string) => {
    await changeLanguage(name);
    setCurrentLanguageName(getLanguageNameOption(getCurrentLanguage() as keyof typeof Languages));
  };

  const menuItems = {
    className:
      'absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
    items: languages.map(lang => ({
      key: `lang-seletor-${lang.flag}`,
      componentType: ComponentTypeEnum.BUTTON,
      component: (
        <>
          <img src={lang.flag} alt={lang.name} className="mr-3 h-5 w-5" />
          {getLanguageNameOption(lang.name as keyof typeof Languages)}
        </>
      ),
      onClick: () => setNewLanguage(lang.name),
      class: {
        base: 'group flex items-center w-full px-4 py-2 text-sm',
        active: 'bg-gray-100 text-gray-900',
        inactive: 'text-gray-700'
      }
    }))
  };

  const menuButton = {
    className:
      'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    component: (
      <>
        {currentLanguageName}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </>
    )
  };

  return <SuspenseMenu menuButton={menuButton} menuItems={menuItems} className="relative inline-block text-left" />;
};
