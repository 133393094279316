import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';

import { PrivateLayout, ModalLayout, PublicLayout, BroadcastLayout, NoRobotsLayout } from '../layout';
import { SpinnerLoader } from '../components';
import PrivateRoutes from './private';
import { ClaimsEnum } from '../utils';

import { CustomerInfo, EventSummary, Participants, PaymentMethods, OrderComplete } from '../pages/checkout/components';
import { PurchasesList, StagingPurchasesList } from '../pages/dashboard/components';
import { ParticipantDetail } from '../pages/participants/manage/components';

//#region rotas isoladas

const HomePage = React.lazy(() => import('../pages/home'));
const LoginPage = React.lazy(() => import('../pages/login'));
const ForgotPasswordPage = React.lazy(() => import('../pages/forgot-password'));
const LandingPage = React.lazy(() => import('../pages/landing-page'));
const NotFoundPage = React.lazy(() => import('../pages/not-found'));
const UnauthenticatedPage = React.lazy(() => import('../pages/unauthenticated'));
const AccessDeniedPage = React.lazy(() => import('../pages/access-denied'));
const CheckoutPage = React.lazy(() => import('../pages/checkout'));
const CheckoutLinkPage = React.lazy(() => import('../pages/checkout-link'));
const PrintTagPage = React.lazy(() => import('../pages/print-tag'));
const DashboardPage = React.lazy(() => import('../pages/dashboard'));
const CouponsPage = React.lazy(() => import('../pages/coupons'));
const VerifyImportPage = React.lazy(() => import('../pages/verify-import'));
const TermsOfUsePage = React.lazy(() => import('../pages/terms-and-conditions/terms-of-use'));
const PrivacyPolicyPage = React.lazy(() => import('../pages/terms-and-conditions/privacy-policy'));
const SignInTokenPage = React.lazy(() => import('../pages/signin-token'));

//#endregion

//#region rotas de participantes
const ParticipantsPage = React.lazy(() => import('../pages/participants'));
const ParticipantsManagePage = React.lazy(() => import('../pages/participants/manage'));
const ParticipantsTicketListPage = React.lazy(() => import('../pages/participants/new/tickets-list'));
const ParticipantsNewPage = React.lazy(() => import('../pages/participants/new'));
//#endregion

//#region rotas de certificados
const CertificatesPage = React.lazy(() => import('../pages/certificates'));
const CertificatesConfigListPage = React.lazy(() => import('../pages/certificates/config'));
const CertificatesConfigPage = React.lazy(() => import('../pages/certificates/config/form'));
const CertificatesIssuancePage = React.lazy(() => import('../pages/certificates/issuance'));
//#endregion

//#region rotas de modais
const ModalParticipantsImportPage = React.lazy(() => import('../pages/modal/participants/import'));
const ModalActivityPage = React.lazy(() => import('../pages/modal/activity'));
const ModalTransferPage = React.lazy(() => import('../pages/modal/transfer'));
const ModalRefundPage = React.lazy(() => import('../pages/modal/refund'));
const ModalTicketUpgradePage = React.lazy(() => import('../pages/modal/ticket-upgrade'));
const ModalCheckInPage = React.lazy(() => import('../pages/modal/check-in'));
const ModalDisabledCouponPage = React.lazy(() => import('../pages/modal/coupon/disable'));
const ModalFormCouponPage = React.lazy(() => import('../pages/modal/coupon/form'));
const ModalEnterBroadcast = React.lazy(() => import('../pages/modal/broadcast'));
//#endregion

//#region rotas de relatórios
const ReportsPage = React.lazy(() => import('../pages/reports'));
const ReportActivitiesPage = React.lazy(() => import('../pages/reports/report-activities'));
const ReportParticipantsPage = React.lazy(() => import('../pages/reports/report-purchases'));
const ReportAccessPage = React.lazy(() => import('../pages/reports/report-access'));
//#endregion

//#region rotas de transmissão
const PlayerPage = React.lazy(() => import('../pages/broadcast/player'));

//#endregion

const PageLoadFallback = () => (
  <div className="h-screen">
    <SpinnerLoader size="xl" position="center" />
  </div>
);

const PageViewLogger = () => {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_title: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};

const CustomRoutes = () => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <React.Suspense fallback={<PageLoadFallback />}>
      <Routes location={background || location}>
        {/* rotas publicas com layout padrao */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path=":eventId">
            <Route index element={<LandingPage />} />
            <Route path="access-denied" element={<AccessDeniedPage />} />
            <Route path="unauthenticated" element={<UnauthenticatedPage />} />
          </Route>
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="terms-of-use" element={<TermsOfUsePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="not-found" element={<NotFoundPage />} />
        </Route>

        <Route element={<NoRobotsLayout />}>
          <Route path="checkout" element={<CheckoutPage />}>
            <Route path=":purchaseId" element={<EventSummary />}>
              <Route index element={<Participants />} />
              <Route path="customer" element={<CustomerInfo />} />
              <Route path="payment-methods" element={<PaymentMethods />} />
              <Route path="complete" element={<OrderComplete />} />
            </Route>
          </Route>
          <Route path="checkout-link" element={<CheckoutLinkPage />} />
          <Route path="print-tag" element={<PrintTagPage />} />
          <Route path="signin-token" element={<SignInTokenPage />} />
        </Route>

        {/* rotas privadas para paginas de colaboração */}
        <Route path=":eventId" element={<PrivateRoutes claims={[ClaimsEnum.ADMIN, ClaimsEnum.COLLAB]} />}>
          <Route element={<PrivateLayout />}>
            <Route path="dashboard" element={<DashboardPage />}>
              <Route index element={<StagingPurchasesList />} />
              <Route path=":ticketId" element={<PurchasesList />} />
            </Route>
            <Route path="participants" element={<ParticipantsPage />}>
              <Route index element={<ParticipantsManagePage />} />
              <Route path="manage" element={<ParticipantsManagePage />}>
                <Route path=":participantId" element={<ParticipantDetail />} />
              </Route>
              <Route path="new">
                <Route index element={<ParticipantsTicketListPage />} />
                <Route path=":purchaseId" element={<ParticipantsNewPage />} />
              </Route>
            </Route>
            <Route path="certificates" element={<CertificatesPage />}>
              <Route index element={<CertificatesConfigListPage />} />
              <Route path="config" element={<CertificatesConfigPage />} />
              {/* TODO: rotas provisorias, config e issuance devem ter subrotas dependendo do design */}
              <Route path="issuance" element={<CertificatesIssuancePage />} />
            </Route>
            <Route path="verify-import" element={<VerifyImportPage />} />
            <Route path="coupons" element={<CouponsPage />} />
            <Route path="reports">
              <Route index element={<ReportsPage />} />
              <Route path="activities" element={<ReportActivitiesPage />} />
              <Route path="purchases" element={<ReportParticipantsPage />} />
              <Route path="access" element={<ReportAccessPage />} />
            </Route>
          </Route>
        </Route>

        {/* rotas privadas para paginas que necessitam autenticação do usuário sem necessidade de claims */}
        <Route path=":eventId" element={<PrivateRoutes />}>
          <Route path="broadcast" element={<BroadcastLayout />}>
            <Route index element={<PlayerPage />} />
            <Route path=":activityId" element={<PlayerPage />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="not-found" />} />
      </Routes>

      {/* modais */}
      {background && (
        <Routes>
          <Route path=":eventId/modal" element={<ModalLayout />}>
            <Route element={<PrivateRoutes claims={[ClaimsEnum.ADMIN, ClaimsEnum.COLLAB]} />}>
              <Route path="import" element={<ModalParticipantsImportPage />} />
              <Route path="transfer" element={<ModalTransferPage />} />
              <Route path="check-in" element={<ModalCheckInPage />} />
              <Route path="refund" element={<ModalRefundPage />} />
              <Route path="ticket-upgrade" element={<ModalTicketUpgradePage />} />
              <Route path="disable-coupon" element={<ModalDisabledCouponPage />} />
              <Route path="manage-coupon" element={<ModalFormCouponPage />} />
            </Route>
            <Route path="activities/:activityId" element={<ModalActivityPage />} />
            <Route path="broadcast/join" element={<ModalEnterBroadcast />} />
          </Route>
        </Routes>
      )}

      <PageViewLogger />
    </React.Suspense>
  );
};

export default CustomRoutes;
