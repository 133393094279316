import classNames from 'classnames';

import { formatMonetaryToLocale } from '../../../../../utils';
import { PurchaseTicketsType } from '../../../../../interfaces';
import { useTranslation } from 'react-i18next';
import { useCheckoutPage } from '../../../../../hooks';

type Props = {
  purchaseSummary: Array<PurchaseTicketsType>;
};

export const ItemsPurchaseSummary = ({ purchaseSummary }: Props) => {
  const { t } = useTranslation();
  const { purchaseTotalValue } = useCheckoutPage();

  const formatMonetary = (monetaryValue: number) =>
    formatMonetaryToLocale({ value: monetaryValue, lang: 'pt-BR', style: 'currency', currency: 'BRL' });

  const calculateDiscount = (discount: number, value: number, quantity: number) => (value * quantity * discount) / 100;

  const sumSubTotal = (quantity: number, value: number, discount?: number) => {
    if (discount) {
      return quantity * value - calculateDiscount(discount, value, quantity);
    }
    return quantity * value;
  };

  return (
    <>
      <tbody>
        {purchaseSummary.map(ticket => (
          <tr key={ticket.id} className="border-b border-gray-200">
            <td className="py-4 pl-2 pr-3 text-sm sm:pl-4 md:pl-1">
              <div className="font-medium text-gray-900">{ticket.title}</div>
              {/* Visualização Mobile */}
              <div className="mt-0.5 text-gray-500 sm:hidden">
                {t('CHECKOUT.STEPS.ORDER_SUMMARY.TABLE.HEADER.QUANTITY')} {ticket.quantity}
              </div>
            </td>
            <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{ticket.quantity}</td>
            <td className="py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{formatMonetary(ticket.value)}</td>
            <td
              className={classNames('py-4 text-right text-sm sm:table-cell ', {
                'text-green-500 px-0 sm:px-3': ticket.coupon,
                'text-gray-500 px-4 sm:px-3': !ticket.coupon
              })}
            >
              -{' '}
              {ticket.coupon &&
                formatMonetary(calculateDiscount(ticket.coupon.discount, ticket.value, ticket.quantity))}
            </td>
            <td className="py-4 pl-3 pr-2 text-right text-sm text-gray-500 sm:pr-6 md:pr-1">
              {formatMonetary(sumSubTotal(ticket.quantity, ticket.value, ticket.coupon?.discount))}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          {/* Visualização Desktop */}
          <th
            scope="row"
            colSpan={4}
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
          >
            Total
          </th>
          {/* Visualização Mobile */}
          <th
            scope="row"
            colSpan={3}
            className="pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:hidden"
          >
            Total
          </th>
          <td className="pr-2 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-1">
            {formatMonetary(purchaseTotalValue)}
          </td>
        </tr>
      </tfoot>
    </>
  );
};
