import { useContext } from 'react';
import { NotificationContext } from '../contexts';

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications deve ser usado dentro do NotificationsProvider.');
  }
  return context;
};
