import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { EnvBanner } from '../components';

export const NoRobotsLayout = () => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <>
      <EnvBanner />
      <Outlet />
    </>
  );
};
