import { useCheckoutPage } from '../../../../hooks';

export const EventHero: React.FC = () => {
  const { event } = useCheckoutPage();

  return (
    <img
      className="h-32 w-full object-cover md:h-48 md:w-48 md:rounded-md"
      src={event.heroImageUrl || process.env.REACT_APP_HERO_IMAGE_PLACEHOLDER}
      alt="Imagem do Evento"
    />
  );
};
