/* eslint-disable no-extra-boolean-cast */
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonWithIcon, SpinnerLoader, TermsAndConditions } from '../..';
import { ParticipantsInfoFormSchema, PurchaseParticipantValues } from '../form-validation';
import { PurchaseParticipantType } from '../../../interfaces';
import { ParticipantFields } from './participant-fields';

type ParticipantFormProps = {
  loading?: boolean;
  participants: Pick<PurchaseParticipantType, 'ticketId' | 'ticketTitle'>[];
  showMbaCoursesField?: boolean;
  onSubmit: (data: PurchaseParticipantValues) => void;
};

export const ParticipantForm = ({
  loading = false,
  participants,
  showMbaCoursesField = false,
  onSubmit
}: ParticipantFormProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'CHECKOUT' });
  const methods = useForm<PurchaseParticipantValues>({
    resolver: yupResolver(ParticipantsInfoFormSchema),
    defaultValues: {
      participants
    }
  });

  const { fields } = useFieldArray({
    name: 'participants',
    control: methods.control
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="divide-y divide-dashed divide-gray-300">
          {fields.map((field, index) => (
            <ParticipantFields
              key={field.id}
              index={index}
              showMbaCoursesField={showMbaCoursesField}
              loading={loading}
            />
          ))}
        </div>
        <div className="space-y-4 pb-8">
          <TermsAndConditions />
        </div>
        <div className="mt-4 w-full rounded-md bg-white">
          <ButtonWithIcon
            type="submit"
            className="w-full bg-primary"
            icon={loading ? SpinnerLoader : ArrowRightIcon}
            disabled={loading}
          >
            {loading ? t('BUTTONS.LOADING') : t('BUTTONS.CONTINUE')}
          </ButtonWithIcon>
        </div>
      </form>
    </FormProvider>
  );
};
