import { useCallback, useState } from 'react';
import {
  collection,
  collectionGroup,
  doc,
  FieldPath,
  orderBy,
  query,
  where,
  WhereFilterOp,
  limit,
  getCountFromServer,
  OrderByDirection,
  Query,
  DocumentData,
  startAfter
} from 'firebase/firestore';
import { httpsCallable, HttpsCallableOptions } from 'firebase/functions';
import {
  useFirestore,
  useFirestoreDocData,
  useFirestoreDocDataOnce,
  useFirestoreCollectionData,
  useFunctions,
  useFirestoreCollection
} from 'reactfire';
import { useTranslation } from 'react-i18next';

import { useNotifications } from './use-notifications';

export type ConstraintsListType = Array<{ fieldPath: string | FieldPath; opStr: WhereFilterOp; value: unknown }>;

export type OrderByType = {
  field: string;
  direction: OrderByDirection;
};

type GetCollectionDataType = {
  collectionName: string;
  path: string[];
  orderBy?: OrderByType;
  constraints?: ConstraintsListType;
  pageLimit?: number;
  idField?: string;
};
type GetCollectionGroupDataType = Omit<GetCollectionDataType, 'path'>;
type GetDocumentType = {
  collectionName: string;
  path: string[];
  idField?: string;
};
type FunctionsCallableType<T> = {
  functionName: string;
  options?: HttpsCallableOptions;
  callableParams: T;
  successMessage?: string;
  omitNotification?: boolean;
};

export const useGetCollectionQuery = (options: GetCollectionDataType) => {
  const { collectionName, path, constraints, pageLimit } = options;
  const firestore = useFirestore();
  let q = query(collection(firestore, collectionName, ...path));
  if (constraints) {
    q = query(q, ...constraints.map(({ fieldPath, opStr, value }) => where(fieldPath, opStr, value)));
  }
  if (options.orderBy) {
    const { direction, field } = options.orderBy;
    q = query(q, orderBy(field, direction));
  }
  if (pageLimit && pageLimit > 0) {
    q = query(q, limit(pageLimit));
  }
  return q;
};

/** Utilize esse método quando quiser consultar um documento e manter o listener sobre ele */
export const useGetDocument = <T>({ collectionName, path, idField }: GetDocumentType) => {
  const firestore = useFirestore();
  const docRef = doc(firestore, collectionName, ...path);
  const { status, data } = useFirestoreDocData(docRef, { idField: idField ?? 'id' });
  return { status, data: data as T };
};

/** Utilize esse método quando quiser consultar um documento sem manter o listener sobre ele */
export const useGetDocumentOnce = <T>({ collectionName, path, idField }: GetDocumentType) => {
  const firestore = useFirestore();
  const docRef = doc(firestore, collectionName, ...path);
  const { status, data } = useFirestoreDocDataOnce(docRef, { idField: idField ?? 'id' });
  return { status, data: data as T };
};

/** Utilize esse método quando quiser consultar vários documentos utilizando o caminho completo para chegar até eles */
export const useGetCollectionData = <T>(options: GetCollectionDataType) => {
  const q = useGetCollectionQuery(options);
  const { status, data } = useFirestoreCollectionData(q, { idField: options.idField ?? 'id' });
  return { status, data: data as T[] };
};

/** Utilize esse método quando quiser consultar vários documentos utilizando somente o nome da coleção para chegar até eles */
export const useGetCollectionGroupData = <T>({ collectionName, idField, constraints }: GetCollectionGroupDataType) => {
  const firestore = useFirestore();
  let q = query(collectionGroup(firestore, collectionName));
  if (constraints) {
    q = query(
      collectionGroup(firestore, collectionName),
      ...constraints.map(({ fieldPath, opStr, value }) => where(fieldPath, opStr, value))
    );
  }
  const { status, data } = useFirestoreCollectionData(q, { idField: idField ?? 'id' });
  return { status, data: data as T[] };
};

/** Utilize esse método quando quiser obter uma contagem de documentos em uma coleção */
export const useGetCollectionQueryCounter = async ({ collectionName, constraints, path }: GetCollectionDataType) => {
  const firestore = useFirestore();
  let q = query(collection(firestore, collectionName, ...path));
  if (constraints) {
    q = query(
      collectionGroup(firestore, collectionName),
      ...constraints.map(({ fieldPath, opStr, value }) => where(fieldPath, opStr, value))
    );
  }
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const useFirebaseFunctions = () => {
  const functions = useFunctions();
  const { errorToast, successToast } = useNotifications();
  const { t } = useTranslation();
  /**
   * Aciona uma função callable. Onde T = interface de request e R = interface de response
   * @param functionName - nome da função a ser chamada.
   * @param options - opções extras. Consultar opções em HttpsCallableOptions
   * @public
   */
  const onCall = useCallback(
    async <T = unknown, R = unknown>({
      functionName,
      options,
      callableParams,
      successMessage,
      omitNotification
    }: FunctionsCallableType<T>) => {
      try {
        const { data } = await httpsCallable<T, R>(functions, functionName, options)(callableParams);
        if (successMessage && successMessage.length > 0) successToast(t(successMessage));
        return data;
      } catch (error) {
        if (omitNotification) return undefined;
        const { message } = <{ message: string; code: string }>error;
        errorToast(t(message));
        return undefined;
      }
    },
    [errorToast, functions, successToast, t]
  );

  return { onCall };
};

export const usePaginatedCollectionData = <T>(options: { query: Query<DocumentData> }) => {
  const [stateQuery, setStateQuery] = useState(query(options.query));
  const { data: qSnapshot, status } = useFirestoreCollection(stateQuery, {});

  const next = async () => {
    if (qSnapshot.empty) {
      return;
    }
    const lastDocument = qSnapshot.docs[qSnapshot.size - 1];
    console.log(lastDocument.id);
    const tempQuery = query(options.query, startAfter(lastDocument));
    console.log(tempQuery);
    setStateQuery(tempQuery);
  };

  let data: T[] = [];
  if (!qSnapshot.empty) {
    data = qSnapshot.docs.map(qDocSnapshot => qDocSnapshot.data() as T);
  }
  return { next, data, status };
};
