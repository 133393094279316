/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

type CheckboxProps = Omit<React.HTMLProps<HTMLElement>, 'label' | 'id'> & {
  id: string;
  label: string | JSX.Element;
};

export const Checkbox = ({ id, label, disabled = false }: CheckboxProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const defaultInputClass = `h-4 w-4 rounded border-gray-300`;
  const defaultLabelClass = `font-medium`;

  const inputArrayError = useMemo(() => {
    const [arrayPropName] = id.split('.');
    const errorsArrayField = errors[arrayPropName];
    return errorsArrayField;
  }, [id, errors]);

  const inputClass = useMemo(() => {
    const error = errors[id];
    const classes = classNames(defaultInputClass, {
      'border-red-600 text-red-600 focus:ring-red-600': !!error || inputArrayError,
      'text-blue-600 focus:ring-blue-600': !error,
      'cursor-not-allowed disabled:opacity-60': disabled
    });
    return classes;
  }, [defaultInputClass, disabled, errors, id, inputArrayError]);

  const labelClass = useMemo(() => {
    const error = errors[id];
    const classes = classNames(defaultLabelClass, {
      'text-red-600': !!error || inputArrayError,
      'text-gray-700': !error,
      'opacity-60 cursor-not-allowed': disabled
    });
    return classes;
  }, [defaultLabelClass, disabled, errors, id, inputArrayError]);

  return (
    <fieldset>
      <legend className="sr-only">{label}</legend>
      <div className="space-y-5">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              type="checkbox"
              {...register(id)}
              className={inputClass}
              id={id}
              aria-describedby={`${id}-description`}
              disabled={disabled}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={id} className={labelClass}>
              {label}
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  );
};
