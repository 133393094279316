import { LockClosedIcon } from '@heroicons/react/24/outline';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  securityInfo: {
    bank: string;
    companyBillingDocument: string;
    payee: string;
    supportEmail: string;
  };
};

export const SecurityInfo: React.FC<Props> = ({ securityInfo }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <hr />
      <div className="flex items-center gap-2">
        <span>
          <LockClosedIcon width="35" height="40" />
        </span>
        <span className="text-3xl font-bold text-neutral-800 sm:text-2xl sm:tracking-tight lg:text-3xl">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.SECURITY_INFO.TITLE')}
        </span>
      </div>
      <div>
        <Trans
          i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.SECURITY_INFO.DESCRIPTION"
          components={{ b: <b /> }}
          values={{
            supportEmail: securityInfo.supportEmail
          }}
        />
      </div>
      <div className="flex-row text-center sm:flex sm:justify-between">
        <div className="flex flex-col">
          <span className="font-semibold text-gray-500">
            {t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.SECURITY_INFO.BANK')}
          </span>
          <span>{securityInfo.bank}</span>
        </div>
        <div className="flex flex-col ">
          <span className="font-semibold text-gray-500">
            {t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.SECURITY_INFO.BENEFICIARY')}
          </span>
          <span>{securityInfo.payee}</span>
        </div>
        <div className="flex flex-col ">
          <span className="font-semibold text-gray-500">
            {t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.SECURITY_INFO.CNPJ')}
          </span>
          <span>{securityInfo.companyBillingDocument}</span>
        </div>
      </div>
    </>
  );
};
