import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputLabel, LandingPageCard } from '../../../../components';
import { useCheckoutPage } from '../../../../hooks';

export const ReceiveReceipts: React.FC = () => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const { stagingPurchase } = useCheckoutPage();

  const {
    formState: { isSubmitting }
  } = methods;

  const defaultEmail = useMemo(() => {
    if (stagingPurchase.customer && stagingPurchase.customer.email) {
      return stagingPurchase.customer.email;
    }
    return '';
  }, [stagingPurchase.customer]);

  return (
    <LandingPageCard title={t('CHECKOUT.STEPS.RECEIPTS.TITLE')}>
      <div className="w-full justify-between gap-12 sm:flex sm:pb-8">
        <div className="w-full sm:h-10">
          <InputLabel
            name="sendPurchaseInfoTo"
            id="sendPurchaseInfoTo"
            label={t('CHECKOUT.STEPS.RECEIPTS.EMAIL').toString()}
            placeholder={t('CHECKOUT.STEPS.RECEIPTS.PLACEHOLDER_EMAIL').toString()}
            type="email"
            defaultValue={defaultEmail}
            maxLength={50}
            disabled={isSubmitting}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0 sm:h-10">
          <InputLabel
            name="confirmSendPurchaseInfoTo"
            id="confirmSendPurchaseInfoTo"
            label={t('CHECKOUT.STEPS.RECEIPTS.CONFIRM_EMAIL').toString()}
            placeholder={t('CHECKOUT.STEPS.RECEIPTS.CONFIRM_EMAIL').toString()}
            type="email"
            defaultValue={defaultEmail}
            maxLength={50}
            disabled={isSubmitting}
          />
        </div>
      </div>

      <p className="text-sm text-gray-500">{t('CHECKOUT.STEPS.RECEIPTS.WARNING')}</p>
    </LandingPageCard>
  );
};
