import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { ButtonWithIcon, SpinnerLoader } from '../../../../../components';
import { VerifyCouponActionEnum } from '../../../../../interfaces';
import { useCheckoutPage } from '../../../../../hooks';

type Props = {
  appliedCouponId: string;
};

export const RemoveCoupon = ({ appliedCouponId }: Props) => {
  const { t } = useTranslation();
  const [isRemoveCoupon, setIsRemoveCoupon] = useState(false);
  const { handleCouponRequest, stagingPurchase } = useCheckoutPage();

  const handleRemoveCoupon = async () => {
    setIsRemoveCoupon(true);
    if (!appliedCouponId) {
      return;
    }
    const requestVerify = {
      eventId: stagingPurchase.eventId,
      couponId: appliedCouponId,
      action: VerifyCouponActionEnum.REMOVE,
      purchaseId: stagingPurchase.purchaseId
    };
    await handleCouponRequest(requestVerify);
    setIsRemoveCoupon(false);
  };

  return (
    <div className="flex justify-center gap-5">
      <div className="flex items-center border-b-2 border-solid border-gray-600 font-normal text-gray-600 shadow-sm">
        {t('CHECKOUT.STEPS.ORDER_SUMMARY.DISCOUNT_COUPON.MESSAGE.APPLIED', { coupon: appliedCouponId })}
      </div>
      <ButtonWithIcon
        type="button"
        onClick={handleRemoveCoupon}
        icon={TrashIcon}
        changePositionIconLeft
        customStylesButton="px-0 text-primary text-sm sm:text-base h-full w-[9rem]"
        disabled={isRemoveCoupon}
      >
        {isRemoveCoupon ? <SpinnerLoader size="sm" /> : t('CHECKOUT.STEPS.ORDER_SUMMARY.DISCOUNT_COUPON.BUTTON.REMOVE')}
      </ButtonWithIcon>
    </div>
  );
};
