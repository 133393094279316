type Step = {
  stepId: string;
  description: string;
  title: string;
};

type ProgressBarProps = {
  steps: Step[];
  currentStep: number;
};

export const ProgressBar = ({ steps, currentStep }: ProgressBarProps) => (
  <div>
    <h4 className="sr-only">Status</h4>
    <p className="text-sm font-medium text-gray-900">{steps[currentStep].description}...</p>
    <div className="mt-6" aria-hidden="true">
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-2 rounded-full bg-primary"
          style={{ width: `${!currentStep ? 15 : (currentStep * 100) / (steps.length - 1)}%` }}
        />
      </div>
      <div className="mt-6 hidden justify-between text-sm font-medium text-gray-600 sm:flex">
        {steps.map(({ title }, index) => (
          <div key={title} className={`shrink basis-52 text-center ${currentStep === index && 'text-primary'}`}>
            {title}
          </div>
        ))}
      </div>
    </div>
  </div>
);
