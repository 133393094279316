export const defaultCourseValue = '0';

export const courses = [
  {
    name: 'Selecione o curso',
    value: '0'
  },
  {
    name: 'MBA em Gestão de Projetos ',
    value: 'GPRO'
  },
  {
    name: 'MBA em Agronegócios',
    value: 'ED'
  },
  {
    name: 'MBA em Gestão Tributária',
    value: 'GT'
  },
  {
    name: 'MBA em Marketing',
    value: 'MKT'
  },
  {
    name: 'MBA em Data Science e Analytics',
    value: 'DSA'
  },
  {
    name: 'MBA em Gestão de Pessoas',
    value: 'GP'
  },
  {
    name: 'MBA em Finanças e Controladoria',
    value: 'FC'
  },
  {
    name: 'MBA em Varejo Físico e Online',
    value: 'VFO'
  },
  {
    name: 'MBA em Gestão de Negócios',
    value: 'GN'
  },
  {
    name: 'MBA em Gestão de Vendas',
    value: 'GV'
  },
  {
    name: 'MBA em Gestão Escolar',
    value: 'ESC'
  },
  {
    name: 'MBA em Digital Business',
    value: 'DB'
  },
  {
    name: 'MBA em Engenharia de Software',
    value: 'ENGS'
  },
  {
    name: 'Economia, Investimentos e Banking',
    value: 'EIB'
  },
  {
    name: 'MBA em Compliance & ESG',
    value: 'MESG'
  },
  {
    name: 'Especialização em Neurociência e Aprendizagem na Educação',
    value: 'ENAE'
  }
];
