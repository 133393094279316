import { PecegePayDocumentType } from '../interfaces';

// Valores divididos em sessões para melhor feedback da formatação

/* 
  Essa repetição das funções é uma feature do typescript chamada "overload" 
  que permite que a função tenha mais de um tipo de retorno e 
  parâmetro de entrada (https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads)
  talvez dê pra melhorar isso com generics.
*/

type Parameters = string | React.FormEvent<HTMLInputElement>;
type Return<T> = T extends string
  ? string
  : T extends React.FormEvent<HTMLInputElement>
  ? React.FormEvent<HTMLInputElement>
  : never;

export const cpf = <T extends Parameters>(e: T): Return<T> => {
  const maxLengthCpf = 14;

  if (e instanceof Object) {
    e.currentTarget.maxLength = maxLengthCpf;
    const { value } = e.currentTarget;
    if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
      const valueInitial = value.replace(/\D/g, '');
      const firstSession = valueInitial.replace(/(\d{3})(\d)/, '$1.$2');
      const secondSession = firstSession.replace(/(\d{3})(\d)/, '$1.$2');
      const formattedValue = secondSession.replace(/(\d{3})(\d{1})$/, '$1-$2');
      e.currentTarget.value = formattedValue;
    }

    /* evita ser digitado mais um número quando o CPF é inserido automaticamente com o tipo string */
    if (value.length > maxLengthCpf) {
      e.currentTarget.value = value.substring(0, value.length - 1);
    }

    return e as unknown as Return<T>;
  }

  return e.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4') as Return<T>;
};

export const cnpj = <T extends Parameters>(e: T): Return<T> => {
  const maxLengthCnpj = 18;

  if (e instanceof Object) {
    e.currentTarget.maxLength = maxLengthCnpj;
    const { value } = e.currentTarget;
    if (!value.match(/^(\d{2}).(\d{3})(\d{3}).(\d{4})-(\d{2})$/)) {
      const valueInitial = value.replace(/\D/g, '');
      const firstSession = valueInitial.replace(/(\d{2})(\d)/, '$1.$2');
      const secondSession = firstSession.replace(/(\d{3})(\d)/, '$1.$2');
      const thirdSession = secondSession.replace(/(\d{3})(\d)/, '$1/$2');
      const formattedValue = thirdSession.replace(/(\d{4})(\d{2})$/, '$1-$2');
      e.currentTarget.value = formattedValue;
    }

    /* evita ser digitado mais um número quando o CNPJ é inserido automaticamente com o tipo string */
    if (value.length > maxLengthCnpj) {
      e.currentTarget.value = value.substring(0, value.length - 1);
    }

    return e as unknown as Return<T>;
  }

  return e.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5') as Return<T>;
};

export const cep = (e: React.FormEvent<HTMLInputElement> | string) => {
  const maxLengthCep = 9;
  if (e instanceof Object) {
    e.currentTarget.maxLength = maxLengthCep;
    const { value } = e.currentTarget;
    const valueInitial = value.replace(/\D/g, '');
    const formattedValue = valueInitial.replace(/^(\d{5})(\d)/, '$1-$2');
    e.currentTarget.value = formattedValue;

    if (value.length > maxLengthCep) {
      e.currentTarget.value = value.substring(0, value.length - 1);
    }
    return e;
  }
  return e.replace(/^(\d{5})(\d)/, '$1-$2');
};

export const creditCardNumberFormatter = (e: React.FormEvent<HTMLInputElement>) => {
  const maxLengthNumber = 19;

  e.currentTarget.maxLength = maxLengthNumber;
  const { value } = e.currentTarget;
  const valueInitial = value.replace(/\D/g, '');
  const firstSession = valueInitial.replace(/^(\d{4})(\d)/g, '$1 $2');
  const secondSession = firstSession.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
  const formattedValue = secondSession.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
  e.currentTarget.value = formattedValue;
  return e;
};

export const inputDocumentMask = (
  value: string | React.FormEvent<HTMLInputElement>,
  selectedDocumentType: string | undefined
) => {
  if (!selectedDocumentType) {
    return cpf(value);
  }

  switch (selectedDocumentType) {
    case PecegePayDocumentType.CPF:
      return cpf(value);

    case PecegePayDocumentType.CNPJ:
      return cnpj(value);

    default: {
      if (value instanceof Object) {
        value.currentTarget.maxLength = 30;
      }

      return value;
    }
  }
};

export const keepOnlyNumbers = (value: string) => value.replace(/[^0-9]/g, '');

export const handleRemoveMaskDocument = (documentType: string, document: string) =>
  documentType !== PecegePayDocumentType.PASSPORT ? keepOnlyNumbers(document) : document;
