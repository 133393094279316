import { useEffect } from 'react';
import { getCurrentLanguagePrefix } from '../i18n';

const currentLanguagePrefix = getCurrentLanguagePrefix();
const getWidgetByLang = (): string => {
  const widgets = process.env.REACT_APP_TAWK_TO_WIDGETS as string;
  if (!widgets || widgets.length < 0) {
    return 'default';
  }
  const parserdWidgets = JSON.parse(widgets);
  return parserdWidgets[currentLanguagePrefix];
};

export const useTawkTo = () => {
  // HACK: não tem como atualizar o script do tawk.to modificando o seletor de linguagem.
  //  teria que atualizar a pagina pois, não tem como limpar o script já carregado da memoria
  useEffect(() => {
    const headElement = document.getElementsByTagName('head')[0];
    const remoteScript = document.createElement('script');

    remoteScript.async = true;
    remoteScript.src = `${process.env.REACT_APP_TAWK_TO_URL}/${getWidgetByLang()}`;
    remoteScript.setAttribute('crossorigin', '*');

    headElement.appendChild(remoteScript);
  }, []);
};
