import { Outlet } from 'react-router-dom';
import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';

import { LayoutProvider } from '../contexts';
import { useLayout } from '../hooks';
import { EnvBanner, PrivateHeader, Sidebar, UserDropdown } from '../components';

const LayoutContent = () => {
  const { setSidebarOpen } = useLayout();
  return (
    <div className="min-h-full">
      <Sidebar />
      <div className="flex flex-col lg:pl-64">
        <div className="sticky top-0 z-10 flex h-16 shrink-0 border-b border-gray-200 bg-white lg:hidden">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
            {/* essa div mantem o alinhamento */}
            <div className="flex flex-1"></div>
            <div className="flex items-center">
              <UserDropdown isMobile className="relative ml-3" />
            </div>
          </div>
        </div>
        <main className="flex-1">
          <EnvBanner />
          <PrivateHeader />
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export const PrivateLayout = () => (
  <div className="min-h-full">
    <LayoutProvider>
      <LayoutContent />
    </LayoutProvider>
  </div>
);
