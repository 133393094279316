import { useTranslation } from 'react-i18next';
import { ButtonWithIcon, SpinnerLoader } from '..';

type SubscribeButtonProps = {
  formatCurrency: (monetaryValue: number) => string;
  sumSelectedTicketsValues: number;
  purchaseCreateLoading: boolean;
  ticketsCount: number;
};

export const SubscribeButton = ({
  formatCurrency,
  purchaseCreateLoading,
  sumSelectedTicketsValues,
  ticketsCount
}: SubscribeButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full justify-center px-8 pb-8 sm:block">
      <ButtonWithIcon
        disabled={purchaseCreateLoading || ticketsCount === 0}
        type="submit"
        className="w-auto bg-primary sm:w-1/2 md:w-1/4"
      >
        {t('LANDING_PAGE.BUTTONS.SUBSCRIBE')} - {formatCurrency(sumSelectedTicketsValues)}
        {purchaseCreateLoading && <SpinnerLoader className="ml-4" />}
      </ButtonWithIcon>
    </div>
  );
};
