import { addYears, eachYearOfInterval, getYear } from 'date-fns';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { InputLabel, SelectLabel } from '../../../../../components';
import { creditCardNumberFormatter, formatMonetaryToLocale, months } from '../../../../../utils';
import { useCheckoutPage, usePaymentMethods } from '../../../../../hooks';

const CreditCardInfo: React.FC = () => {
  const { t } = useTranslation();
  const { creditCardInstallments } = usePaymentMethods();
  const { purchaseTotalValue } = useCheckoutPage();
  const formatMonetary = (monetaryValue: number) =>
    formatMonetaryToLocale({ value: monetaryValue, lang: 'pt-BR', style: 'currency', currency: 'BRL' });

  const today = new Date();
  const yearsToAdd = 15;
  const addedYears = addYears(today, yearsToAdd);
  const yearValidityInterval = eachYearOfInterval({
    start: today,
    end: addedYears
  });
  const getOnlyYears = yearValidityInterval.map(year => getYear(year));
  const yearsValidityOptions = Object.entries(getOnlyYears).map(yearValue => ({
    name: yearValue[1],
    value: yearValue[1]
  }));

  const numberOfInstallments = Array.from(Array(creditCardInstallments.installments).keys());
  const installmentsOptions = numberOfInstallments.map(installmentIdx => ({
    name: `${installmentIdx + 1} x ${formatMonetary(purchaseTotalValue / (installmentIdx + 1))} = ${formatMonetary(
      purchaseTotalValue
    )} `,
    value: installmentIdx + 1
  }));

  const [firstYear] = getOnlyYears;
  const [firstMonth] = months;
  const [firstInstallment] = installmentsOptions;

  const methods = useFormContext();
  const {
    setFocus,
    formState: { isSubmitting, errors }
  } = methods;

  useEffect(() => {
    const [currentError] = Object.keys(errors);
    if (currentError) setFocus(currentError);
  }, [errors, setFocus]);

  return (
    <div className="w-full gap-8 pb-4 sm:flex sm:flex-col">
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 mb-8 w-full sm:mt-0 sm:h-10">
          <InputLabel
            name="number"
            label={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.CARD_NUMBER').toString()}
            placeholder="**** **** **** ****"
            type="text"
            onChangeValue={creditCardNumberFormatter}
            disabled={isSubmitting}
          />
        </div>
        <div className="mt-4 mb-8 w-full sm:mt-0 sm:h-10">
          <InputLabel
            name="holderName"
            label={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.CARD_HOLDER').toString()}
            type="text"
            maxLength={50}
            disabled={isSubmitting}
          />
        </div>
      </div>
      <div className="w-full justify-between gap-12 sm:flex">
        <div className="mt-4 w-full sm:mt-0 sm:h-10">
          <SelectLabel
            name="expMonth"
            label={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.EXPIRATION_MONTH')}
            defaultValue={firstMonth.value}
            options={months}
            disabled={isSubmitting}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0 sm:h-10">
          <SelectLabel
            name="expYear"
            label={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.EXPIRATION_YEAR')}
            defaultValue={firstYear}
            options={yearsValidityOptions}
            disabled={isSubmitting}
          />
        </div>
        <div className="mt-4 w-full sm:mt-0 sm:h-10">
          <InputLabel
            name="cvv"
            label={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.CARD_CVV').toString()}
            type="text"
            placeholder={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.CARD_CVV').toString()}
            maxLength={4}
            disabled={isSubmitting}
          />
        </div>
      </div>
      <div className="mt-4 w-full sm:h-10">
        <SelectLabel
          name="installments"
          label={t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.CARD_INSTALLMENTS')}
          defaultValue={firstInstallment.value}
          options={installmentsOptions}
          disabled={isSubmitting}
        />
        <small>{t('CHECKOUT.STEPS.PAYMENT_METHOD.CREDIT_CARD.INFO_INSTALLMENTS')}</small>
      </div>
    </div>
  );
};

export default CreditCardInfo;
