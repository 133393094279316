/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { SlideOverPanel } from '../components';
import { LandingPageNavigationType } from '../interfaces';

interface ContextProps {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setHideBodyOverflow: Dispatch<SetStateAction<boolean>>;
  slideOverOpen: boolean;
  closeSlideOver: () => void;
  openSlideOver: () => void;
  headerProps: HeaderProps;
  setHeaderProps: Dispatch<SetStateAction<HeaderProps>>;
  slideOverProps: SlideOverProps;
  setSlideOverProps: Dispatch<SetStateAction<SlideOverProps>>;
}

type HeaderActionButtonProps = { title: string; to: string; state: { [key: string]: any } };
type HeaderProps = {
  title: string;
  primaryActionButton?: HeaderActionButtonProps;
  secondaryActionButton?: HeaderActionButtonProps;
  navigation?: LandingPageNavigationType;
  showAuthentication?: boolean;
  showFooter?: boolean;
};
type SlideOverProps = {
  title?: string;
  details?: string;
  widePanel?: boolean;
  component: JSX.Element;
};

export const LayoutContext = createContext({} as ContextProps);

const defaultSlideOverProps = {
  component: <></>,
  widePanel: true
};

export const LayoutProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [hideBodyOverflow, setHideBodyOverflow] = useState(false);
  const [headerProps, setHeaderProps] = useState<HeaderProps>({
    title: 'Dashboard',
    showAuthentication: false,
    showFooter: true
  });

  const [slideOverProps, setSlideOverProps] = useState<SlideOverProps>(defaultSlideOverProps);

  const openSlideOver = useCallback(() => setSlideOverOpen(true), []);
  const closeSlideOver = useCallback(() => {
    setSlideOverOpen(false);
    setSlideOverProps(defaultSlideOverProps);
  }, []);

  useEffect(() => {
    let title = 'Linka Eventos';
    if (headerProps && headerProps.title) {
      title = headerProps.title;
    }
    document.title = title;
  }, [headerProps]);

  useEffect(() => {
    if (hideBodyOverflow) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => document.body.classList.remove('overflow-hidden');
  }, [hideBodyOverflow]);

  return (
    <LayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        setHideBodyOverflow,
        slideOverOpen,
        headerProps,
        setHeaderProps,
        slideOverProps,
        setSlideOverProps,
        openSlideOver,
        closeSlideOver
      }}
    >
      {children}
      <SlideOverPanel />
    </LayoutContext.Provider>
  );
};
