import Logo from '../../../../assets/svg/logo-linka.svg';
import { LanguageSelector } from '../../../../components';

export const Header = () => (
  <header className="relative flex h-16 w-full justify-between px-6 sm:justify-center">
    <img src={Logo} alt="Logo do Linka" className="w-32" />
    <div className="right-10 flex h-full items-center justify-center sm:absolute">
      <LanguageSelector />
    </div>
  </header>
);
