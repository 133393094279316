import { useContext } from 'react';

import { DebounceInputCombobox, Toggle } from '../../../../../components';
import { ParticipantsContext } from '../../../participants-context';

export const FormSearch = () => {
  const { setInputSearchValue, onlyRefundAndTransferToggle, setOnlyRefundAndTransferToggle } =
    useContext(ParticipantsContext);

  return (
    <>
      <DebounceInputCombobox debounceLimit={500} onSelectedValue={setInputSearchValue} />
      <div className="mt-2">
        <Toggle
          setEnabled={setOnlyRefundAndTransferToggle}
          enabled={onlyRefundAndTransferToggle}
          label="Ver somente estornados e transferidos"
        />
      </div>
    </>
  );
};
