import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DefaultPicture from '../../../assets/svg/no-profile-picture.svg';
import { getCurrentLanguage } from '../../../i18n';
import { TranslateType } from '../../../interfaces';

type Props = {
  speaker: {
    photoUrl: string;
    name: string;
    curriculum: TranslateType;
  };
};

export const SpeakerCard: React.FC<Props> = ({ speaker }: Props) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState(false);
  const lang = getCurrentLanguage();

  return (
    <div className="relative flex items-start space-x-3 rounded-lg bg-white py-4">
      <img
        className="h-10 w-10 self-start rounded-full object-cover"
        src={speaker.photoUrl ?? DefaultPicture}
        alt={speaker.name}
      />
      <div className="flex-1">
        <p className="mb-2 text-sm font-medium text-gray-900">{speaker.name}</p>
        <p
          className={classNames('text-sm text-gray-500 text-left', {
            'line-clamp-2': !seeMore
          })}
        >
          {speaker.curriculum?.[lang] ?? speaker.curriculum.pt}
        </p>
        {speaker.curriculum && (
          <button
            type="button"
            onClick={() => setSeeMore(state => !state)}
            className="float-right mt-2 cursor-pointer font-medium text-primary hover:brightness-50 sm:font-normal"
          >
            {seeMore ? t('LANDING_PAGE.ACTIVITIES.MODAL.SEE_LESS') : t('LANDING_PAGE.ACTIVITIES.MODAL.SEE_MORE')}
          </button>
        )}
      </div>
    </div>
  );
};
