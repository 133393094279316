import { LockClosedIcon } from '@heroicons/react/24/outline';
import { Trans, useTranslation } from 'react-i18next';
import { usePaymentMethods } from '../../../../../../hooks';

const SecurityInfo: React.FC = () => {
  const { t } = useTranslation();
  const { securityInfo } = usePaymentMethods();

  return (
    <>
      <div className="flex items-center gap-2">
        <span>
          <LockClosedIcon width="35" height="40" />
        </span>
        <span className="text-3xl font-bold text-neutral-800 sm:text-2xl sm:tracking-tight lg:text-3xl">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.SECURITY_INFO.TITLE')}
        </span>
      </div>
      <div className=" text-center text-gray-500">
        <Trans
          i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.SECURITY_INFO.DESCRIPTION"
          values={{
            securityInfoPayee: securityInfo.payee,
            supportEmail: securityInfo.supportEmail
          }}
        />
      </div>
    </>
  );
};

export default SecurityInfo;
