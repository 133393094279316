import { CalendarDaysIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { HorizontalTabWithUnderline } from '../../../components';
import { BroadcastSchedule } from './schedule';
import { BroadcastChat } from './chat';
import { useState } from 'react';

export const BroadcastSidebar = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'BROADCAST_PAGE' });
  // define programação como visão principal
  const [selectedTabIndex, setSelectedTabIndex] = useState(1);

  return (
    <HorizontalTabWithUnderline
      selectedIndex={selectedTabIndex} // força exibição da tab programação
      setSelectedTabIndex={setSelectedTabIndex}
      tabList={[
        {
          name: t('COMPONENTS.SIDEBAR.CHAT'),
          disabled: false,
          icon: <ChatBubbleLeftRightIcon className="h-6 w-6" />
        },
        {
          name: t('COMPONENTS.SIDEBAR.SCHEDULE'),
          disabled: false,
          icon: <CalendarDaysIcon className="h-6 w-6" />
        }
      ]}
      tabPanels={[
        { index: 'chat', panel: <BroadcastChat /> },
        {
          index: 'conteudo',
          panel: <BroadcastSchedule />
        }
      ]}
    />
  );
};
