import { useEffect, useState } from 'react';
import { indigo } from 'tailwindcss/colors';
import { differenceInCalendarDays, format } from 'date-fns';
import { pt, es, enUS } from 'date-fns/locale';
import { DateRange, DayPicker, Row, RowProps, SelectRangeEventHandler } from 'react-day-picker';
import { Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getCurrentLanguage } from '../../i18n';

type InputDataRangeProps = {
  labelFrom: string;
  labelTo: string;
  onChange: (dateRange?: DateRange) => void;
  onlyFuture?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  defaultValue?: DateRange;
};

const DATE_FORMAT = 'dd/MM/yyyy';

const dayPickerLanguages = {
  pt,
  es,
  en: enUS
};

function isPastDate(date: Date) {
  return differenceInCalendarDays(date, new Date()) < 0;
}

const OnlyFutureRow = (props: RowProps) => {
  const isPastRow = props.dates.every(isPastDate);
  if (isPastRow) return <></>;
  return <Row {...props} />;
};

export const InputDateRange = ({
  labelFrom,
  labelTo,
  onChange,
  errorMessage,
  disabled,
  defaultValue,
  onlyFuture = false
}: InputDataRangeProps) => {
  const currentLanguage = dayPickerLanguages[getCurrentLanguage() as keyof typeof dayPickerLanguages];

  const { t } = useTranslation();
  const [selectedRange, setSelectedRange] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    onChange(selectedRange);
  }, [onChange, selectedRange]);

  return (
    <>
      <div className="relative flex flex-col gap-4 sm:flex-row sm:gap-0">
        <label htmlFor="from" className="flex flex-col">
          <span className="pb-1 text-sm font-medium text-gray-700">{labelFrom}</span>
          <input
            id="from"
            type="text"
            placeholder="00/00/0000"
            disabled={disabled}
            className={classNames(
              'rounded-md sm:rounded-none sm:rounded-l-md border border-gray-300 py-1 px-4 sm:w-40 w-full',
              {
                'border-red-500': errorMessage
              }
            )}
            autoComplete="off"
            defaultValue={selectedRange?.from ? format(selectedRange.from, DATE_FORMAT) : ''}
            readOnly
            onFocus={() => setIsFocused(true)}
          />
        </label>

        <label htmlFor="to" className="flex flex-col">
          <span className="pb-1 text-sm font-medium text-gray-700">{labelTo}</span>
          <input
            id="to"
            type="text"
            placeholder="00/00/0000"
            disabled={disabled}
            className={classNames(
              'rounded-md sm:rounded-none sm:rounded-r-md border border-gray-300 py-1 px-4 sm:w-40 w-full',
              {
                'border-red-500': errorMessage
              }
            )}
            autoComplete="off"
            defaultValue={selectedRange?.to ? format(selectedRange.to, DATE_FORMAT) : ''}
            readOnly
            onFocus={() => setIsFocused(true)}
          />
        </label>

        <Transition
          show={isFocused}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-2"
          className="absolute top-14 z-20"
        >
          <button onClick={() => setIsFocused(false)} className="sr-only" type="button">
            {t('COMPONENTS.INPUT_DATE_RANGE.A11Y.CLOSE_CALENDAR')}
          </button>
          <DayPicker
            fromDate={new Date()}
            selected={selectedRange}
            onSelect={handleRangeSelect}
            mode="range"
            className=" rounded bg-primary p-2 text-white shadow-lg"
            locale={currentLanguage}
            components={{ Row: onlyFuture ? OnlyFutureRow : Row }}
            hidden={onlyFuture && isPastDate}
            formatters={{
              formatCaption: month => {
                const monthName = format(month, 'LLLL yyyy', {
                  locale: currentLanguage
                });
                return `${monthName.at(0)?.toUpperCase()}${monthName.slice(1)}`;
              }
            }}
            modifiersStyles={{
              selected: {
                background: 'white',
                color: indigo[600]
              }
            }}
          />
        </Transition>
      </div>

      {errorMessage && (
        <span className="flex items-center text-red-500">
          <ExclamationCircleIcon aria-hidden="true" className="mr-[3px] w-4" />
          {t(errorMessage)}
        </span>
      )}

      {isFocused && (
        <div
          aria-hidden="true"
          onClick={() => setIsFocused(false)}
          className="absolute top-0 right-0 z-10 h-screen w-screen bg-transparent"
        />
      )}
    </>
  );
};
