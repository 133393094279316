import { Timestamp } from 'firebase/firestore';

import { PhoneNumberType } from './purchase';

export enum DataImportStatusEnum {
  CREATED = 'created',
  'IN-PROGRESS' = 'in-progress',
  DONE = 'done',
  FAILED = 'failed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

export enum DataImportConfirmActions {
  ACCEPT = 'accept',
  REJECT = 'reject'
}

export enum DataImportTypeEnum {
  PARTICIPANT = 'participant'
}

export type DataImport = {
  createdAt: Timestamp;
  id: string;
  fileUrl: string;
  status: Lowercase<keyof typeof DataImportStatusEnum>;
  type: Lowercase<keyof typeof DataImportTypeEnum>;
};

export type DataImportParticipant = {
  cellphone: PhoneNumberType;
  document: string;
  eventId: string;
  documentType: string;
  email: string;
  fullName: string;
  ticketsId: string;
};

export type DataImportConfirm = {
  eventId: string;
  importDocId: string;
  importType: Lowercase<keyof typeof DataImportTypeEnum>;
  action: Lowercase<keyof typeof DataImportConfirmActions>;
};
