import {
  InformationCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon
} from '@heroicons/react/20/solid';
import { ComponentProps, ComponentType, useMemo } from 'react';

type Props = {
  message: string;
  type: 'info' | 'error' | 'warning' | 'success' | 'none';
  outline?: boolean;
} & React.HTMLProps<HTMLElement>;

const typeColors: { [key: string]: { color: string; icon: ComponentType<ComponentProps<'svg'>> } } = {
  info: {
    color: 'blue',
    icon: InformationCircleIcon
  },
  none: {
    color: 'gray',
    icon: InformationCircleIcon
  },
  error: {
    color: 'red',
    icon: XCircleIcon
  },
  warning: {
    color: 'yellow',
    icon: ExclamationTriangleIcon
  },
  success: {
    color: 'green',
    icon: CheckCircleIcon
  }
};

export const Alerts = ({ message, type, outline }: Props) => {
  const alertClass = useMemo(() => `rounded-md p-4 bg-${typeColors[type].color}-100`, [type]);
  const outlineAlertClass = useMemo(() => `rounded-md p-4 border border-${typeColors[type].color}-500`, [type]);
  const iconClass = useMemo(() => `h-5 w-5 text-${typeColors[type].color}-500`, [type]);
  const messageClass = useMemo(() => `text-sm text-${typeColors[type].color}-500`, [type]);
  const outlineMessageClass = useMemo(() => `text-sm text-black-100`, []);
  const Icon = useMemo(() => typeColors[type].icon, [type]);

  return (
    <div className={outline ? outlineAlertClass : alertClass}>
      <div className="flex">
        <div className="shrink-0">
          <Icon className={iconClass} aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={outline ? outlineMessageClass : messageClass} dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </div>
    </div>
  );
};
