import { useContext, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { chain } from 'lodash';
import { UserCircleIcon } from '@heroicons/react/20/solid';

import { ConstraintsListType, OrderByType, useGetCollectionData } from '../../../../../hooks';
import { ParticipantsContext } from '../../../participants-context';
import { Participant } from '../../../../../interfaces';
import { formatPhone } from '../../../../../utils';
import { Alerts } from '../../../../../components';

type ListType = {
  order: string;
  participants: Participant[];
}[];

const ParticipantsList = ({ order, participants }: { order: string; participants: Participant[] }) => (
  <div key={order} className="relative">
    <div className="sticky top-0 z-10 border-y border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
      <h3>{order}</h3>
    </div>
    <ul className="relative z-0 divide-y divide-gray-200">
      {participants.map(person => (
        <li key={`${person.document}-${person.email}`}>
          <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500 hover:bg-gray-50">
            <div className="shrink-0">
              <UserCircleIcon className="h-10 w-10 text-gray-300" />
            </div>
            <div className="min-w-0 flex-1">
              <Link to={`../manage/${person.email}`} state={{ person }} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{person.fullName}</p>
                <p className="truncate text-sm text-gray-500">{formatPhone({ phone: person.cellphone })}</p>
              </Link>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export const List = () => {
  const { eventId = '' } = useParams();
  const {
    inputSearchValue,
    generateToken,
    onlyRefundAndTransferToggle,
    participantsList,
    searchParticipantsList,
    setParticipantsList,
    isQueryingParticipants
  } = useContext(ParticipantsContext);
  const collectionName = onlyRefundAndTransferToggle ? 'participants-log' : 'participants';
  const alertMessage = useMemo(
    () =>
      onlyRefundAndTransferToggle
        ? 'Nenhum participante estornado ou transferido encontrado'
        : 'Nenhum participante encontrado. Verifique na lista de estornados/transferidos',
    [onlyRefundAndTransferToggle]
  );

  const constraints = useMemo(() => {
    const filters: ConstraintsListType = [];
    let order: OrderByType | undefined = {
      field: 'fullName',
      direction: 'asc'
    };
    if (inputSearchValue) {
      filters.push(
        { fieldPath: 'fullName', opStr: '==', value: inputSearchValue.name },
        { fieldPath: 'email', opStr: '==', value: inputSearchValue.email }
      );
      order = undefined;
    }
    return { filters, order };
  }, [inputSearchValue]);

  const { data } = useGetCollectionData<Participant>({
    collectionName: 'event',
    path: [eventId, collectionName],
    constraints: constraints.filters,
    orderBy: constraints.order
  });

  const list: ListType = useMemo(() => {
    const listOder = data.map(participant => ({
      ...participant,
      order: participant.fullName.charAt(0).toUpperCase(),
      token: generateToken({
        purchaseId: participant.purchaseId,
        participantEmail: participant.email,
        tagName: participant.tagName,
        tagNumber: participant.tagNumber,
        eventId: participant.eventId,
        fullName: participant.fullName,
        ticketId: participant.ticketsId,
        ticketTitle: participant.ticketsTitle,
        course: participant.course || '',
        company: participant.company || ''
      })
    }));
    return chain(listOder)
      .groupBy('order')
      .map((value, key) => ({ order: key, participants: value }))
      .value();
  }, [data, generateToken]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        // next();
      }
    });
    const element = document.querySelector('#list-footer');
    if (element) intersectionObserver.observe(element);
    return () => intersectionObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParticipantsList(list);
  }, [list, setParticipantsList]);

  if (!list || list.length === 0) {
    return (
      <div className="px-6">
        <Alerts message={alertMessage} type="none" />
      </div>
    );
  }

  return (
    <>
      {isQueryingParticipants
        ? searchParticipantsList.map(({ order, participants }) => (
            <ParticipantsList key={order} order={order} participants={participants} />
          ))
        : participantsList.map(({ order, participants }) => (
            <ParticipantsList key={order} order={order} participants={participants} />
          ))}
      <div id="list-footer" className="h-10" />
    </>
  );
};
