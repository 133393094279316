import { Trans, useTranslation } from 'react-i18next';

import { LandingPageCard, SpinnerLoader } from '../../../../../../components';
import { useCheckoutPage } from '../../../../../../hooks';
import { PecegePayCompleteOrderCreditCardResponseType, PecegePayOrderStatusEnum } from '../../../../../../interfaces';
import { PaymentFailed } from './payment-failed';
import { PaymentSuccess } from './payment-success';
import SecurityInfo from './security-info';

type Props = {
  pecegePay: PecegePayCompleteOrderCreditCardResponseType;
  logErrorCount: number;
};

export const CreditCardComplete: React.FC<Props> = ({ pecegePay, logErrorCount }: Props) => {
  const { t } = useTranslation();
  const { event, stagingPurchase } = useCheckoutPage();

  if (!pecegePay && logErrorCount === 0)
    return (
      <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.TITLE')}>
        <SpinnerLoader />
      </LandingPageCard>
    );

  if (!pecegePay && logErrorCount < 5) {
    return (
      <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.TITLE')}>
        <SpinnerLoader />
        <h1 className="pt-4 text-center text-lg font-medium">
          <Trans
            i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.PAYMENT_FAILED.FAILED_PROCESS"
            values={{
              logErrorCount
            }}
          />
        </h1>
      </LandingPageCard>
    );
  }

  if (!pecegePay && logErrorCount === 5) {
    return (
      <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.TITLE')}>
        <PaymentFailed customerName={stagingPurchase.customer?.name} />
      </LandingPageCard>
    );
  }

  if (pecegePay.status === PecegePayOrderStatusEnum.OPEN) {
    return (
      <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.TITLE')}>
        <SpinnerLoader />
        <h1 className="pt-4 text-center text-lg font-medium">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.VALIDATING_CREDIT_CARD')}
        </h1>
      </LandingPageCard>
    );
  }

  const ticketsQuantity = pecegePay.items.reduce((acc, { quantity }) => acc + quantity, 0);
  const [creditCard] = pecegePay.creditCardLimit.map(({ lastFourDigits, brand }) => ({ lastFourDigits, brand }));

  return (
    <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.CREDIT_CARD.TITLE')}>
      {pecegePay.status === PecegePayOrderStatusEnum.PAID ? (
        <PaymentSuccess
          pecegePay={pecegePay}
          event={event}
          lastFourDigits={creditCard.lastFourDigits}
          ticketsQuantity={ticketsQuantity}
          cardBrand={creditCard.brand}
        />
      ) : (
        <PaymentFailed customerName={pecegePay.customer.name} />
      )}
      <SecurityInfo />
    </LandingPageCard>
  );
};
