import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  sendPurchaseInfoTo: string | undefined;
};

export const BoletoSuccess: React.FC<Props> = ({ sendPurchaseInfoTo }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center gap-2 text-center text-gray-600">
      <CheckCircleIcon className="w-20 text-green-500" />
      <p>
        <Trans
          i18nKey="CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.SUCCESS.TITLE"
          components={{ strong: <strong /> }}
          values={{
            sendPurchaseInfoTo
          }}
        />
      </p>

      <Link to="/" className="mt-4 text-base text-primary hover:brightness-50">
        {t('CHECKOUT.ERROR_BOUNDARY.BUTTON')}
      </Link>
    </div>
  );
};
