import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketTypesEnum, TicketWithTier } from '../../interfaces/ticket';
import { getCurrentPeriod } from '../../utils/current-period';
import { PricingTiers, StatusPriceCounter } from './index';

type SinglePricingDetailProps = {
  ticket: TicketWithTier;
  getUpdateTicketAmount: ({ ticketId, increment }: updateTicketAmount) => void;
  purchaseCreateLoading: boolean;
  formatCurrency: (monetaryValue: number) => string;
};

type updateTicketAmount = {
  ticketId: string;
  increment: boolean;
};

// #region Componentes exclusivos para uso interno

export const SinglePricingDetail: React.FC<SinglePricingDetailProps> = ({
  ticket,
  getUpdateTicketAmount,
  purchaseCreateLoading,
  formatCurrency
}: SinglePricingDetailProps) => {
  const { t } = useTranslation();

  /** atenção aqui com a regra. essa regra aqui quer dizer que os lotes sempre terao datas configuradas corretamente */
  const currentTier = ticket.tiers.find(tier => tier.current);

  const lastTickets = useMemo(() => ticket.limitedTo - ticket.currentlySold, [ticket.currentlySold, ticket.limitedTo]);

  const notifySoldOutOnLast = useMemo(
    () => ticket.notifySoldOutOnLast !== null && lastTickets <= ticket.notifySoldOutOnLast,
    [lastTickets, ticket.notifySoldOutOnLast]
  );

  const isSoldOut = useMemo(
    () => ticket.limitedTo !== 0 && ticket.currentlySold >= ticket.limitedTo,
    [ticket.currentlySold, ticket.limitedTo]
  );

  const currentValue = useMemo(() => {
    if (!ticket.tiers || ticket.type === TicketTypesEnum.FREE || !currentTier) return 'Grátis';

    return currentTier.value;
  }, [ticket.tiers, ticket.type, currentTier]);

  const maximumCounterValue = useMemo(() => {
    if (!ticket.canBuyMoreThanOne) return;

    const hasLimit = ticket.limitedTo !== 0;
    const ticketsAvailable = ticket.limitedTo - ticket.currentlySold;

    if (
      hasLimit &&
      typeof ticket.howManyPurchasesAtSameTime === 'number' &&
      ticket.howManyPurchasesAtSameTime > ticketsAvailable
    ) {
      return ticketsAvailable;
    }

    return ticket.howManyPurchasesAtSameTime;
  }, [ticket]);

  const isValidityOutOfRange = useMemo(() => {
    const validity = ticket.validity && getCurrentPeriod(ticket.validity);

    return (
      (ticket.type === TicketTypesEnum.FREE && !validity) ||
      (ticket.type === TicketTypesEnum.PAID && !currentTier?.current)
    );
  }, [currentTier, ticket.type, ticket.validity]);

  const outOfRangeMessage = useMemo(() => {
    const isAllTiersSoldOut = ticket.tiers.every(tier => tier.validity.to.toMillis() < Date.now());

    if (isAllTiersSoldOut) {
      return 'Inscrições encerradas';
    }

    if (!currentTier?.current) {
      return 'Aguarde a data de abertura';
    }

    return null;
  }, [currentTier, ticket.tiers]);
  return (
    <div className="relative mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
      <div className="flex-1 bg-white p-6 lg:p-8">
        <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">{ticket.title}</h3>
        <p className="mt-6 text-gray-500" dangerouslySetInnerHTML={{ __html: ticket.details }} />
        {ticket.tiers && ticket.tiers.length > 0 && (
          <PricingTiers tiers={ticket.tiers} formatCurrency={formatCurrency} />
        )}
      </div>
      {notifySoldOutOnLast && !isSoldOut && !isValidityOutOfRange && (
        <>
          <div aria-hidden="true" className="absolute right-0 top-0 h-16 w-16">
            <div className="absolute right-[-36px] top-[32px] w-[170px] rotate-45 bg-rose-600 py-1 text-center font-semibold text-white">
              {t('LANDING_PAGE.TICKETS.RIBBON.TITLE', { count: lastTickets })}
            </div>
          </div>
          <p className="sr-only">
            {t('LANDING_PAGE.TICKETS.RIBBON.TITLE', { count: lastTickets })}{' '}
            {t('LANDING_PAGE.TICKETS.RIBBON.DESCRIPTION')}
          </p>
        </>
      )}
      <StatusPriceCounter
        ticketSoldOut={isSoldOut}
        isValidityOutOfRange={isValidityOutOfRange}
        currentValue={currentValue}
        maximumCounterValue={maximumCounterValue}
        outOfRangeMessage={outOfRangeMessage}
        ticketId={ticket.ticketId}
        getUpdateTicketAmount={getUpdateTicketAmount}
        purchaseCreateLoading={purchaseCreateLoading}
        formatCurrency={formatCurrency}
      />
    </div>
  );
};

// #endregion
