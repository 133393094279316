import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonWithIcon, LandingPageCard } from '../../../../../../components';

export const BoletoFailed: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleReturnToHome = () => navigate('/');
  return (
    <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.TITLE')}>
      <div className="flex flex-col items-center">
        <ExclamationCircleIcon className="w-20 text-red-500" />
        <h1 className="pt-4 text-center text-lg font-medium">
          {t('CHECKOUT.STEPS.ORDER_COMPLETE.BOLETO.FAILED.TITLE')}
        </h1>
      </div>
      <div className="flex justify-center">
        <ButtonWithIcon
          onClick={handleReturnToHome}
          type="button"
          customStylesButton="flex w-1/2 text-white bg-green-500 hover:bg-green-600 "
        >
          {t('CHECKOUT.BUTTONS.TRY_AGAIN')}
        </ButtonWithIcon>
      </div>
    </LandingPageCard>
  );
};
