import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Buffer } from 'buffer';

import { DebounceComboboxValueType, Participant } from '../../interfaces';

type ParticipantValueType = { name: string; email: string };

type TokenData = {
  purchaseId: string;
  participantEmail: string;
  fullName: string;
  tagName: string;
  tagNumber: number;
  eventId: string;
  ticketId: string;
  ticketTitle: string;
  course: string;
  company: string;
  tagTemplateUrl?: string;
};

export type ListType = {
  order: string;
  participants: Participant[];
}[];

interface ContextProps {
  inputSearchValue: DebounceComboboxValueType<ParticipantValueType> | undefined;
  setInputSearchValue: React.Dispatch<
    React.SetStateAction<DebounceComboboxValueType<ParticipantValueType> | undefined>
  >;
  generateToken: (data: TokenData) => string;
  setOnlyRefundAndTransferToggle: Dispatch<SetStateAction<boolean>>;
  onlyRefundAndTransferToggle: boolean;

  setDisabledRefund: Dispatch<SetStateAction<boolean>>;
  disabledRefund: boolean;

  setTransferIn: Dispatch<SetStateAction<boolean>>;
  transferIn: boolean;

  setTransferOut: Dispatch<SetStateAction<boolean>>;
  transferOut: boolean;

  setDisabledCheckIn: Dispatch<SetStateAction<boolean>>;
  disabledCheckIn: boolean;

  setSearchParticipantsList: Dispatch<SetStateAction<ListType>>;
  searchParticipantsList: ListType;

  setParticipantsList: Dispatch<SetStateAction<ListType>>;
  participantsList: ListType;

  setIsQueryingParticipants: Dispatch<SetStateAction<boolean>>;
  isQueryingParticipants: boolean;
}

const encodeBase64 = (data: string) => Buffer.from(data).toString('base64');

export const ParticipantsContext = createContext({} as ContextProps);

export const ParticipantsProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const [inputSearchValue, setInputSearchValue] = useState<DebounceComboboxValueType<ParticipantValueType>>();
  const [onlyRefundAndTransferToggle, setOnlyRefundAndTransferToggle] = useState(false);
  const [searchParticipantsList, setSearchParticipantsList] = useState<ListType>([]);
  const [participantsList, setParticipantsList] = useState<ListType>([]);
  const [isQueryingParticipants, setIsQueryingParticipants] = useState(false);

  // geralmente utilizado no componente de detalhes do participante para bloquear as ações do menu
  const [disabledRefund, setDisabledRefund] = useState(false);
  const [transferIn, setTransferIn] = useState(false);
  const [transferOut, setTransferOut] = useState(false);
  const [disabledCheckIn, setDisabledCheckIn] = useState(false);

  const generateToken = useCallback((data: TokenData) => encodeBase64(JSON.stringify(data)), []);

  return (
    <ParticipantsContext.Provider
      value={{
        inputSearchValue,
        setInputSearchValue,
        generateToken,
        onlyRefundAndTransferToggle,
        setOnlyRefundAndTransferToggle,
        disabledRefund,
        setDisabledRefund,
        transferIn,
        setTransferIn,
        transferOut,
        setTransferOut,
        disabledCheckIn,
        setDisabledCheckIn,
        setSearchParticipantsList,
        searchParticipantsList,
        setParticipantsList,
        participantsList,
        setIsQueryingParticipants,
        isQueryingParticipants
      }}
    >
      {children}
    </ParticipantsContext.Provider>
  );
};
