import { Combobox } from '@headlessui/react';

import { DebounceComboboxProps, DebounceComboboxValueType } from '../../interfaces';
import { useContext } from 'react';
import { ListType, ParticipantsContext } from '../../pages/participants/participants-context';

export const DebounceInputCombobox = <T,>({ label, onSelectedValue }: DebounceComboboxProps<T>) => {
  const { participantsList, setSearchParticipantsList, setIsQueryingParticipants, isQueryingParticipants } =
    useContext(ParticipantsContext);
  const removeAccents = (str: string) => str.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');

  const handleSearch = (query: string, participantsWithOrder: ListType) => {
    if (query.length === 0) {
      setIsQueryingParticipants(false);
      setSearchParticipantsList([]);
      return;
    }

    if (!isQueryingParticipants) {
      setIsQueryingParticipants(true);
    }

    const participants = participantsWithOrder.flatMap(order => order.participants);

    const normalizedQuery = removeAccents(query.toLowerCase());

    const result = participants.filter(participant =>
      removeAccents(participant.fullName.toLowerCase()).includes(normalizedQuery)
    );

    setSearchParticipantsList([
      {
        order: 'Resultados da busca',
        participants: result
      }
    ]);
  };

  return (
    <Combobox as="div" onChange={onSelectedValue} nullable>
      {label ? <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label> : null}
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            handleSearch(event.currentTarget.value, participantsList)
          }
          displayValue={(value: DebounceComboboxValueType<T>) => (value ? value.displayValue : '')}
        />
      </div>
    </Combobox>
  );
};
