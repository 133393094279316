import { Timestamp } from 'firebase/firestore';

export enum EventTypeEnum {
  PRESENCIAL = 'presencial',
  HIBRIDO = 'híbrido',
  ONLINE = 'online'
}

export type Event = {
  eventId: string;
  name: string;
  promoters: string[];
  date: {
    end: Timestamp;
    start: Timestamp;
    timezone: {
      id: string;
      offset: string;
    };
  };
  broadcastRecordDate?: {
    end: Timestamp;
    start: Timestamp;
  };
  shortDescription: string;
  type: EventTypeEnum;
  heroImageUrl?: string;
};
