export enum PecegePayDocumentType {
  CPF = 'cpf',
  CNPJ = 'cnpj',
  PASSPORT = 'passport'
}
export enum PaymentMethodTypeEnum {
  CREDIT_CARD = 'CreditCardLimit',
  BOLETO = 'Boleto',
  PIX = 'Pix',
  FREE = 'Free'
}
export enum PecegePayOrderStatusEnum {
  OPEN = 'Open',
  PAID = 'Paid',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  PARTIALLY_REFUNDED = 'partiallyRefunded',
  TOTALLY_REFUNDED = 'totallyRefunded'
}
export enum PecegePayOrderBoletoStatusEnum {
  OPEN = 'Open',
  PAID = 'Paid',
  CANCELLED = 'Cancelled',
  IN_CANCELLATION = 'InCancellation'
}
export enum PecegePayOrderCreditCardStatusEnum {
  OPEN = 'Open',
  PAID = 'Paid',
  PARTIALLY_REFUNDED = 'partiallyRefunded',
  TOTALLY_REFUNDED = 'totallyRefunded',
  REFUNDING = 'refunding'
}

export type PecegePayOrderItemType = {
  quantity: number;
  description: string;
  value: number;
  percentageDiscount?: number;
  currency?: string;
};

type PecegePayOrderBillingAddressType = {
  street: string;
  placeNumber: string;
  neighborhood: string;
  complement?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode: string;
};

export type PecegePayOrderCustomerType = {
  name: string;
  email: string;
  document: string;
  documentType: PecegePayDocumentType;
};

type PecegePayOrderBoletosUrlsType = {
  defaultUrl: string;
  png: string;
  pdf: string;
  boletoHibrido: string;
  boletoPix: string;
  barcode: string;
  envelope: string;
  letter: string;
  line: string;
  recibo: string;
};

type PecegePayOrderBoletoRequestType = {
  expireAt: Date;
  ourNumber?: number;
  fine?: number | null;
  dailyInterest?: number | null;
  timelyPaymentDiscount?: number | null;
  instructions?: string | null;
  daysToProtest?: number | null;
  daysForAutomaticWriteOff?: number | null;
};
type PecegePayOrderBoletoResponseType = {
  id: string;
  paidAt: Date | null;
  cancelledAt: Date | null;
  paidByPix: boolean;
  ourNumberRaw: number;
  barcode: string;
  registeredAt: Date | null;
  pixQrcode: string | null;
  pixTrackingCode: string | null;
  pixTxId: string | null;
  pixCopyCode: string | null;
  status: PecegePayOrderBoletoStatusEnum;
  error: unknown;
  errorOccurredAt: Date | null;
  urls: PecegePayOrderBoletosUrlsType;
};

export type PecegePayOrderCreditCardResponseType = {
  amount: number;
  amountPaid: number;
  availableAmount: number | null;
  brand: string;
  cardId: string;
  error: unknown;
  expirationMonth: number;
  expirationYear: number;
  firstSixDigits: string;
  id: string;
  lastFourDigits: string;
  paidAt: Date | null;
  paymentGatewayChargeId: string;
  refunds: [];
  status: PecegePayOrderCreditCardStatusEnum;
};

export type PecegePayOrderCreditCardRequestType = {
  holderName: string;
  number: string;
  expMonth: number;
  expYear: number;
  cvv: number;
  installments: number;
};

type PecegePayOrderRequestType = {
  companyBillingDocument: string;
  idExternal: string;
  issueInvoice: boolean;
  items: Array<PecegePayOrderItemType>;
  billingAddress: PecegePayOrderBillingAddressType;
  customer: PecegePayOrderCustomerType;
  boleto: PecegePayOrderBoletoRequestType;
  creditCardLimit: PecegePayOrderCreditCardRequestType;
};

export type PecegePayCompleteOrderBoletoResponseType = Omit<PecegePayOrderRequestType, 'boleto' | 'creditCardLimit'> & {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  amountPaid: number;
  amount: number;
  status: PecegePayOrderStatusEnum;
  paymentMethod: string;
  boletos: Array<PecegePayOrderBoletoRequestType & PecegePayOrderBoletoResponseType>;
};

export type PecegePayCompleteOrderCreditCardResponseType = Omit<
  PecegePayOrderRequestType,
  'boleto' | 'creditCardLimit'
> & {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  amountPaid: number;
  amount: number;
  status: PecegePayOrderStatusEnum;
  paymentMethod: string;
  creditCardLimit: Array<PecegePayOrderCreditCardResponseType>;
};

export type PecegePay = PecegePayCompleteOrderBoletoResponseType | PecegePayCompleteOrderCreditCardResponseType;
