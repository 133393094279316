import { useTranslation } from 'react-i18next';

type AboutProps = {
  description: string;
};

export const About = ({ description }: AboutProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'BROADCAST_PAGE' });
  return (
    <>
      <p className="mb-2 text-sm font-medium sm:text-lg">{t('COMPONENTS.DESCRIPTION.TITLE')}</p>
      <div
        className="w-full text-gray-500 prose-headings:text-lg prose-headings:font-semibold prose-headings:text-black prose-p:my-3 prose-p:text-gray-500 sm:text-lg"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </>
  );
};
