import { useTranslation } from 'react-i18next';
import BoletoImage from '../../../../assets/svg/boleto.svg';
import { usePaymentMethods } from '../../../../hooks';

const BoletoInfo: React.FC = () => {
  const { t } = useTranslation();
  const { daysToExpireText } = usePaymentMethods();

  return (
    <div className="ml-6 flex flex-col gap-4">
      <span className="w-32">
        <img src={BoletoImage} alt="boleto" />
      </span>
      <ul className="ml-6 list-disc space-y-1 font-medium">
        <li>{t('CHECKOUT.STEPS.PAYMENT_METHOD.BOLETO.INSTRUCTIONS.ITEM_1')}</li>
        <li>{t('CHECKOUT.STEPS.PAYMENT_METHOD.BOLETO.INSTRUCTIONS.ITEM_2')}</li>
        <li>{daysToExpireText}</li>
        <li>{t('CHECKOUT.STEPS.PAYMENT_METHOD.BOLETO.INSTRUCTIONS.ITEM_4')}</li>
      </ul>
    </div>
  );
};

export default BoletoInfo;
