import { useContext } from 'react';
import { RecaptchaContext } from '../contexts';

export const useRecaptcha = () => {
  const context = useContext(RecaptchaContext);
  if (!context) {
    throw new Error('useRecaptcha deve ser usado dentro do RecaptchaProvider.');
  }
  return context;
};
