import { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SpinnerLoader } from '../../../../components';
import { useGetCollectionData } from '../../../../hooks';
import { Activity, ActivityDisplayOnEnum } from '../../../../interfaces';
import { BroadcastScheduleItem } from './item';

const ScheduleContent = () => {
  const { eventId = '', activityId = '' } = useParams();

  const { data } = useGetCollectionData<Activity>({
    collectionName: 'event',
    path: [eventId, 'activities'],
    constraints: [
      { fieldPath: 'displayOn', opStr: 'in', value: [ActivityDisplayOnEnum.BOTH, ActivityDisplayOnEnum.WEB] }
    ],
    orderBy: {
      direction: 'asc',
      field: 'date'
    }
  });

  return (
    <>
      {data.map(item => (
        <li key={item.uid}>
          <Link
            to={item.uid}
            relative="route"
            className={classNames('flex items-center rounded-md p-2 transition hover:bg-blue-100/40', {
              'bg-blue-100/50': item.uid === activityId
            })}
          >
            <BroadcastScheduleItem {...item} />
          </Link>
        </li>
      ))}
    </>
  );
};

export const BroadcastSchedule = () => (
  <ul className="mt-4 space-y-4">
    <Suspense fallback={<SpinnerLoader />}>
      <ScheduleContent />
    </Suspense>
  </ul>
);
