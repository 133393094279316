import { PecegePayDocumentType } from '../interfaces';

export const defaultDocumentTypeValue = PecegePayDocumentType.CPF;

export const documentTypes = [
  {
    name: 'CPF',
    value: PecegePayDocumentType.CPF
  },
  {
    name: 'CNPJ',
    value: PecegePayDocumentType.CNPJ
  },
  {
    name: 'Passaporte',
    value: PecegePayDocumentType.PASSPORT
  }
];

export const brazilianDocumentTypes = [
  {
    name: 'CPF',
    value: PecegePayDocumentType.CPF
  },
  {
    name: 'CNPJ',
    value: PecegePayDocumentType.CNPJ
  }
];

export const foreignDocumentTypes = [
  {
    name: 'Passaporte',
    value: PecegePayDocumentType.PASSPORT
  }
];
