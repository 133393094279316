import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ParticipantsContext } from '../../../participants-context';
import { useGetCollectionQueryCounter } from '../../../../../hooks';

export const CountParticipants = () => {
  const { eventId = '' } = useParams();
  const { onlyRefundAndTransferToggle } = useContext(ParticipantsContext);
  const [count, setCount] = useState<number | null>(null);

  const subCollectionName = onlyRefundAndTransferToggle ? 'participants-log' : 'participants';

  const promise = useGetCollectionQueryCounter({
    collectionName: 'event',
    path: [eventId, subCollectionName]
  });

  useEffect(() => {
    const execute = async () => {
      const result = await promise;
      setCount(result);
    };
    execute();
  }, [promise]);

  const text = useMemo(() => {
    if (count) {
      return count > 1 ? `${count} participantes encontrados na lista` : `${count} participante encontrado na lista`;
    }
    return '';
  }, [count]);

  return (
    <>
      <p className="mt-1 mb-4 text-sm text-gray-600">{text}</p>
      <p className="mt-1 text-sm text-gray-600">Use o campo abaixo para procurar por nome, e-mail ou documento</p>{' '}
    </>
  );
};
