/* eslint-disable react/no-children-prop */
import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';

import { NavigationItem } from './navigation-item';
import { SidebarContext } from './sidebar-context';

type Props = { baseClass?: string };

export const SidebarNavigation: React.FC<Props> = ({ baseClass }: Props) => {
  const { navigation, secondaryNavigation } = useContext(SidebarContext);

  return (
    <>
      <div className="space-y-1">
        {navigation.map(item => {
          if (!item.children) {
            return (
              <div key={item.name}>
                <NavLink
                  key={item.name}
                  to={item.to}
                  className={({ isActive }) =>
                    `${baseClass} ${
                      isActive ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'
                    }`
                  }
                  children={({ isActive }) => <NavigationItem isActive={isActive} item={item} />}
                />
              </div>
            );
          }
          return (
            <Disclosure as="div" key={item.name} className="space-y-1">
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(baseClass, 'text-gray-700 hover:text-gray-900 hover:bg-gray-50')}
                  >
                    <item.icon
                      className="mr-3 h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                    <svg
                      className={classNames(
                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                        'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1">
                    {item.children &&
                      item.children.map(subItem => (
                        <NavLink
                          key={subItem.name}
                          to={subItem.to}
                          className={({ isActive }) =>
                            `group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-gray-600 ${
                              isActive
                                ? 'bg-gray-200 text-gray-900'
                                : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'
                            }`
                          }
                        >
                          {subItem.name}
                        </NavLink>
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        })}
      </div>
      <div className="mt-8">
        <h3 className="px-3 font-medium text-gray-500" id="projects-headline">
          Navegar
        </h3>
        <div className="mt-1 space-y-1" aria-labelledby="projects-headline">
          {secondaryNavigation.map(item => (
            <Link
              key={item.name}
              to={item.to}
              target="_blank"
              className="group flex items-center rounded-md px-3 py-2 text-gray-600 hover:bg-gray-50 hover:text-gray-900"
            >
              <span className="truncate">{item.name}</span>
              <span className="pointer-events-none pl-2 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

// classe base para componente desktop
SidebarNavigation.defaultProps = {
  baseClass: 'group w-full flex items-center text-left px-2 py-2 rounded-md'
};
