import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type updateTicketAmount = {
  ticketId: string;
  increment: boolean;
};

type InputNumberCounterProps = {
  maximumCounterValue?: number | null;
  ticketId: string;
  onInputChange?: (value: number) => void;
  getUpdateTicketAmount: ({ ticketId, increment }: updateTicketAmount) => void;
  purchaseCreateLoading: boolean;
};

export const InputNumberCounter: React.FC<InputNumberCounterProps> = ({
  maximumCounterValue = 1,
  ticketId,
  onInputChange,
  getUpdateTicketAmount,
  purchaseCreateLoading
}: InputNumberCounterProps) => {
  const [counter, setCounter] = useState<number>(0);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(ticketId, counter);

    if (onInputChange) onInputChange(counter);
  }, [counter, ticketId, setValue, onInputChange]);

  const disableIncrementButton = useMemo(
    () => counter === maximumCounterValue || purchaseCreateLoading,
    [counter, maximumCounterValue, purchaseCreateLoading]
  );

  const disableDecrementButton = useMemo(() => !counter || purchaseCreateLoading, [counter, purchaseCreateLoading]);

  const increment = useCallback(() => {
    if (disableIncrementButton) return;

    getUpdateTicketAmount({ ticketId, increment: true });
    setCounter(currentCounter => currentCounter + 1);
  }, [disableIncrementButton, getUpdateTicketAmount, ticketId]);

  const decrement = useCallback(() => {
    if (disableDecrementButton) return;

    getUpdateTicketAmount({ ticketId, increment: false });
    setCounter(currentCounter => currentCounter - 1);
  }, [disableDecrementButton, getUpdateTicketAmount, ticketId]);

  return (
    <div
      className="
        flex items-center justify-around rounded-md border-2
        border-gray-300 text-3xl font-extrabold
      "
    >
      <button className="w-12 py-2 px-4" type="button" onClick={decrement} disabled={disableDecrementButton}>
        -
      </button>
      <p className="w-16 text-2xl font-extrabold text-gray-900">{counter}</p>
      <button className="w-12 py-2 px-4" type="button" onClick={increment} disabled={disableIncrementButton}>
        +
      </button>
    </div>
  );
};
