import { Timestamp } from 'firebase/firestore';
import { PurchaseParticipantType } from './purchase';
import { TranslateType } from './translate';

export enum TicketTypesEnum {
  PAID = 'paga',
  FREE = 'gratuita'
}
export enum TicketVisibilityEnum {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

export type TicketValidity = {
  from: Timestamp;
  to: Timestamp;
};

export type TicketAllotment = {
  title: TranslateType;
  validity: TicketValidity;
  value: number;
};

export type TicketWithTier = {
  tiers: {
    title: TranslateType;
    value: number;
    deadline: string;
    current: boolean;
    isOlderThanCurrent: boolean;
    validity: TicketValidity;
  }[];
} & Ticket;

export type Ticket = {
  ticketId: string;
  canBuyMoreThanOne: boolean;
  howManyPurchasesAtSameTime: number | null;
  details: string;
  limitedTo: number;
  notifySoldOutOnLast: number | null;
  currentlySold: number;
  title: string;
  type: TicketTypesEnum;
  validity: TicketValidity | null;
  allotment: TicketAllotment[] | null;
  requireEnrollmentCheck: boolean;
  visibility: TicketVisibilityEnum;
};

export type TransferTicket = {
  eventId: string;
  exParticipantEmail: string;
  newParticipant: PurchaseParticipantType;
  availableForMailing: boolean;
};
