import { createContext, Suspense, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

import { formatDateToLocale, Optional } from '../utils';
import { useFirebaseFunctions, useGetCollectionGroupData, useGetDocument, useNotifications } from '../hooks';
import {
  Event,
  PurchaseStatusEnum,
  VerifyCouponActionEnum,
  StagingPurchaseType,
  PurchaseParticipantType,
  AdditionalInfoCheckoutForm,
  CompanyInviteField
} from '../interfaces';
import { useTranslation } from 'react-i18next';

export type EventDates = {
  start: Timestamp;
  end: Timestamp;
};

export type FormatedEvent = Event & {
  duration: string;
  eventType: string;
  location: string;
};

type PurchaseParticipantsRequestType = {
  eventId: string;
  purchaseId: string;
  participants: PurchaseParticipantType[];
};

type PurchaseParticipantsResponseType = {
  result: boolean;
};

type ValidateCouponRequestType = {
  eventId: string;
  couponId: string;
  action: VerifyCouponActionEnum;
  purchaseId: string;
};

type ValidateCouponResponseType = boolean;

// esse type tem essas props opcinais porque não sabemos em que momento estamos consultando. O que temos certeza é, que sempre
// teremos eventId, purchaseId, status e tickets em todas as fases da criação de uma compra
export type OptionalStagingPurchaseType = Optional<
  StagingPurchaseType,
  | 'participants'
  | 'customer'
  | 'participantDocuments'
  | 'participantNames'
  | 'participantEmails'
  | 'sendPurchaseInfoTo'
  | 'logErrorCount'
>;

export type StagingPurchaseParticipantType = Array<
  Pick<PurchaseParticipantType, 'ticketId' | 'ticketTitle' | 'ticketTags'> & {
    requireEnrollmentCheck: boolean;
  }
>;

interface ContextProps {
  stagingPurchase: OptionalStagingPurchaseType;
  event: FormatedEvent;
  handleGoBackToParticipants: () => void;
  handleContinueToCustomer: () => void;
  handleContinueToPaymentMethods: () => void;
  handleCompletePayment: () => void;
  purchaseTotalValue: number;
  handlePurchaseParticipantsRequest: (
    callableParams: PurchaseParticipantsRequestType
  ) => Promise<PurchaseParticipantsResponseType | undefined>;
  participantTicketList: StagingPurchaseParticipantType;
  handleCouponRequest: (callableParams: ValidateCouponRequestType) => Promise<ValidateCouponResponseType | undefined>;
  companyInviteField?: CompanyInviteField;
}

const duplicateFiledValue = (values: string[]) => new Set(values).size !== values.length;

export const CheckoutPageContext = createContext({} as ContextProps);

export const CheckoutPageProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const params = useParams();
  const navigate = useNavigate();
  const { purchaseId = '' } = params;
  const { onCall } = useFirebaseFunctions();
  const { errorToast } = useNotifications();
  const { t } = useTranslation();

  const handlePurchaseParticipantsRequest = useCallback(
    async (callableParams: PurchaseParticipantsRequestType) => {
      const { participants } = callableParams;
      const documentFildValues = participants.map(participant => participant.document);
      const isDuplicateDocument = duplicateFiledValue(documentFildValues);

      const emailFieldValues = participants.map(participant => participant.email);
      const isDuplicateEmail = duplicateFiledValue(emailFieldValues);

      if (isDuplicateDocument) {
        errorToast(t('CHECKOUT.STEPS.PARTICIPANTS_DATA.MESSAGES.DUPLICATED_DOCUMENT'));
        return undefined;
      }
      if (isDuplicateEmail) {
        errorToast(t('CHECKOUT.STEPS.PARTICIPANTS_DATA.MESSAGES.DUPLICATED_EMAIL'));
        return undefined;
      }

      return onCall<PurchaseParticipantsRequestType, PurchaseParticipantsResponseType>({
        functionName: 'http-cal-stagingPurchase-participants',
        callableParams,
        successMessage: 'CHECKOUT.STEPS.PARTICIPANTS_DATA.MESSAGES.REGISTERED_SUCCESSFULY'
      });
    },
    [errorToast, onCall, t]
  );

  const handleGoBackToParticipants = useCallback(() => navigate(`${purchaseId}`), [navigate, purchaseId]);

  const handleContinueToCustomer = useCallback(() => navigate(`${purchaseId}/customer`), [navigate, purchaseId]);

  const handleContinueToPaymentMethods = useCallback(
    () => navigate(`${purchaseId}/payment-methods`),
    [navigate, purchaseId]
  );

  const handleCompletePayment = useCallback(() => navigate(`${purchaseId}/complete`), [navigate, purchaseId]);

  const {
    data: [stagingPurchase]
  } = useGetCollectionGroupData<OptionalStagingPurchaseType>({
    collectionName: 'staging-purchases',
    constraints: [
      { fieldPath: 'purchaseId', opStr: '==', value: purchaseId },
      {
        fieldPath: 'status',
        opStr: 'in',
        value: [PurchaseStatusEnum.VOID, PurchaseStatusEnum.OPEN, PurchaseStatusEnum.PAID, PurchaseStatusEnum.FAILED]
      }
    ]
  });

  if (!stagingPurchase || !stagingPurchase.eventId) {
    throw new Error('Inscrição não existe ou foi removida por ultrapassar tempo limite de compra');
  }

  const { data: event } = useGetDocument<Event>({ collectionName: 'event', path: [stagingPurchase.eventId] });
  const { data: eventLocation } = useGetDocument<{ fullAddress: string }>({
    collectionName: 'event',
    path: [stagingPurchase.eventId, 'additional-info', 'location']
  });

  const getEventDuration = ({ start, end }: EventDates) =>
    `de ${formatDateToLocale({ date: start, style: 'dd/MM/yyyy HH:mm', lang: 'pt' })} até ${formatDateToLocale({
      date: end,
      style: 'dd/MM/yyyy HH:mm',
      lang: 'pt'
    })}`;

  const purchaseTotalValue = stagingPurchase.tickets.reduce((acc, currentTicket) => {
    if (currentTicket.coupon) {
      const discount = (currentTicket.value * currentTicket.quantity * currentTicket.coupon.discount) / 100;
      return acc + currentTicket.value * currentTicket.quantity - discount;
    }
    return acc + currentTicket.value * currentTicket.quantity;
  }, 0);

  const participantTicketList = stagingPurchase.tickets
    .map(ticket =>
      Array(ticket.quantity)
        .fill(0)
        .map(() => ({
          ticketId: ticket.id,
          ticketTitle: ticket.title,
          requireEnrollmentCheck: ticket.requireEnrollmentCheck,
          ticketTags: ticket.tags
        }))
    )
    .flat();

  const handleCouponRequest = useCallback(
    async (callableParams: ValidateCouponRequestType) =>
      onCall<ValidateCouponRequestType, ValidateCouponResponseType>({
        functionName: 'http-cal-coupon-handle',
        callableParams
      }),
    [onCall]
  );

  const checkoutFormData = useGetDocument<AdditionalInfoCheckoutForm>({
    collectionName: 'event',
    path: [stagingPurchase.eventId, 'additional-info', 'checkout-form']
  });

  const companyInviteField = checkoutFormData.data?.companyInviteField || undefined;

  const memoValue = useMemo(
    () => ({
      stagingPurchase,
      event: {
        ...event,
        eventType: `Evento ${event.type}`,
        location: eventLocation.fullAddress,
        heroImageUrl: event.heroImageUrl || process.env.REACT_APP_HERO_IMAGE_PLACEHOLDER,
        duration: getEventDuration({
          start: event.date.start,
          end: event.date.end
        })
      },
      handleGoBackToParticipants,
      handleContinueToCustomer,
      handleContinueToPaymentMethods,
      handleCompletePayment,
      purchaseTotalValue,
      handlePurchaseParticipantsRequest,
      participantTicketList,
      handleCouponRequest,
      companyInviteField
    }),
    [
      stagingPurchase,
      event,
      eventLocation.fullAddress,
      handleGoBackToParticipants,
      handleContinueToCustomer,
      handleContinueToPaymentMethods,
      handleCompletePayment,
      purchaseTotalValue,
      handlePurchaseParticipantsRequest,
      participantTicketList,
      handleCouponRequest,
      companyInviteField
    ]
  );

  return (
    <CheckoutPageContext.Provider value={memoValue}>
      <Suspense>{children}</Suspense>
    </CheckoutPageContext.Provider>
  );
};
