import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ptBrTranslation from './translations/pt-BR.json';
import enTranslation from './translations/en.json';
import esTranslation from './translations/es.json';

import ptFlag from '../assets/svg/flags/pt.svg';
import enFlag from '../assets/svg/flags/en.svg';
import esFlag from '../assets/svg/flags/es.svg';

// em produção não precisamos do modo debug
const debug = process.env.REACT_APP_I18N_DEBUG === 'true';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    supportedLngs: ['pt', 'en', 'es'],
    debug,
    interpolation: {
      escapeValue: false
    },
    resources: {
      pt: {
        translation: ptBrTranslation
      },
      en: {
        translation: enTranslation
      },
      es: {
        translation: esTranslation
      }
    }
  });

export const getCurrentLanguage = (): string => i18n.language;
export const getCurrentLanguagePrefix = (): string => getCurrentLanguage().split('-')[0].toLocaleLowerCase();
export const changeLanguage = (languagePrefix: string): Promise<TFunction> => i18n.changeLanguage(languagePrefix);

export const languagesFlags = [
  { name: 'pt', flag: ptFlag },
  { name: 'en', flag: enFlag },
  { name: 'es', flag: esFlag }
];

export default i18n;
