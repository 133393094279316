import classNames from 'classnames';

type CardProps = React.PropsWithChildren & {
  title?: string;
  description?: string;
  noShadow?: boolean;
  bodyPadding?: '2' | '4';
};

export const Card = ({ children, title, description, noShadow = false, bodyPadding = '4' }: CardProps) => {
  const bodyClass = classNames('border-gray-200', {
    'border-t ': title,
    'px-4 py-4 sm:px-6': bodyPadding === '4',
    'px-2 py-2 sm:px-4': bodyPadding === '2'
  });
  const mainClass = classNames('w-full bg-white sm:rounded-lg', {
    shadow: !noShadow
  });
  return (
    <div className={mainClass}>
      {(title || description) && (
        <div className="px-4 py-5 sm:px-6">
          {title ? <h2 className="text-lg font-medium text-gray-900">{title}</h2> : null}
          {description ? <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p> : null}
        </div>
      )}
      <div className={bodyClass}>{children}</div>
    </div>
  );
};
