import { useContext } from 'react';
import { ActivitiesContext } from '../contexts';

const useActivities = () => {
  const context = useContext(ActivitiesContext);
  if (!context) {
    throw new Error('useActivities deve ser usado dentro do ActivitiesProvider.');
  }
  return context;
};
export default useActivities;
