import * as yup from 'yup';

import { PecegePayDocumentType, PhoneNumberType, PurchaseParticipantType } from '../../interfaces';
import { defaultCourseValue } from '../../utils';

export type PurchaseParticipantValues = {
  participants: Array<Omit<PurchaseParticipantType, 'cellphone'> & { cellphone: string | PhoneNumberType }>;
  termsAndConditions: boolean;
  availableForMailing: boolean;
};

export const ParticipantsInfoFormSchema = yup.object().shape({
  participants: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .max(50, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .matches(
          /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
          'CHECKOUT.FORM.WARNINGS.NO_NUMBERS_OR_SPECIAL_CHARACTERS'
        )
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      lastName: yup
        .string()
        .max(50, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .matches(
          /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
          'CHECKOUT.FORM.WARNINGS.NO_NUMBERS_OR_SPECIAL_CHARACTERS'
        )
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      email: yup
        .string()
        .max(50, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .email('CHECKOUT.FORM.WARNINGS.FIELD_INVALID')
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      confirmEmail: yup.string().oneOf([yup.ref('email'), null], 'CHECKOUT.FORM.WARNINGS.EMAILS_DONT_MATCH'),
      nacionality: yup
        .string()
        .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      documentType: yup
        .string()
        .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      document: yup
        .string()
        .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
        .when('documentType', {
          is: (value: string) => value === PecegePayDocumentType.CPF,
          then: yup.string().test('document', 'CHECKOUT.FORM.WARNINGS.FIELD_INVALID', value => value?.length === 14)
        })
        .when('documentType', {
          is: (value: string) => value === PecegePayDocumentType.CNPJ,
          then: yup
            .string()
            .test('document', 'CHECKOUT.FORM.WARNINGS.FIELD_INVALID', value => Number(value?.length) > 16)
        }),
      isPeopleSpecialNeeds: yup.boolean().nullable().required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      peopleSpecialNeedsType: yup
        .string()
        .max(50, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .when('isPeopleSpecialNeeds', {
          is: (value: string) => value,
          then: yup.string().required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
        }),
      tagName: yup.string().max(50, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS'),
      cellphone: yup
        .string()
        .min(10, 'CHECKOUT.FORM.WARNINGS.NO_MINIMUM_CHARACTERS')
        .max(20, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
      course: yup.string().test('course', 'CHECKOUT.FORM.WARNINGS.MBA_COURSE', value => value !== defaultCourseValue),
      languageContact: yup
        .string()
        .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
        .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
    })
  ),
  // checkbox não precisa de mensagem
  termsAndConditions: yup.bool().oneOf([true]),
  availableForMailing: yup.bool()
});
