import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { AppCheckProvider, useFirebaseApp } from 'reactfire';

export const ReactFireAppCheckProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const APP_CHECK_TOKEN = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '';
  const app = useFirebaseApp();
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true
  });
  return <AppCheckProvider sdk={appCheck}>{children}</AppCheckProvider>;
};
