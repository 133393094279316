/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

export enum ComponentTypeEnum {
  BUTTON = 'button',
  LINK = 'link',
  NAV_LINK = 'nav-link'
}

type MenuItemType = {
  key: string;
  componentType: ComponentTypeEnum;
  component: JSX.Element;
  onClick: string | (() => Promise<void>);
  onClickState?: { [key: string]: any };
  disabled?: boolean;
  class: {
    base: string;
    active?: string;
    inactive?: string;
    disabled?: string;
  };
};

type PropsType = {
  menuButton: {
    className: string;
    component: JSX.Element;
  };
  menuItems: {
    className: string;
    items: Array<MenuItemType>;
  };
} & React.HTMLAttributes<HTMLElement>;

export const SuspenseMenu: React.FC<PropsType> = ({ className, menuButton, menuItems }: PropsType) => {
  const renderItem = (item: MenuItemType, active: boolean) => {
    switch (item.componentType) {
      case ComponentTypeEnum.BUTTON:
        return (
          <button
            onClick={item.onClick as () => Promise<void>}
            className={classNames(
              item.class.base,
              active ? item.class.active : item.class.inactive,
              item.disabled ? item.class.disabled : ''
            )}
            type="button"
          >
            {item.component}
          </button>
        );
      case ComponentTypeEnum.LINK:
        return (
          <Link
            to={item.onClick as string}
            className={classNames(
              item.class.base,
              active ? item.class.active : item.class.inactive,
              item.disabled ? item.class.disabled : ''
            )}
            state={item.onClickState}
          >
            {item.component}
          </Link>
        );
      case ComponentTypeEnum.NAV_LINK:
        return (
          <NavLink
            to={item.onClick as string}
            className={classNames(
              item.class.base,
              active ? item.class.active : item.class.inactive,
              item.disabled ? item.class.disabled : ''
            )}
            state={item.onClickState}
          >
            {item.component}
          </NavLink>
        );
    }
  };

  return (
    <Menu as="div" className={className}>
      <Menu.Button className={menuButton.className}>{menuButton.component}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuItems.className}>
          <div className="py-1">
            {menuItems.items.map(item => (
              <Menu.Item key={item.key} disabled={item.disabled}>
                {({ active }) => renderItem(item, active)}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
