import { createContext, useCallback, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNotifications } from '../hooks';

interface ContextProps {
  recaptchaToken: string | null;
  reCaptchaVerify: (action: string) => Promise<string | undefined>;
}

export const RecaptchaContext = createContext({} as ContextProps);

export const RecaptchaProvider: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { errorToast } = useNotifications();

  const reCaptchaVerify = useCallback(
    async (action: string): Promise<string | undefined> => {
      try {
        if (!executeRecaptcha) {
          throw new Error('executeRecaptcha is undefined');
        }
        const token = await executeRecaptcha(action);
        if (!token) {
          throw new Error('Token is undefined');
        }
        setRecaptchaToken(token);
        return token;
      } catch (err: unknown) {
        if (err instanceof Error) {
          switch (err.message) {
            case 'executeRecaptcha is undefined':
              errorToast('Validação de segurança não disponível ainda, tente novamente em alguns segundos');
              break;

            case 'Token is undefined':
              errorToast('Validação de segurança falhou, tente novamente em alguns segundos');
              break;

            default:
              errorToast('Algo inesperado aconteceu, tente novamente em alguns instantes');
              break;
          }
        }
        // eslint-disable-next-line no-console
        console.log(err);
        errorToast('Erro ao validar o reCAPTCHA');
        return undefined;
      }
    },
    [executeRecaptcha, errorToast]
  );

  const value = useMemo(() => ({ recaptchaToken, reCaptchaVerify }), [recaptchaToken, reCaptchaVerify]);

  return <RecaptchaContext.Provider value={value}>{children}</RecaptchaContext.Provider>;
};
