import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ParticipantsContext } from '../../../participants-context';
import { Alerts, Card, Timeline, TimelineTypeEnum } from '../../../../../components';
import { useGetCollectionData } from '../../../../../hooks';
import { FixedActivitiesEnum, ParticipantActivity, ParticipantLogActivityStatusEnum } from '../../../../../interfaces';
import { formatDateToLocale } from '../../../../../utils';

export const ActivitiesTimeline = ({ email }: { email: string }) => {
  const { eventId = '' } = useParams();
  const { setDisabledCheckIn } = useContext(ParticipantsContext);
  const { data: activities } = useGetCollectionData<ParticipantActivity>({
    collectionName: 'event',
    path: [eventId, 'participants', email, 'participant-activities'],
    constraints: [{ fieldPath: 'status', opStr: '==', value: ParticipantLogActivityStatusEnum.ACCEPTED }],
    orderBy: {
      field: 'createdAt',
      direction: 'asc'
    }
  });

  const disabledCheckIn = useMemo(
    () => activities.some(s => s.activityId === FixedActivitiesEnum.CHECK_IN),
    [activities]
  );

  useEffect(() => {
    setDisabledCheckIn(disabledCheckIn);
  }, [disabledCheckIn, setDisabledCheckIn]);

  const timeline = useMemo(
    () =>
      activities.map(activity => ({
        id: activity.activityId,
        type: TimelineTypeEnum.CHECK,
        content: activity.activityTitle,
        date: formatDateToLocale({
          date: activity.createdAt,
          lang: 'pt',
          style: 'dd MMM HH:mm'
        })
      })),
    [activities]
  );

  const renderCardBody = () => {
    if (!activities || activities.length === 0) {
      return <Alerts type="none" message="Nenhum registro de atividades até o momento" />;
    }
    return <Timeline timeline={timeline} />;
  };

  return <Card title="Registro de atividades">{renderCardBody()}</Card>;
};
