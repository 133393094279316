import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import { useGetDocument } from '../../hooks';
import { SoldTickets } from '../../interfaces';
import { nameInitials } from '../../utils';
import { Alerts, ComponentTypeEnum, SuspenseMenu } from '../../components';

const StatsActionMenu = ({ ticketId }: { ticketId: string }) => {
  const menuButton = {
    className:
      'inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    component: (
      <>
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </>
    )
  };

  const menuItems = {
    className:
      'absolute right-10 top-3 z-10 mx-3 mt-1 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 focus:outline-none',
    items: [
      {
        key: '',
        componentType: ComponentTypeEnum.NAV_LINK,
        component: <>Ver ingressos</>,
        onClick: ticketId,
        class: {
          base: 'block px-4 py-2 text-sm',
          active: 'bg-gray-100 text-gray-900',
          inactive: 'text-gray-700'
        }
      }
    ]
  };

  return <SuspenseMenu menuButton={menuButton} menuItems={menuItems} className="shrink-0 pr-2" />;
};

export const TicketsSoldStats = () => {
  const { eventId = '' } = useParams();
  const eventCollectionName = 'event';
  const statisticsCollectionName = 'statistics';
  const soldOnlineTicketsDocName = 'sold-online-tickets';
  const soldInPersonTicketsDocName = 'sold-in-person-tickets';
  const refundsDocName = 'refunds';

  const { data: onlineTickets } = useGetDocument<SoldTickets>({
    collectionName: eventCollectionName,
    path: [eventId, statisticsCollectionName, soldOnlineTicketsDocName]
  });

  const { data: inPersonTickets } = useGetDocument<SoldTickets>({
    collectionName: eventCollectionName,
    path: [eventId, statisticsCollectionName, soldInPersonTicketsDocName]
  });

  const { data: refundsTickets } = useGetDocument<{ count: number }>({
    collectionName: eventCollectionName,
    path: [eventId, statisticsCollectionName, refundsDocName]
  });

  const memoizedOnlineTickets = useMemo(() => {
    if (onlineTickets) {
      return Object.keys(onlineTickets)
        .map(key => ({ ...onlineTickets[key], ticketId: key }))
        .slice(0, -1);
    }
    return [];
  }, [onlineTickets]);

  const memoizedInPersonTickets = useMemo(() => {
    if (inPersonTickets) {
      return Object.keys(inPersonTickets)
        .map(key => ({ ...inPersonTickets[key], ticketId: key }))
        .slice(0, -1);
    }
    return [];
  }, [inPersonTickets]);

  const totalSold = useMemo(
    () =>
      Object.values([...memoizedOnlineTickets, ...memoizedInPersonTickets])
        .map(item => item.count)
        .reduce((acc, current) => acc + current, 0),
    [memoizedInPersonTickets, memoizedOnlineTickets]
  );

  const list = useMemo(
    () => [
      {
        count: totalSold,
        ticketName: 'Total vendido',
        ticketId: 'all',
        color: 'bg-green-600'
      },
      ...memoizedOnlineTickets,
      ...memoizedInPersonTickets,
      {
        count: refundsTickets ? refundsTickets.count : 0,
        ticketName: 'Ingressos estornados',
        ticketId: refundsDocName,
        color: 'bg-red-600'
      }
    ],
    [memoizedInPersonTickets, memoizedOnlineTickets, refundsTickets, totalSold]
  );

  const objectList = useMemo(
    () =>
      list.reduce((result: SoldTickets, currentValue) => {
        if (!result[currentValue.ticketId]) {
          result[currentValue.ticketId] = {
            ticketId: currentValue.ticketId,
            ticketName: currentValue.ticketName,
            count: currentValue.count,
            color: currentValue.color
          };
        } else {
          result[currentValue.ticketId].count += currentValue.count;
        }
        return result;
      }, {}),
    [list]
  );

  const soldText = useCallback(
    (key: string) => {
      const count = objectList[key].count;
      if (key === refundsDocName) {
        const text = count > 1 ? 'estornados' : 'estornado';
        return `${count} ${text}`;
      }
      const text = count > 1 ? 'vendidos' : 'vendido';
      return `${count} ${text}`;
    },
    [objectList]
  );

  if (totalSold <= 0) {
    return <Alerts type="info" message="Nenhum ingresso foi vendido até o momento" />;
  }

  return (
    <ul className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 xl:grid-cols-4">
      {Object.keys(objectList).map(key => (
        <li key={key} className="relative col-span-1 flex rounded-md shadow-sm">
          <div
            className={classNames(
              objectList[key].color ?? 'bg-blue-600',
              'shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
            )}
          >
            {nameInitials(objectList[key].ticketName)}
          </div>
          <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-y border-r border-gray-200 bg-white">
            <div className="flex-1 truncate px-4 py-2 text-sm">
              <span className="font-medium text-gray-900 hover:text-gray-600">{objectList[key].ticketName}</span>
              <p className="text-gray-500">{soldText(key)}</p>
            </div>
            <StatsActionMenu ticketId={objectList[key].ticketId} />
          </div>
        </li>
      ))}
    </ul>
  );
};
