import { Timestamp } from 'firebase/firestore';
import { PaymentMethodTypeEnum, PecegePayDocumentType } from './pecege-pay';

export enum PurchaseStatusEnum {
  VOID = 'void',
  OPEN = 'open',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  IN_CANCELATION = 'in-cancellation',
  FAILED = 'failed',
  PARTIALLY_REFUNDED = 'partially-refunded',
  FULLY_REFUNDED = 'fully-refunded',
  EXPIRED = 'expired' // somente para casos de purge
}

export type PhoneNumberType = {
  countryCode: string;
  areaCode: string;
  number: string;
};

export type PurchaseParticipantType = {
  firstName: string;
  lastName: string;
  email: string;
  cellphone: PhoneNumberType;
  tagName: string;
  tagNumber: number;
  nacionality: string;
  document: string;
  documentType: PecegePayDocumentType;
  ticketId: string;
  ticketTitle: string;
  ticketTags: string[] | null;
  refund?: {
    refundRequestedAt?: Date;
    refundReason: string;
    refundAmount: number;
    refundNotifiedAt: Date | null;
    refundedByUser: string;
    refundPercentage: number;
  };
  // WORKAROUND: paliativo, temos que remover quando o form for dinamico
  isPeopleSpecialNeeds: boolean;
  peopleSpecialNeedsType: string;
  languageContact: string;
  course: string | null;
  availableForMailing: boolean;
  company: string | null;
};

export type PurchaseTicketsType = {
  id: string;
  title: string;
  value: number;
  quantity: number;
  coupon?: { id: string; discount: number };
  tags: string[] | null;
  // WORDAROUND: prop paliativa para ser utilizada no ato da compra (isso deve ser removido quando um form dinamico for criado)
  requireEnrollmentCheck: boolean;
};

export type PurchaseCustomerBillingAddressType = {
  nacionality: string;
  zipCode: string;
  placeNumber: string;
  street: string;
  neighborhood: string;
  complement: string;
  city: string;
  state: string;
};

export type PurchaseCustomerType = {
  name: string;
  email: string;
  document: string;
  documentType: PecegePayDocumentType;
  billingAddress: PurchaseCustomerBillingAddressType;
  phoneNumber: PhoneNumberType;
};

export type PurchaseType = {
  eventId: string;
  purchaseId: string;
  orderId: string;
  paymentMethod: PaymentMethodTypeEnum;
  inPersonPurchase: boolean;
  status: PurchaseStatusEnum;
  participants: Array<PurchaseParticipantType>;
  tickets: Array<PurchaseTicketsType>;
  customer: PurchaseCustomerType;
  sendPurchaseInfoTo: string;
  participantDocuments: Array<string>;
  participantNames: Array<string>;
  participantEmails: Array<string>;
  ticketIds: Array<string>;
  ticketNames: Array<string>;
  createdAt: Timestamp;
  completedAt?: Timestamp;
};
