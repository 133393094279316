import { Md5 } from 'ts-md5';

export const md5HashAsciiStringLMS = (value: string): string => {
  const md5Str = Md5.hashAsciiStr(value).toString();
  const md5StrArray = md5Str.toUpperCase().match(/.{1,2}/g);
  return md5StrArray?.join('-') || '';
};

export const nameShortener = (fullName: string): string => {
  const [firstName, ...names] = fullName.split(' ');

  if (names.length <= 2) {
    return fullName;
  }

  const surnames = names.map((name, index) => {
    if (name.length <= 3 || index === names.length - 1) {
      return name;
    }

    return name.substring(0, 1).toUpperCase();
  });

  return `${firstName} ${surnames.join('. ')}`;
};

export const nameInitials = (string: string): string => {
  const names = string.split(' ');
  const initials = names.map((name: string) => name[0].toUpperCase()).join('');

  if (names.length <= 1) {
    return `${initials[0]}` ?? '';
  }

  return `${initials[0]}${initials[initials.length - 1]}` ?? '';
};
