import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

type AcordionProps = {
  items: Array<{ key: string; title: string; component: JSX.Element }>;
};

export const Acordion = ({ items }: AcordionProps) => (
  <>
    {items.map(({ key, title, component }) => (
      <Disclosure as="div" key={key} className="mb-4">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-lg font-semibold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500">
              <span>{title}</span>
              <ChevronUpIcon className={`${open ? 'rotate-180' : ''} h-6 w-6 text-blue-500`} />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2">{component}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ))}
  </>
);
