import * as yup from 'yup';

import { PecegePayDocumentType } from '../../../../interfaces';

export const CustomerInfoFormSchema = yup.object().shape({
  name: yup
    .string()
    .max(100, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'CHECKOUT.FORM.WARNINGS.NO_NUMBERS_OR_SPECIAL_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  email: yup
    .string()
    .max(50, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .email('CHECKOUT.FORM.WARNINGS.FIELD_INVALID')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  documentType: yup
    .string()
    .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  document: yup
    .string()
    .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
    .when('documentType', {
      is: (value: string) => value === PecegePayDocumentType.CPF,
      then: yup.string().test('document', 'CHECKOUT.FORM.WARNINGS.FIELD_INVALID', value => value?.length === 14)
    })
    .when('documentType', {
      is: (value: string) => value === PecegePayDocumentType.CNPJ,
      then: yup
        .string()
        .test(
          'document',
          'CHECKOUT.FORM.WARNINGS.FIELD_INVALID',
          value => Number(value?.length) > 16 && Number(value?.length) < 19
        )
    }),
  nacionality: yup
    .string()
    .max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  phoneNumber: yup
    .string()
    .min(10, 'CHECKOUT.FORM.WARNINGS.NO_MINIMUM_CHARACTERS')
    .max(20, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  zipCode: yup.string().when('nacionality', {
    is: (value: string) => value === 'BR',
    then: yup
      .string()
      .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD')
      .test('zipCode', 'CHECKOUT.FORM.WARNINGS.FIELD_INVALID', value => value?.length === 9)
  }),
  city: yup
    .string()
    .max(70, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  state: yup
    .string()
    .max(70, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  neighborhood: yup
    .string()
    .max(70, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  street: yup
    .string()
    .max(100, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  placeNumber: yup
    .string()
    .max(11, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
    .required('CHECKOUT.FORM.WARNINGS.REQUIRED_FIELD'),
  complement: yup.string().max(30, 'CHECKOUT.FORM.WARNINGS.MAXIMUM_CHARACTERS')
});
