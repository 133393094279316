import { ArrowUpTrayIcon, DocumentIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '../../hooks';

type InputFileProps = {
  acceptedMimeTypes: Accept;
  description: string;
  name: string;
  disabled?: boolean;
  maxSize?: number;
  defaultValue?: string;
};

export const InputFile = ({
  acceptedMimeTypes,
  description,
  name,
  disabled,
  maxSize,
  defaultValue
}: InputFileProps) => {
  const { errorToast } = useNotifications();
  const {
    setValue,
    register,
    unregister,
    formState: { errors }
  } = useFormContext();
  const { t } = useTranslation();
  const errorMessage = t(`${errors[name]?.message}`);

  const { getRootProps, getInputProps, acceptedFiles, isDragAccept, isDragActive, isDragReject } = useDropzone({
    accept: acceptedMimeTypes,
    onError: () => errorToast('Erro ao enviar o arquivo'),
    onDropRejected: () => errorToast('Arquivo não suportado'),
    onDrop: files => setValue(name, files),
    onFileDialogOpen: () => setValue(name, undefined),
    disabled,
    maxSize
  });

  useEffect(() => {
    register(name);

    () => unregister(name);
  }, [register, unregister, name]);

  return (
    <>
      {!!defaultValue && (
        <img src={defaultValue} alt="" className="w-full rounded-t-lg border border-b-0 border-gray-400 object-cover" />
      )}
      <div
        {...getRootProps()}
        className={classNames('cursor-pointer border border-dashed py-3 text-center', {
          'bg-emerald-50 border-emerald-500 text-emerald-500': isDragAccept,
          'border-red-500 bg-red-500/5 text-red-500': isDragReject,
          'border-primary bg-primary/5 text-primary': !isDragActive,
          'rounded-b-md rounded-t-0 border-t-0': !!defaultValue?.length,
          'rounded-md': !defaultValue?.length
        })}
      >
        <input {...getInputProps()} />
        <div className="mx-auto mb-3 flex h-10 w-10 items-center justify-center rounded-full border border-gray-400 bg-white">
          {acceptedFiles.length === 0 ? <ArrowUpTrayIcon className="h-4 w-4" /> : <DocumentIcon className="h-4 w-4" />}
        </div>
        <div className="text-gray-500">
          {acceptedFiles.length === 0 ? (
            <p>
              Arraste o arquivo ou
              <span
                className={classNames('ml-1', {
                  'text-primary': !isDragActive,
                  'text-emerald-500': isDragAccept,
                  'text-red-500': isDragReject
                })}
              >
                clique aqui para fazer upload.
              </span>
            </p>
          ) : (
            <p
              className={classNames({
                'text-primary': !isDragActive,
                'text-emerald-500': isDragAccept,
                'text-red-500': isDragReject
              })}
            >
              {acceptedFiles.map(file => file.name).join(', ')}
            </p>
          )}
          <p>{description}</p>
        </div>
      </div>
      {!!errors[name] && <p className="mt-2 text-red-500">{errorMessage}</p>}
    </>
  );
};
