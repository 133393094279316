import { useContext } from 'react';
import { BroadcastContext } from '../contexts';

export const useBroadcast = () => {
  const context = useContext(BroadcastContext);
  if (!context) {
    throw new Error('useBroadcast deve ser usado dentro do BroadcastProvider.');
  }
  return context;
};
