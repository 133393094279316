import { useTranslation } from 'react-i18next';

import { LandingPageCard } from '../../../../components';
import { useCheckoutPage } from '../../../../hooks';
import { DiscountCoupon } from './discount-coupon';
import { PurchaseSummaryTable } from './purchase-summary-table';

export const PurchaseSummary: React.FC = () => {
  const { t } = useTranslation();
  const { purchaseTotalValue } = useCheckoutPage();

  return (
    <LandingPageCard title={t('CHECKOUT.STEPS.ORDER_SUMMARY.TITLE')}>
      {purchaseTotalValue > 0 && <DiscountCoupon />}
      <PurchaseSummaryTable />
    </LandingPageCard>
  );
};
