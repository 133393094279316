import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { CustomerProvider, PaymentMethodsProvider } from '../../../../contexts';
import { useCheckoutPage } from '../../../../hooks';
import { EventDetails } from './event-details';
import { EventHero } from './event-hero';
import { ProgressBar } from './progress-bar';

export const EventSummary: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { event, purchaseTotalValue } = useCheckoutPage();

  const checkoutSteps = useMemo(
    () => [
      {
        stepId: '/',
        description: t('CHECKOUT.STEPS.PARTICIPANTS_DATA.DESCRIPTION'),
        title: t('CHECKOUT.STEPS.PARTICIPANTS_DATA.TITLE')
      },
      {
        stepId: '/customer',
        description: t('CHECKOUT.STEPS.BUYER_DATA.DESCRIPTION'),
        title: t('CHECKOUT.STEPS.BUYER_DATA.TITLE')
      },
      {
        stepId: '/payment-methods',
        description: t('CHECKOUT.STEPS.PAYMENT_METHOD.DESCRIPTION'),
        title: t('CHECKOUT.STEPS.PAYMENT_METHOD.TITLE')
      },
      {
        stepId: '/complete',
        description: t('CHECKOUT.STEPS.ORDER_COMPLETE.DESCRIPTION'),
        title: t('CHECKOUT.STEPS.ORDER_COMPLETE.TITLE')
      }
    ],
    [t]
  );

  const currentIndex = checkoutSteps.findIndex(({ stepId }) => location.pathname.endsWith(stepId));

  return (
    <>
      <div className="rounded-md bg-white">
        <section className="flex w-full flex-col items-center justify-between md:max-h-[320px] md:flex-row-reverse md:pr-8">
          <EventHero />
          <div className="flex max-w-xl grow flex-col justify-center gap-8 p-8">
            <EventDetails />
            <Link to={`/${event.eventId}`} className="text-base text-primary hover:brightness-50">
              {t('CHECKOUT.ERROR_BOUNDARY.BUTTON')}
            </Link>
          </div>
        </section>
      </div>
      {purchaseTotalValue > 0 && (
        <section className="rounded-md bg-white p-8">
          <ProgressBar steps={checkoutSteps} currentStep={currentIndex === -1 ? 0 : currentIndex} />
        </section>
      )}
      <CustomerProvider>
        <PaymentMethodsProvider>
          <Outlet />
        </PaymentMethodsProvider>
      </CustomerProvider>
    </>
  );
};
