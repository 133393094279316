import { useMemo } from 'react';
import { AdditionalInfoBroadcastChatArena } from '../../../../interfaces';

export const ArenaChat = ({ config }: { config: AdditionalInfoBroadcastChatArena }) => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  const isDevEnv = projectId?.includes('-dev');

  // o calculo é feito para descontar o tamanho da barra superior da pagina onde fica o titulo da página
  // e do banner de sinalização de ambiente
  const frameClass = useMemo(() => {
    const h = isDevEnv ? 'h-[calc(100vh_-_128px)]' : 'h-[calc(100vh_-_88px)]';
    return `${h} w-full`;
  }, [isDevEnv]);

  return <iframe className={frameClass} src={config.iframeSrc} title="chat arena" />;
};
